import {t} from '@lingui/macro'
import {toast} from 'react-hot-toast'

import {UserModel} from '@/models/User'

export const successLoginToast = (user: UserModel) =>
  toast.success(t`Welcome Back ${user.username}.`)
export const successRegisterToast = (user: UserModel) =>
  toast.success(t`Welcome ${user.username}.`)
export const successLogoutToast = (user: UserModel) =>
  toast.success(t`Goodbye ${user.username}.`)
export const successForgotPasswordToast = () =>
  toast.success(
    t`If an account with the email address exists, an email was sent to this address.`
  )
export const successResetPasswordToast = () =>
  toast.success(t`Your password were changed.`)
export const successResendVeryEmailToast = () =>
  toast.success(t`A verification mail has been sent to your email address.`)
export const successVerifyToast = () =>
  toast.success(t`Your email were verified.`)

export const errorGuestToast = () =>
  toast.error(t`You can only access this page if you are not logged in.`)
export const errorLoggedInToast = () =>
  toast.error(t`You can only access this page if you are logged in.`)
export const errorVerifiedToast = () =>
  toast.error(t`You can only access this page if your Email is verified.`)
export const errorVerifyToast = () =>
  toast.error(t`The verification link seems to be incorrect. Try it again.`)
export const errorEmailVerifiedToast = () =>
  toast.error(t`You can only access this page if your email is not verified.`)
