import React, {FocusEvent, useState} from 'react'

import {t} from '@lingui/macro'
import {useField, useFormikContext} from 'formik'
import PlacesAutocomplete, {geocodeByPlaceId} from 'react-places-autocomplete'
import {useUpdateEffect} from 'react-use'

import useCurrentPosition from '@/hooks/useCurrentPosition'
import useGoogleMapsLoaded from '@/hooks/useGoogleMapsLoaded'

import List from '@/components/List'
import FieldCurrentPosition from '@/components/fields/FieldCurrentPosition'
import FieldInput, {Input} from '@/components/fields/FieldInput'
import FieldRadiusSelect from '@/components/fields/FieldRadiusSelect'

import {AddressComponents, normalizeAddressComponents} from '@/lib/address'
import {FieldGroupInterface, FieldInterface, FormControl} from '@/lib/form'
import {PopperContainer} from '@/lib/popper'

interface AutocompleteOptions {
  addressComponents: AddressComponents
  position: google.maps.LatLng
  name?: string
  address: string
  placeId: string
}

interface GeneralLocationAutocompleteProps {
  className?: string
  onlyAddress?: boolean
  onlyLocation?: boolean
  disabled?: boolean
  hidden?: (inputValue: string) => boolean
  required?: boolean
}

interface LocationAutocompleteProps
  extends GeneralLocationAutocompleteProps,
    FieldInterface {
  value: string
  invalid?: boolean
  valid?: boolean
  onBlur?: (event: FocusEvent) => void
  onChange?: (autocompleteOptions: AutocompleteOptions) => void
}

export const AddressAutocomplete = ({
  value = '',
  onChange,
  onBlur,
  onlyAddress,
  onlyLocation,
  disabled,
  hidden = () => false,
  ...props
}: LocationAutocompleteProps) => {
  const googleMapsLoaded = useGoogleMapsLoaded()
  const getCurrentPosition = useCurrentPosition()
  const [inputValue, setInputValue] = useState(value || '')
  const [focused, setFocused] = useState(false)
  const [position, setPosition] = useState({
    coords: {
      accuracy: 1,
      latitude: 51.157822,
      longitude: 8.2121852,
    },
  })

  useUpdateEffect(() => {
    setInputValue(value)
  }, [value])

  if (!googleMapsLoaded) {
    return (
      <FieldInput
        {...props}
        value={inputValue}
        onChange={({target}) => setInputValue(target.value)}
        disabled={disabled}
        loading
      />
    )
  }

  const searchOptions = {
    types: onlyAddress
      ? ['geocode']
      : onlyLocation
      ? ['establishment']
      : ['geocode', 'establishment'],
    bounds: new google.maps.LatLngBounds(
      new google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      ),
      new google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      )
    ),
  }

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      value={inputValue || ''}
      onChange={setInputValue}
      onSelect={async (address, placeId) => {
        setFocused(false)
        setInputValue(address.split(',')[0])
        const results = await geocodeByPlaceId(placeId)
        const position = results[0].geometry.location
        const addressComponents = normalizeAddressComponents(
          results[0].address_components
        )
        const name = address.split(',')[0]

        onChange &&
          onChange({
            position,
            addressComponents,
            address,
            name,
            placeId,
          })
      }}
      highlightFirstSuggestion
    >
      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <PopperContainer
          hidden={
            !focused ||
            ((inputValue?.length || 0) < 3 && !suggestions.length) ||
            hidden(inputValue)
          }
          onOutsideClick={() => {
            setFocused(false)
            setInputValue(value)
          }}
          refChildren={({setRefElement}) => (
            <Input
              ref={setRefElement}
              placeholder={t`Enter a location`}
              loading={loading}
              value={value}
              onFocus={async () => {
                setFocused(true)
                try {
                  const position = await getCurrentPosition(false)
                  setPosition(position)
                } catch (e) {}
              }}
              {...getInputProps()}
              disabled={getInputProps().disabled || disabled}
              onBlur={(e) => {
                setFocused(false)
                getInputProps().onBlur(e)
                onBlur && onBlur(e)
              }}
              aria-haspopup="menu"
              {...props}
            />
          )}
        >
          <List
            items={suggestions}
            emptyMessage={t`No locations found for „${inputValue}“`}
            isActive={(suggestion) => suggestion.active}
            accessor="description"
            itemProps={(suggestion) => getSuggestionItemProps(suggestion)}
          />
        </PopperContainer>
      )}
    </PlacesAutocomplete>
  )
}

interface FieldAddressAutocompleteProps
  extends GeneralLocationAutocompleteProps,
    FieldInterface {
  onChange: (autocompleteOptions: AutocompleteOptions) => void
}

const FieldAddressAutocomplete = ({
  name,
  ...props
}: FieldAddressAutocompleteProps) => {
  const {isSubmitting} = useFormikContext()
  const [field, meta] = useField(name)

  return (
    <FormControl meta={meta}>
      <AddressAutocomplete
        {...field}
        {...props}
        invalid={
          (meta.initialTouched || meta.touched) &&
          (!!meta.error || !!meta.initialError)
        }
        valid={
          (meta.touched && !meta.error) ||
          (meta.initialTouched && !meta.initialError)
        }
        disabled={isSubmitting}
      />
    </FormControl>
  )
}

interface FieldGroupAddressPosition
  extends FieldGroupInterface,
    GeneralLocationAutocompleteProps {
  onChange: (autocompleteOptions: AutocompleteOptions) => void
}

export const FieldGroupAddressPosition = ({
  namePrefix = '',
  ...props
}: FieldGroupAddressPosition) => (
  <div className="flex flex-grow space-x-5">
    <div className="flex-grow">
      <FieldAddressAutocomplete
        name={`${namePrefix}name`}
        placeholder={t`Enter a location`}
        hidden={(inputValue) => inputValue === t`Current position`}
        required
        onlyAddress
        {...props}
      />
    </div>

    <div className="w-auto">
      <FieldCurrentPosition namePrefix={namePrefix} />
    </div>
  </div>
)

export const FieldGroupRadiusAddressPosition = ({
  namePrefix = '',
  ...props
}: FieldGroupAddressPosition) => {
  const [fieldRadius] = useField(`${namePrefix}radius`)

  return (
    <div className="flex flex-wrap">
      <div className="w-full md:w-auto md:pr-5">
        <FieldRadiusSelect name={`${namePrefix}radius`} required />
      </div>

      <FieldGroupAddressPosition
        namePrefix={namePrefix}
        required={!!fieldRadius.value}
        {...props}
      />
    </div>
  )
}

export default FieldAddressAutocomplete
