import React from 'react'

import {Trans} from '@lingui/macro'

import {Title} from '@/components/Title'
import UsersTable from '@/components/tabels/UsersTable'

const Users = () => (
  <div>
    <Title>
      <Trans>Users</Trans>
    </Title>

    <UsersTable />
  </div>
)

export default Users
