import {i18n} from '@lingui/core'
import {t} from '@lingui/macro'
import {Helmet} from 'react-helmet'

const Head = () => (
  <Helmet>
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta charSet="utf-8" />
    <title>Darts Turniere</title>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link
      rel="shortcut icon"
      type="image/png"
      href="/images/darts-turniere-logo.svg"
    />
    <meta
      name="description"
      content={t`In our tournament calendar you can view darts tournaments and filter them by your league (Hobby, B-League, etc.), location and your favorite game mode!`}
    />
    <meta property="og:title" content={t`Darts Turniere`} />
    <meta property="og:image" content="/images/flyer.png" />
    <meta
      name="og:description"
      content={t`In our tournament calendar you can view darts tournaments and filter them by your league (Hobby, B-League, etc.), location and your favorite game mode!`}
    />
    <html lang={i18n.locale} />
  </Helmet>
)

export default Head
