import React from 'react'

import {Trans} from '@lingui/macro'

import {postTournament} from '@/api/tournaments'

import {Title} from '@/components/Title'
import TournamentForm from '@/components/forms/TournamentForm'

import {createTournament} from '@/actions/tournament'
import {useAuth} from '@/lib/auth'
import {useLocalizedNavigate} from '@/lib/router'
import {TournamentModel} from '@/models/Tournament'

const CreateTournament = () => {
  const {user} = useAuth()
  const navigate = useLocalizedNavigate()

  return (
    <div>
      <Title>
        <Trans>Create tournament</Trans>
      </Title>

      <TournamentForm
        onSubmit={async (values, formikHelpers) => {
          const tournamentPayload = await postTournament(values, formikHelpers)
          const tournament =
            TournamentModel.fromNormalizedPayload(tournamentPayload).data
          createTournament(tournament)
          navigate(
            user.isAdmin()
              ? '/admin/tournaments/review'
              : '/profile/tournaments'
          )
        }}
      />
    </div>
  )
}

export default CreateTournament
