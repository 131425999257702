import React from 'react'

import PodiumPrices from '@/components/PodiumPrices'
import {SearchParamsProps} from '@/components/Table'

import {Model, ModelInterface} from '@/lib/model'
import {PriceModel} from '@/models/Price'

export interface PriceListInterface extends ModelInterface {
  name: string
  prices: PriceModel[]
  sortedPrices: PriceModel[]
}

export class PriceListModel extends Model<PriceListInterface> {
  name: string

  public static KEY = 'price-lists'
  public static USER_KEY = 'user-price-lists'
  public static SELECT_KEY = 'select-price-lists'

  public static toSelectItems(normalizedPriceListsPayload: any) {
    const priceLists = this.fromNormalizedPayload(
      normalizedPriceListsPayload
    ).data

    return priceLists.map((priceList) => ({
      label: priceList.name,
      value: priceList.id,
      subLabel: <PodiumPrices prices={priceList.sortedPrices} />,
    }))
  }

  get prices(): PriceModel[] {
    return this.manyRelationship(PriceModel, 'prices')
  }

  get sortedPrices(): PriceModel[] {
    if (typeof this.attributes.sortedPrices !== 'undefined') {
      return this.attributes.sortedPrices
    }

    this.attributes.sortedPrices = [...this.prices].sort((a, b) => {
      if (a.place === b.place) {
        return 0
      }

      return a.place > b.place ? 1 : -1
    })

    return this.attributes.sortedPrices
  }

  public firstPrice(): string | null {
    if (this.sortedPrices.length < 1) {
      return null
    }
    return this.sortedPrices[0].price
  }

  public secondPrice(): string {
    if (this.sortedPrices.length < 2) {
      return null
    }
    return this.sortedPrices[1].price
  }

  public thirdPrice(): string | null {
    if (this.sortedPrices.length < 3) {
      return null
    }
    return this.sortedPrices[2].price
  }

  public otherPrices(): string[] {
    if (this.sortedPrices.length < 4) {
      return []
    }
    return this.sortedPrices
      .slice(3, this.sortedPrices.length)
      .map((price) => price.price)
  }
}

export const priceListSearchParams: SearchParamsProps = {
  'filter[search]': '',
  'page[size]': 25,
  'page[number]': 0,
  sort: '-createdAt',
}
