import React from 'react'
import {useSearchParams} from 'react-router-dom'

import {Trans} from '@lingui/macro'

import {useTournament} from '@/hooks/query/useTournament'

import {LayoutLoader} from '@/components/Loader'
import {Title} from '@/components/Title'
import GameList from '@/components/games/GameList'
import {CenteredLayoutMessage} from '@/components/layouts/DefaultLayout'

import {useSlug} from '@/lib/router'

const Tournament = () => {
  const slug = useSlug()
  const {data: tournament, isLoading, isError} = useTournament(slug)
  const [searchParams] = useSearchParams()

  if (isLoading) {
    return <LayoutLoader />
  }

  if (isError) {
    return (
      <CenteredLayoutMessage>
        <Trans>500 - Internal server error</Trans>
      </CenteredLayoutMessage>
    )
  }

  const includedIds = searchParams.getAll('included')
  const games = includedIds.length
    ? tournament.games.filter((game) => includedIds.includes(String(game.dbId)))
    : tournament.games

  return (
    <div className="container py-10">
      <Title>
        <Trans>Tournament games</Trans>
      </Title>

      <GameList games={games} />
    </div>
  )
}

export default Tournament
