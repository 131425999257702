import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import {useUpdateEffect} from 'react-use'

const useScroll = () => {
  const {pathname, hash, key} = useLocation()

  useUpdateEffect(() => {
    if (hash) {
      setTimeout(
        () =>
          document.querySelector(hash)?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          }),
        50
      )
    }
  }, [hash, key])

  useEffect(() => {
    if (hash) {
      document.querySelector(hash)?.scrollIntoView({
        behavior: 'auto',
        block: 'start',
        inline: 'start',
      })
    }
  }, [])

  useUpdateEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    })
  }, [pathname])
}

export default useScroll
