import React, {Fragment} from 'react'

import {PriceModel} from '@/models/Price'

interface PodiumPricesProps {
  prices: PriceModel[]
}

const PodiumPrices = ({prices}: PodiumPricesProps) => (
  <Fragment>
    {prices.length > 0 && <span className="text-gold">{prices[0].price}</span>}
    {prices.length > 1 && ', '}
    {prices.length > 1 && (
      <span className="text-silver">{prices[1].price}</span>
    )}
    {prices.length > 2 && ', '}
    {prices.length > 2 && (
      <span className="text-bronze">{prices[2].price}</span>
    )}
  </Fragment>
)

export default PodiumPrices
