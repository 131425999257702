import React, {useContext, useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {FormikHelpers} from 'formik/dist/types'
import Cookies from 'js-cookie'

import {postLogin, postLogout, postRegister} from '@/api/tokens'
import {getMe} from '@/api/users'

import {LoginProps} from '@/components/forms/LoginForm'
import {RegistrationProps} from '@/components/forms/RegistrationForm'

import NotFound from '@/pages/errors/NotFound'

import {queryClient} from '@/lib/query'
import {LocalizedNavigate} from '@/lib/router'
import {UserModel} from '@/models/User'
import {
  errorGuestToast,
  errorLoggedInToast,
  errorVerifiedToast,
  successLoginToast,
  successLogoutToast,
  successRegisterToast,
} from '@/toasts/auth'

export let accessToken = Cookies.get('Token') || null
export const setAccessToken = (token: string | null) => (accessToken = token)
export let user = null

declare type UserCallback = (user: UserModel) => void

interface AuthContextType {
  user: UserModel | null
  login: (
    loginProps: LoginProps,
    formikHelpers: FormikHelpers<LoginProps>,
    callback: UserCallback
  ) => void
  register: (
    registrationProps: RegistrationProps,
    formikHelpers: FormikHelpers<RegistrationProps>,
    callback: UserCallback
  ) => void
  logout: (callback?: UserCallback) => void
  isLoading: boolean
}

const AuthContext = React.createContext<AuthContextType>({
  isLoading: false,
  user: null,
  register: () => null,
  login: () => null,
  logout: () => null,
})

export const useAuth = () => useContext(AuthContext)

interface AuthProviderProps {
  children: JSX.Element | JSX.Element[]
  initUser?: UserModel | null
}

export const AuthProvider = ({
  children,
  initUser = null,
}: AuthProviderProps) => {
  const [currentUser, setUser] = useState(initUser)
  const [isLoading, setLoading] = useState(!currentUser && !!accessToken)

  useEffect(() => {
    user = initUser
    if (!currentUser && accessToken) {
      setLoading(true)
      getMe()
        .then((userPayload) => {
          const userModel = UserModel.fromNormalizedPayload(userPayload).data
          setUser(userModel)
          user = userModel
        })
        .catch(() => {
          Cookies.remove('Token')
          user = null
        })
        .finally(() => setLoading(false))
    }
  }, [])

  const login = async (
    {email, password, rememberMe}: LoginProps,
    formikHelpers: FormikHelpers<LoginProps>,
    callback: UserCallback
  ) => {
    const {
      data: {token},
    } = await postLogin(email, password, formikHelpers)

    setAccessToken(token)

    if (rememberMe) {
      Cookies.set('Token', token)
    }

    const userPayload = await getMe()

    const userModel = UserModel.fromNormalizedPayload(userPayload).data

    user = userModel
    setUser(userModel)
    successLoginToast(userModel)
    callback(userModel)
  }

  const register = async (
    registrationProps: RegistrationProps,
    formikHelpers: FormikHelpers<RegistrationProps>,
    callback: UserCallback
  ) => {
    const {
      data: {token},
    } = await postRegister(registrationProps, formikHelpers)

    setAccessToken(token)

    const userPayload = await getMe()

    const userModel = UserModel.fromNormalizedPayload(userPayload).data

    user = userModel
    setUser(userModel)
    successRegisterToast(userModel)
    callback(userModel)
  }

  const logout = async (callback?: UserCallback) => {
    await postLogout()
    Cookies.remove('Token')
    successLogoutToast(currentUser)
    setAccessToken(null)
    setUser(null)
    await queryClient.invalidateQueries()
    callback && callback(currentUser)
  }

  let value = {user: currentUser, login, logout, register, isLoading}

  return <AuthContext.Provider value={value} children={children} />
}

interface RequireProps {
  children: JSX.Element
}

export const RequireAuth = ({children}: RequireProps) => {
  const ref = useRef(true)
  const {user} = useAuth()
  const location = useLocation()

  useEffect(() => {
    ref.current = false
    if (!user) {
      errorLoggedInToast()
    }
  }, [])

  if (ref.current && !user) {
    return <LocalizedNavigate to="/auth/login" state={{from: location}} />
  }

  if (ref.current && !user.emailVerifiedAt) {
    errorVerifiedToast()
    return (
      <LocalizedNavigate to="/auth/verify/email" state={{from: location}} />
    )
  }

  return children
}

export const RequireNoAuth = ({children}: RequireProps) => {
  const ref = useRef(true)
  const {user} = useAuth()
  const location = useLocation()

  useEffect(() => {
    ref.current = false
    if (user) {
      errorGuestToast()
    }
  }, [])

  if (ref.current && user) {
    return <LocalizedNavigate to="/" state={{from: location}} />
  }

  return children
}

export const RequireIsAdmin = (props: RequireProps) => {
  const {user} = useAuth()

  if (user && !user.isAdmin()) {
    return <NotFound />
  }

  return <RequireAuth {...props} />
}
