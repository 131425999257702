import React from 'react'

import {Trans, t} from '@lingui/macro'

import {deleteEmailNewsletter} from '@/api/email-newsletters'

import {FieldGroupRadiusAddressPosition} from '@/components/fields/FieldAddressAutocomplete'
import FieldGroupMapRadius from '@/components/fields/FieldGroupMapRadius'
import FieldGroupSetting from '@/components/fields/FieldGroupSetting'
import FieldInput from '@/components/fields/FieldInput'
import FieldLeaguesSelect from '@/components/fields/FieldLeaguesSelect'
import FieldSubmitButton from '@/components/fields/FieldSubmitButton'

import {unsubscribeEmailNewsletter} from '@/actions/email-newsletter'
import Form, {CustomFormInterface} from '@/lib/form'
import Yup from '@/lib/yup'
import {MultiSettingInterface} from '@/models/Setting'
import {UserModel} from '@/models/User'
import {errorUnsubscribeNewsletterToast} from '@/toasts/newsletter'

export interface EmailNewsletterProps {
  id?: string
  radius?: number
  name: string
  currentPosition: boolean
  latitude?: number
  longitude?: number
  leagueIds: string[]
  email: string
  setting: MultiSettingInterface
}

const createEmailNewsletterInitValues = (
  user?: UserModel | null
): EmailNewsletterProps =>
  (user?.emailNewsletter && user.emailNewsletter.toInitValues()) || {
    radius: undefined,
    name: '',
    currentPosition: false,
    latitude: undefined,
    longitude: undefined,
    leagueIds: [],
    email: user?.email || '',
    setting: {
      variant: [],
      value: [],
      size: [],
      out: [],
      dyp: false,
      doubleIn: false,
      mixed: false,
    },
  }

interface EmailNewsletterFormProps
  extends CustomFormInterface<EmailNewsletterProps> {
  user: UserModel | null
}

const EmailNewsletterForm = ({user, ...props}: EmailNewsletterFormProps) => {
  const newsletterValidationSchema = Yup.object().shape({
    setting: Yup.multiSetting(),
    name: Yup.string()
      .when('radius', {
        is: (radius: number) => !!radius,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      })
      .label(t`Address`),
    radius: Yup.radius().required(),
    latitude: Yup.latitude().when('radius', {
      is: (radius: number) => !!radius,
      then: Yup.latitude().required(),
      otherwise: Yup.latitude().nullable(),
    }),
    longitude: Yup.longitude().when('radius', {
      is: (radius: number) => !!radius,
      then: Yup.longitude().required(),
      otherwise: Yup.longitude().nullable(),
    }),
    leagueIds: Yup.leagues().required(),
    currentPosition: Yup.currentPosition(),
    email: Yup.email().required(),
  })

  return (
    <Form
      initialValues={createEmailNewsletterInitValues(user)}
      validationSchema={newsletterValidationSchema}
      {...props}
    >
      {({
        handleSubmit,
        setSubmitting,
        setFieldValue,
        setValues,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <FieldGroupSetting namePrefix="setting." multi />

          <FieldGroupRadiusAddressPosition
            onChange={({name, position}) => {
              setFieldValue('name', name)
              setFieldValue('latitude', position.lat())
              setFieldValue('longitude', position.lng())
            }}
            onlyAddress
          />

          <FieldLeaguesSelect />

          <FieldGroupMapRadius />

          <div className="flex flex-wrap md:flex-nowrap md:space-x-5">
            <FieldInput name="email" placeholder={t`Email`} disabled={!!user} />

            <div className="w-full md:w-auto">
              <div className="flex space-x-5 whitespace-nowrap">
                {user?.emailNewsletter && (
                  <button
                    type="button"
                    className="btn btn-gray w-full md:w-auto"
                    onClick={async () => {
                      setSubmitting(true)
                      try {
                        await deleteEmailNewsletter(
                          user.emailNewsletter.id,
                          user.email,
                          user.emailNewsletter.token
                        )
                        unsubscribeEmailNewsletter(user)
                        setValues(createEmailNewsletterInitValues(user))
                        setSubmitting(false)
                      } catch (e) {
                        errorUnsubscribeNewsletterToast()
                        setSubmitting(false)
                      }
                    }}
                    disabled={isSubmitting}
                  >
                    <Trans>Unsubscribe</Trans>
                  </button>
                )}

                <FieldSubmitButton className="w-full md:w-auto">
                  {user?.emailNewsletter ? (
                    <Trans>Update</Trans>
                  ) : (
                    <Trans>Subscribe</Trans>
                  )}
                </FieldSubmitButton>
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  )
}

export default EmailNewsletterForm
