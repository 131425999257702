import React from 'react'

import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {
  faCalendar,
  faCalendarDay,
  faCalendarWeek,
  faEnvelope,
  faMapMarkerAlt,
  faPaperPlane,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans, t} from '@lingui/macro'

import {useStat} from '@/hooks/query/useStat'

import {TrophyPlus} from '@/components/Icons'
import {LayoutLoader} from '@/components/Loader'
import {Title} from '@/components/Title'

import {Stat, StatInterface} from '@/models/Stat'

const Dashboard = () => {
  const {isLoading, data: stats} = useStat()

  if (isLoading || !stats) {
    return <LayoutLoader />
  }

  return (
    <div>
      <Title>
        <Trans>Dashboard</Trans>
      </Title>

      <StatsCards {...stats.getAttributes()} />
    </div>
  )
}

const StatsCards = ({
  emailNewsletter,
  location,
  emailNewsletterSent,
  register,
  tournament,
  game,
}: StatInterface) => (
  <div className="space-y-5">
    <div className="flex flex-wrap justify-center space-y-5 lg:flex-nowrap lg:space-y-0 lg:space-x-5">
      <StatsCard
        title={t`Newsletter registrations`}
        icon={faPaperPlane}
        {...emailNewsletter}
      />

      <StatsCard title={t`Registrations`} icon={faUsers} {...register} />
    </div>

    <div className="flex flex-wrap justify-center space-y-5 lg:flex-nowrap lg:space-y-0 lg:space-x-5">
      <StatsCard
        title={t`Tournaments`}
        iconChild={<TrophyPlus layerClassName="text-white dark:text-dark" />}
        {...tournament}
      />

      <StatsCard
        title={t`Tournament games`}
        iconChild={<TrophyPlus layerClassName="text-white dark:text-dark" />}
        {...game}
      />
    </div>

    <div className="flex flex-wrap justify-center space-y-5 lg:flex-nowrap lg:space-y-0 lg:space-x-5">
      <StatsCard
        title={t`Newsletter sent`}
        icon={faEnvelope}
        {...emailNewsletterSent}
      />

      <StatsCard
        title={t`Tournament locations`}
        icon={faMapMarkerAlt}
        {...location}
      />
    </div>
  </div>
)

interface StatsCardProps extends Stat {
  title: string
  icon?: IconProp
  iconChild?: JSX.Element
}

const StatsCard = ({
  title,
  icon,
  iconChild,
  total,
  day,
  week,
  month,
}: StatsCardProps) => (
  <div className="w-full space-y-3 rounded bg-white p-3 text-center dark:bg-dark">
    <h2 className="text-xl capitalize text-primary">
      {!!icon && <FontAwesomeIcon icon={icon} fixedWidth />}
      {!!iconChild && iconChild} {title}
    </h2>

    <div className="text-5xl text-primary">{total}</div>

    <table className="w-full table-fixed text-2xl text-primary">
      <thead>
        <tr>
          <td>
            <FontAwesomeIcon icon={faCalendarDay} fixedWidth />
          </td>
          <td>
            <FontAwesomeIcon icon={faCalendarWeek} fixedWidth />
          </td>
          <td>
            <FontAwesomeIcon icon={faCalendar} fixedWidth />
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{day}</td>
          <td>{week}</td>
          <td>{month}</td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default Dashboard
