import React, {ReactChild, useEffect, useRef, useState} from 'react'

import {Trans} from '@lingui/macro'
import classNames from 'classnames'
import {motion} from 'framer-motion'

import {CloseIcon} from '@/components/Icons'

const backgroundVariants = {
  hidden: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
  visible: {
    display: 'block',
    opacity: 1,
  },
}

const modalVariants = {
  hidden: {
    opacity: 0,
    scale: 0.4,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
}

export interface ModalProps {
  title?: ReactChild
  open: boolean
  footer?: ReactChild
  children?: ReactChild | ReactChild[]
  onHide: () => void
  center?: boolean
  cancel?: boolean
}

const Modal = ({
  title,
  open,
  onHide,
  footer,
  children,
  center = false,
  cancel = true,
}: ModalProps) => {
  const [isClosed, setClosed] = useState(!open)
  const modalRef = useRef(null)

  useEffect(() => {
    if (open) {
      setClosed(false)
      document.querySelector('body')?.classList.add('overflow-hidden')
    }

    if (!open) {
      setTimeout(() => setClosed(true), 500)
      document.querySelector('body')?.classList.remove('overflow-hidden')
    }

    return () =>
      document.querySelector('body')?.classList.remove('overflow-hidden')
  }, [open])

  if (isClosed) {
    return null
  }

  return (
    <motion.div
      className="fixed inset-0 z-40 cursor-pointer overflow-y-auto overflow-x-hidden bg-white bg-opacity-50 text-left dark:bg-dark dark:bg-opacity-50"
      initial={{display: 'none'}}
      animate={open ? 'visible' : 'hidden'}
      variants={backgroundVariants}
      onMouseUp={(e) => {
        if (!cancel || e.target !== modalRef.current) {
          return
        }
        onHide()
      }}
    >
      <motion.div
        ref={modalRef}
        className={classNames(
          'flex justify-center py-6 px-5',
          center && 'min-h-full flex-col items-center'
        )}
        initial="hidden"
        animate={open ? 'visible' : 'hidden'}
        variants={modalVariants}
      >
        <div className="relative w-full cursor-default rounded border border-gray-light bg-white outline-none dark:border-gray-dark dark:bg-dark md:max-w-7xl">
          <ModalHeader onClose={() => cancel && onHide()}>{title}</ModalHeader>

          <div className="border-b border-gray-light p-5">{children}</div>

          <ModalFooter close={cancel} onHide={onHide}>
            {footer}
          </ModalFooter>
        </div>
      </motion.div>
    </motion.div>
  )
}

interface ModalHeaderProps {
  children?: ReactChild
  onClose: () => void
}

const ModalHeader = ({children, onClose}: ModalHeaderProps) => {
  if (!children) {
    return null
  }

  return (
    <div className="flex items-center border-b border-gray-light p-5 text-xl">
      <div className="w-full">{children}</div>

      <div>
        <CloseIcon onClick={onClose} />
      </div>
    </div>
  )
}

interface ModalFooterProps {
  children?: ReactChild
  close?: boolean
  onHide: () => void
}

const ModalFooter = ({children, close, onHide}: ModalFooterProps) => {
  if (!children && !close) {
    return null
  }

  return (
    <div className="w-full-child md:w-auto-child flex flex-wrap justify-end space-y-5 p-5 md:flex-nowrap md:space-y-0 md:space-x-5">
      {close && (
        <button type="button" className="btn btn-gray" onClick={onHide}>
          <Trans>Close</Trans>
        </button>
      )}

      {children}
    </div>
  )
}

export default Modal
