import React from 'react'

import GameCalendar from '@/components/games/GameCalendar'

const Calendar = () => (
  <div className="container py-10">
    <GameCalendar />
  </div>
)

export default Calendar
