import React from 'react'

import {Trans, t} from '@lingui/macro'

import FieldInput from '@/components/fields/FieldInput'
import {FieldRequiredSubmitButton} from '@/components/fields/FieldSubmitButton'

import {useAuth} from '@/lib/auth'
import Form, {CustomFormInterface} from '@/lib/form'
import Yup from '@/lib/yup'
import {UserModel} from '@/models/User'

export interface ProfileProps {
  id: string
  username: string
}

const createProfileInitialValues = (user: UserModel) => ({
  id: user.id,
  username: user.username,
  email: user.email,
})

interface ProfileFormProps extends CustomFormInterface<ProfileProps> {
  user: UserModel
}

const ProfileForm = (props: ProfileFormProps) => {
  const {user} = useAuth()

  if (!user) {
    return null
  }

  const profileValidationSchema = Yup.object().shape({
    username: Yup.string()
      .required()
      .min(3)
      .label(t`Username`),
    email: Yup.email().required(),
  })

  return (
    <Form
      initialTouched={{email: true}}
      initialValues={createProfileInitialValues(user)}
      validationSchema={profileValidationSchema}
      {...props}
    >
      {({handleSubmit}) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <FieldInput
            name="username"
            placeholder={t`Username`}
            required
            autoFocus
          />

          <FieldInput name="email" placeholder={t`Email`} required disabled />

          <FieldRequiredSubmitButton className="w-full">
            <Trans>Update profile</Trans>
          </FieldRequiredSubmitButton>
        </form>
      )}
    </Form>
  )
}

export default ProfileForm
