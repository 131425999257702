import {useQuery} from 'react-query'

import {
  getMobileNewsletter,
  getMobileNewsletters,
} from '@/api/mobile-newsletters'

import {SearchParamsProps} from '@/components/Table'

import {queryClient} from '@/lib/query'
import {newsletterSearchParams} from '@/models/EmailNewsletter'
import {MobileNewsletterModel} from '@/models/MobileNewsletter'

export const useMobileNewsletters = (searchParams: SearchParamsProps) =>
  useQuery(
    [MobileNewsletterModel.KEY, searchParams],
    () => getMobileNewsletters(searchParams),
    {
      select: (newslettersPayload) =>
        MobileNewsletterModel.fromNormalizedPayload(newslettersPayload),
      keepPreviousData: true,
    }
  )

export const useMobileNewsletter = (token: string, options: any) =>
  useQuery(MobileNewsletterModel.KEY, () => getMobileNewsletter(token), {
    select: (newsletterPayload) =>
      MobileNewsletterModel.fromNormalizedPayload(newsletterPayload).data,
    ...options,
  })

export const prefetchMobileNewsletters = (
  searchParams: SearchParamsProps = newsletterSearchParams
) =>
  !queryClient.getQueryData([MobileNewsletterModel.KEY, searchParams]) &&
  queryClient.prefetchQuery([MobileNewsletterModel.KEY, searchParams], () =>
    getMobileNewsletters(searchParams)
  )
