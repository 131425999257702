import React from 'react'

import {Trans} from '@lingui/macro'
import classNames from 'classnames'

import {GameModel} from '@/models/Game'

interface GameLeaguesProps {
  className?: string
  game: GameModel
}

const GameLeagues = ({className, game}: GameLeaguesProps) => (
  <div
    className={classNames(
      'flex items-center justify-center bg-white p-5 dark:bg-dark',
      className
    )}
  >
    <div>
      <h2 className="text-xl text-primary">
        <Trans>Leagues</Trans>
      </h2>

      <ul className="list-disc">
        {game.leagues?.map((league) => (
          <li className="text-2xl text-primary" key={league.id}>
            <div className="text-base text-gray-dark dark:text-gray-light">
              {league.name}
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

export default GameLeagues
