import React, {Fragment, useEffect} from 'react'
import {Route, Routes} from 'react-router-dom'

import {config} from '@fortawesome/fontawesome-svg-core'

import {prefetchSelectLeagues} from '@/hooks/query/useLeague'
import useScroll from '@/hooks/useScroll'

import Head from '@/components/Head'
import {DefaultLayoutLoader} from '@/components/Loader'
import Toasts from '@/components/Toasts'
import AdminLayout from '@/components/layouts/AdminLayout'
import DefaultLayout from '@/components/layouts/DefaultLayout'
import GuideLayout from '@/components/layouts/GuideLayout'
import NoAuthLayout from '@/components/layouts/NoAuthLayout'
import ProfileLayout from '@/components/layouts/ProfileLayout'

import Home from '@/pages/Home'
import Dashboard from '@/pages/admin/Dashboard'
import EmailNewsletters from '@/pages/admin/EmailNewsletters'
import AdminLocations from '@/pages/admin/Locations'
import MobileNewsletters from '@/pages/admin/MobileNewsletters'
import PriceLists from '@/pages/admin/PriceLists'
import Reports from '@/pages/admin/Reports'
import SentEmails from '@/pages/admin/SentEmails'
import Users from '@/pages/admin/Users'
import Review from '@/pages/admin/tournaments/Review'
import Tournaments from '@/pages/admin/tournaments/Tournaments'
import Login from '@/pages/auth/Login'
import Registration from '@/pages/auth/Registration'
import Forgot from '@/pages/auth/password/Forgot'
import Reset from '@/pages/auth/password/Reset'
import Email from '@/pages/auth/verify/Email'
import Verify from '@/pages/auth/verify/Verify'
import NotFound from '@/pages/errors/NotFound'
import GameModes from '@/pages/guides/GameModes'
import Newsletter from '@/pages/guides/Newsletter'
import TournamentCalendar from '@/pages/guides/tournaments/Calendar'
import Location from '@/pages/locations/Location'
import Locations from '@/pages/locations/Locations'
import UnsubscribeEmailNewsletter from '@/pages/newsletters/UnsubscribeEmailNewsletter'
import ProfileEmailNewsletter from '@/pages/profile/EmailNewsletter'
import ProfileMobileNewsletter from '@/pages/profile/MobileNewsletter'
import MyPriceLists from '@/pages/profile/MyPriceLists'
import MyProfile from '@/pages/profile/MyProfile'
import CalendarFilter from '@/pages/profile/tournaments/CalendarFilter'
import CreateTournament from '@/pages/profile/tournaments/CreateTournament'
import EditTournament from '@/pages/profile/tournaments/EditTournament'
import Favorites from '@/pages/profile/tournaments/Favorites'
import MyTournaments from '@/pages/profile/tournaments/MyTournaments'
import Imprint from '@/pages/rights/Imprint'
import PrivatePolicy from '@/pages/rights/PrivatePolicy'
import Calendar from '@/pages/tournaments/Calendar'
import Game from '@/pages/tournaments/Game'
import Tournament from '@/pages/tournaments/Tournament'

import {useAuth} from '@/lib/auth'
import {supportedLocales} from '@/lib/i18n'
import '@/styles/index.scss'

config.autoAddCss = false

const App = () => {
  const {isLoading} = useAuth()
  useScroll()

  useEffect(() => {
    prefetchSelectLeagues()
  }, [])

  if (isLoading) {
    return <DefaultLayoutLoader />
  }

  return (
    <Fragment>
      <Head />

      <Toasts />

      <Routes>
        {supportedLocales.map((locale) => (
          <Route key={locale} path={locale} element={<DefaultLayout />}>
            <Route index element={<Home />} />

            <Route path="auth" element={<NoAuthLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="registration" element={<Registration />} />

              <Route path="password">
                <Route path="reset" element={<Reset />} />
                <Route path="forgot" element={<Forgot />} />
              </Route>
            </Route>

            <Route path="auth/verify">
              <Route index element={<Verify />} />
              <Route path="email" element={<Email />} />
            </Route>

            <Route path="tournaments">
              <Route
                path=":location/:city/:street/:tournament"
                element={<Tournament />}
              />
              <Route
                path=":location/:city/:street/:tournament/:date/:time"
                element={<Game />}
              />
              <Route path="calendar" element={<Calendar />} />
            </Route>

            <Route path="locations">
              <Route index element={<Locations />} />
              <Route path=":location/:city/:street" element={<Location />} />
            </Route>

            <Route path="rights">
              <Route path="imprint" element={<Imprint />} />
              <Route path="private-policy" element={<PrivatePolicy />} />
            </Route>

            <Route path="guides" element={<GuideLayout />}>
              <Route
                path="tournaments/calendar"
                element={<TournamentCalendar />}
              />
              <Route path="game-modes" element={<GameModes />} />
              <Route path="newsletter" element={<Newsletter />} />
            </Route>

            <Route path="profile" element={<ProfileLayout />}>
              <Route index element={<MyProfile />} />

              <Route path="tournaments">
                <Route index element={<MyTournaments />} />

                <Route
                  path=":location/:city/:street/:tournament/edit"
                  element={<EditTournament />}
                />
                <Route path="create" element={<CreateTournament />} />
                <Route path="favorites" element={<Favorites />} />
                <Route path="calendar/filter" element={<CalendarFilter />} />
              </Route>

              <Route path="price-lists" element={<MyPriceLists />} />

              <Route path="newsletters">
                <Route path="email" element={<ProfileEmailNewsletter />} />

                <Route path="mobile" element={<ProfileMobileNewsletter />} />
              </Route>
            </Route>

            <Route path="admin" element={<AdminLayout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="reports" element={<Reports />} />
              <Route path="users" element={<Users />} />
              <Route path="price-lists" element={<PriceLists />} />
              <Route path="emails/sent" element={<SentEmails />} />
              <Route path="newsletters">
                <Route path="email" element={<EmailNewsletters />} />

                <Route path="mobile" element={<MobileNewsletters />} />
              </Route>
              <Route path="locations" element={<AdminLocations />} />
              <Route path="tournaments">
                <Route index element={<Tournaments />} />

                <Route path="review" element={<Review />} />
              </Route>
            </Route>

            <Route path="newsletters">
              <Route
                path="unsubscribe"
                element={<UnsubscribeEmailNewsletter />}
              />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Route>
        ))}
      </Routes>
    </Fragment>
  )
}

export default App
