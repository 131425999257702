import React, {ReactChild, ReactChildren} from 'react'

import classNames from 'classnames'

interface TitleProps {
  className?: string
  children: ReactChild | ReactChildren
}

export const Title = ({className, ...props}: TitleProps) => (
  <h1
    className={classNames(
      'mb-8 text-center text-3xl capitalize text-primary',
      className
    )}
    {...props}
  />
)
