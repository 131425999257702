import React, {useMemo, useState} from 'react'
import {UseQueryResult} from 'react-query'

import {faCheck, faStar, faTimes} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {t} from '@lingui/macro'

import {postFavorite} from '@/api/games'

import {prefetchFavoriteGames, useFavoritesGames} from '@/hooks/query/useGame'

import {AlertDanger} from '@/components/Alert'
import DartLeagues from '@/components/DartLeagues'
import DartsSetting from '@/components/DartsSetting'
import Table, {SearchParamsProps} from '@/components/Table'

import {toggleGameFavorite} from '@/actions/game'
import {queryClient} from '@/lib/query'
import {LocalizedLink} from '@/lib/router'
import {GameModel, gameSearchParams} from '@/models/Game'

interface GamesTableProps {
  useTableGames?: (searchParams: SearchParamsProps) => UseQueryResult<any>
  prefetchTableGames?: (searchParams: SearchParamsProps) => void
}

const GamesTable = ({
  useTableGames = useFavoritesGames,
  prefetchTableGames = prefetchFavoriteGames,
}: GamesTableProps) => {
  const [searchParams, setSearchParams] =
    useState<SearchParamsProps>(gameSearchParams)
  const {data: gamesData, ...gameQuery} = useTableGames(searchParams)
  const gameColumns = useMemo(
    () => [
      {
        id: 'tournaments.name',
        Header: t`Tournament name`,
        accessor: (game: GameModel) => (
          <>
            <LocalizedLink to={game.link()}>
              {game.tournament.name}
            </LocalizedLink>
            <br />
            <DartsSetting setting={game.setting} />
          </>
        ),
        sort: false,
      },
      {
        id: 'locations.name',
        Header: t`Tournament location`,
        accessor: (game: GameModel) => (
          <>
            <LocalizedLink to={game.tournament.location.link()}>
              {game.tournament.location.name}
            </LocalizedLink>
            <br />
            <small>
              {game.tournament.location.postalCode}{' '}
              {game.tournament.location.city}
            </small>
          </>
        ),
        sort: false,
      },
      {
        id: 'leagues',
        Header: t`Leagues`,
        accessor: (game: GameModel) => <DartLeagues leagues={game.leagues} />,
        sort: false,
      },
      {
        id: 'start',
        Header: t`Tournament start`,
        accessor: (game: GameModel) => game.formatStart(),
      },
      {
        Header: t`Added by`,
        accessor: 'tournament.creator.username',
        show: false,
        sort: false,
      },
      {
        id: 'canceled',
        Header: t`Tournament status`,
        accessor: (game: GameModel) => (
          <div className="text-center">
            <FontAwesomeIcon
              className={game.canceled ? 'text-danger' : 'text-primary'}
              icon={game.canceled ? faTimes : faCheck}
            />
          </div>
        ),
        show: false,
      },
      {
        id: 'deadline',
        Header: t`Tournament deadline`,
        accessor: (game: GameModel) => game.formatDeadline(),
        show: false,
      },
      {
        id: 'createdAt',
        Header: t`Added at`,
        accessor: (game: GameModel) => game.formatCreatedAt(),
        show: false,
      },
      {
        id: 'controls',
        Header: '',
        className: 'w-1 whitespace-nowrap',
        Cell: ({row}: any) => (
          <div>
            <button
              type="button"
              className="btn-sm btn-danger py-1"
              onClick={async () => {
                await postFavorite(row.original.id)

                toggleGameFavorite(row.original)
                await queryClient.invalidateQueries([
                  GameModel.FAVORITE_KEY,
                  searchParams,
                ])
              }}
            >
              <FontAwesomeIcon icon={faStar} />
            </button>
          </div>
        ),
        sort: false,
      },
    ],
    []
  )

  if (gameQuery.isError) {
    return <AlertDanger>{String(gameQuery.error)}</AlertDanger>
  }

  const {
    data: games,
    meta: {page: pagination},
  } = gamesData || {
    data: [],
    meta: {page: {}},
  }

  return (
    <Table
      placeholder={t`Tournament favorites`}
      data={games}
      columns={gameColumns}
      pagination={pagination}
      searchParams={searchParams}
      query={gameQuery}
      onChange={setSearchParams}
      onPrefetch={prefetchTableGames}
    />
  )
}

export default GamesTable
