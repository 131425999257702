import React, {useMemo} from 'react'

import {t} from '@lingui/macro'

import FieldSelect from '@/components/fields/FieldSelect'

import {FieldInterface} from '@/lib/form'

interface FieldRadiusSelectProps extends FieldInterface {
  disabled?: boolean
  required?: boolean
  invalid?: boolean
  valid?: boolean
  loading?: boolean
}

const FieldRadiusSelect = ({placeholder, ...props}: FieldRadiusSelectProps) => {
  const radii = useMemo(
    () => [
      {label: t`All`, value: 0},
      {label: t`${10} KM`, value: 10000},
      {label: t`${50} KM`, value: 50000},
      {label: t`${100} KM`, value: 100000},
      {label: t`${250} KM`, value: 250000},
    ],
    []
  )

  return (
    <FieldSelect
      {...props}
      items={radii}
      placeholder={placeholder || t`radius`}
    />
  )
}

export default FieldRadiusSelect
