import {t} from '@lingui/macro'
import {toast} from 'react-hot-toast'

import {GameModel} from '@/models/Game'
import {LocationModel} from '@/models/Location'

export const successReportedGameToast = (game: GameModel) =>
  toast.success(
    t`The tournament game „${
      game.tournament.name
    }“ on ${game.formatStart()} has been reported`
  )

export const successReportedLocationToast = (location: LocationModel) =>
  toast.success(t`The tournament location „${location.name}“ has been reported`)
