import React from 'react'

import {Trans} from '@lingui/macro'

import {
  patchMobileNewsletter,
  postMobileNewsletter,
} from '@/api/mobile-newsletters'

import {Title} from '@/components/Title'
import MobileNewsletterForm from '@/components/forms/MobileNewsletterForm'

import {
  subscribeMobileNewsletter,
  updateMobileNewsletter,
} from '@/actions/mobile-newsletter'
import {useAuth} from '@/lib/auth'
import {MobileNewsletterModel} from '@/models/MobileNewsletter'

const MobileNewsletter = () => {
  const {user} = useAuth()

  return (
    <div>
      <Title>
        <Trans>Mobile newsletter</Trans>
      </Title>

      <MobileNewsletterForm
        onSubmit={async (values, formikHelpers) => {
          if (user?.mobileNewsletters?.length) {
            const newsletterPayload = await patchMobileNewsletter(
              values,
              formikHelpers
            )

            updateMobileNewsletter(
              user,
              MobileNewsletterModel.fromNormalizedPayload(newsletterPayload)
                .data
            )
          }

          if (!user?.mobileNewsletters?.length) {
            const newsletterPayload = await postMobileNewsletter(
              values,
              formikHelpers
            )

            subscribeMobileNewsletter(
              user,
              MobileNewsletterModel.fromNormalizedPayload(newsletterPayload)
                .data
            )
          }

          formikHelpers.setSubmitting(false)
        }}
      />
    </div>
  )
}

export default MobileNewsletter
