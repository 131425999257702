import React, {ReactChild} from 'react'

import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {
  faCalendar,
  faClipboardList,
  faGlobe,
  faMapMarkedAlt,
  faPhone,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import DartLeagues from '@/components/DartLeagues'
import DartsSetting from '@/components/DartsSetting'
import {IconHandHoldingEuro, IconTrophyPlacement} from '@/components/Icons'

import {ExternalLink, LocalizedLink} from '@/lib/router'
import {GameModel} from '@/models/Game'

interface SmallGameInformation {
  game: GameModel
}

const SmallGameInformation = ({game}: SmallGameInformation) => (
  <>
    <FontAwesomeInformation icon={faMapMarkedAlt}>
      <LocalizedLink to={game.tournament.location.link()}>
        {game.tournament.location.name}
      </LocalizedLink>
    </FontAwesomeInformation>

    <FontAwesomeInformation icon={faClipboardList}>
      <DartsSetting setting={game.setting} />
    </FontAwesomeInformation>

    <FontAwesomeInformation icon={faTrophy}>
      <DartLeagues leagues={game.leagues} />
    </FontAwesomeInformation>

    {game.buyIn && (
      <IconInformation icon={IconHandHoldingEuro}>
        {game.formattedBuyIn()}
      </IconInformation>
    )}

    {!!game.priceList && (
      <IconInformation icon={IconTrophyPlacement} iconProps={{placement: 1}}>
        {game.priceList.firstPrice()}
      </IconInformation>
    )}

    {game.tournament.location.number && (
      <FontAwesomeInformation icon={faPhone}>
        <ExternalLink to={`tel:${game.tournament.location.number}`}>
          {game.tournament.location.number}
        </ExternalLink>
      </FontAwesomeInformation>
    )}

    {game.tournament.location.website && (
      <FontAwesomeInformation icon={faGlobe}>
        <ExternalLink to={game.tournament.location.website}>
          {game.tournament.location.websiteHost()}
        </ExternalLink>
      </FontAwesomeInformation>
    )}

    <FontAwesomeInformation icon={faCalendar}>
      {game.deadlineForHumans()}
    </FontAwesomeInformation>
  </>
)

interface FontAwesomeInformationProps {
  icon: IconProp
  children?: ReactChild | string
}

const FontAwesomeInformation = ({
  icon,
  children,
}: FontAwesomeInformationProps) => (
  <div>
    <FontAwesomeIcon icon={icon} className="fa-fw text-primary" />
    <span className="inline-block pl-1">{children}</span>
  </div>
)

interface IconInformationProps {
  icon: any
  children?: ReactChild | string
  iconProps?: any
}

const IconInformation = ({
  icon,
  children,
  iconProps = {},
}: IconInformationProps) => (
  <div>
    {React.createElement(icon, {
      className: 'text-primary fa-fw',
      ...iconProps,
    })}
    <span className="pl-1">{children}</span>
  </div>
)

export default SmallGameInformation
