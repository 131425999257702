import React, {ReactChild, ReactChildren} from 'react'

import classNames from 'classnames'

interface AlertProps {
  className?: string
  children?: ReactChild | ReactChildren
}

const Alert = ({className = '', ...props}: AlertProps) => (
  <div className={`rounded border p-4 text-center ${className}`} {...props} />
)

export const AlertDanger = ({className, ...props}: AlertProps) => (
  <Alert
    className={classNames(
      'border-danger bg-danger bg-opacity-30 text-white',
      className
    )}
    {...props}
  />
)

export const AlertInfo = ({className, ...props}: AlertProps) => (
  <Alert
    className={classNames(
      'border-info bg-info bg-opacity-30 text-white',
      className
    )}
    {...props}
  />
)
