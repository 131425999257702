import {useEffect, useState} from 'react'

const useGoogleMapsLoaded = () => {
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(
    typeof window !== 'undefined' && !!window.google?.maps?.places
  )

  useEffect(() => {
    if (!googleMapsLoaded) {
      const googleMapsLoadedInterval = setInterval(() => {
        if (window.google?.maps?.places) {
          setGoogleMapsLoaded(true)
          clearInterval(googleMapsLoadedInterval)
        }
      }, 500)
    }
  }, [])

  return googleMapsLoaded
}

export default useGoogleMapsLoaded
