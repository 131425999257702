import React, {Fragment} from 'react'

import {faStar as farStar} from '@fortawesome/free-regular-svg-icons'
import {
  faCalendar,
  faCalendarPlus,
  faClipboardList,
  faTrophy,
  faUserEdit,
  faStar as fasStar,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import {postFavorite} from '@/api/games'

import DartsSetting from '@/components/DartsSetting'
import {IconCalendarClock, IconHandHoldingEuro} from '@/components/Icons'
import InputLabel from '@/components/InputLabel'
import {Title} from '@/components/Title'
import GameCanceled from '@/components/games/GameCanceled'
import GameReport from '@/components/games/GameReport'
import GameStatus from '@/components/games/GameStatus'

import {toggleGameFavorite} from '@/actions/game'
import {useAuth} from '@/lib/auth'
import {intlFormatDate} from '@/lib/date'
import {GameModel} from '@/models/Game'
import {errorChangeFavoriteToast} from '@/toasts/game'

interface GameInformationProps {
  className?: string
  game: GameModel
}

const GameInformation = ({className = '', game}: GameInformationProps) => {
  const {user} = useAuth()

  return (
    <div
      className={classNames('relative bg-white p-5 dark:bg-dark', className)}
    >
      <Title className={user ? 'pr-10' : ''}>{game.tournament.name}</Title>

      <FavoritesButton game={game} />

      <SeoGameInformation game={game} />

      <div className="space-y-5">
        <div className="space-y-5">
          <GameStatus game={game} />

          <GameCanceled game={game} />
        </div>

        <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
          <InputLabel icon={faTrophy} itemProp="name">
            {game.tournament.name}
          </InputLabel>

          {!!game.deadline && (
            <InputLabel itemProp="doorTime" iconChild={<IconCalendarClock />}>
              {game.formatDeadline()}
            </InputLabel>
          )}

          <InputLabel icon={faCalendar} itemProp="startDate">
            {game.formatStart()}
          </InputLabel>

          <InputLabel icon={faClipboardList}>
            <DartsSetting setting={game.setting} />
          </InputLabel>

          {game.buyIn && (
            <InputLabel iconChild={<IconHandHoldingEuro />}>
              {game.formattedBuyIn()}
            </InputLabel>
          )}

          <InputLabel icon={faUserEdit}>
            {game.tournament.creator?.username}
          </InputLabel>

          <InputLabel icon={faCalendarPlus}>
            {intlFormatDate(new Date(game.createdAt))}
          </InputLabel>
        </div>

        <div className="flex justify-end">
          <GameReport game={game} />
        </div>
      </div>
    </div>
  )
}

interface SeoGameInformationProps {
  game: GameModel
}

const SeoGameInformation = ({game}: SeoGameInformationProps) => (
  <Fragment>
    <span className="hidden" itemProp="image" />
    <span className="hidden" itemProp="organizer">
      {game.tournament.location.name}
    </span>
    <span className="hidden" itemProp="url">
      {game.link()}
    </span>
    <span className="hidden" itemProp="audience" />
    {!game.buyIn && <span className="hidden" itemProp="isAccessibleForFree" />}
  </Fragment>
)

interface FavoritesButtonProps {
  game: GameModel
}

const FavoritesButton = ({game}: FavoritesButtonProps) => {
  const {user} = useAuth()

  if (!user) {
    return null
  }

  return (
    <div
      className="absolute right-5 top-5 cursor-pointer text-2xl text-gold"
      onClick={async () => {
        try {
          await postFavorite(game.id)
          toggleGameFavorite(game)
        } catch (e) {
          errorChangeFavoriteToast(game)
        }
      }}
    >
      <FontAwesomeIcon icon={game.favorite ? fasStar : farStar} />
    </div>
  )
}

export default GameInformation
