import React from 'react'
import {Link} from 'react-router-dom'

import {i18n} from '@lingui/core'
import {t} from '@lingui/macro'
import classNames from 'classnames'
import {getYear} from 'date-fns'

import de from '@/images/flags/de.svg'
import gb from '@/images/flags/gb.svg'
import {changeLocale} from '@/lib/i18n'
import {LocalizedLink, useLocalizedLocation} from '@/lib/router'

export const flags = {
  de,
  en: gb,
}

const Footer = () => {
  const {pathname} = useLocalizedLocation()
  const isHome = pathname === '/'

  return (
    <footer
      className={classNames(
        'bg-white py-16 text-center text-dark dark:bg-dark dark:text-gray',
        !isHome && 'shadow-lg-'
      )}
    >
      <div className="container px-6">
        <LanguageButtons />

        <Rights />

        <CopyRight />
      </div>
    </footer>
  )
}

const Rights = () => (
  <div className="mb-5 text-sm">
    <LocalizedLink
      className="mx-3 cursor-pointer text-primary"
      to="/rights/imprint"
    >
      {t`Imprint`}
    </LocalizedLink>

    <LocalizedLink
      className="mx-3 cursor-pointer text-primary"
      to="/rights/private-policy"
    >
      {t`Private policy`}
    </LocalizedLink>
  </div>
)

const CopyRight = () => (
  <div>{t`Copyright © Darts Turniere ${getYear(new Date())}`}</div>
)

const LanguageButtons = () => (
  <div className="mb-5 space-x-3" data-test-id="language-buttons">
    <LanguageButton locale="en">{t`English`}</LanguageButton>

    <LanguageButton locale="de">{t`German`}</LanguageButton>
  </div>
)

interface LanguageButtonProps {
  locale: string
  children: string
}

const LanguageButton = ({locale, children}: LanguageButtonProps) => {
  const {pathname} = useLocalizedLocation()

  return (
    <Link to={`/${locale}${pathname}`} onClick={() => changeLocale(locale)}>
      <button
        className={classNames(
          'btn-sm btn-primary',
          i18n.locale === locale && 'active'
        )}
      >
        <img
          className="mr-1 inline-block h-3.5 w-auto"
          src={flags[locale]}
          alt={children}
          title={children}
          width="28px"
          height="14px"
        />

        {children}
      </button>
    </Link>
  )
}

export default Footer
