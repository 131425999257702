import {MobileNewsletterProps} from '@/components/forms/MobileNewsletterForm'

import {Model} from '@/lib/model'
import {NewsletterInterface} from '@/models/EmailNewsletter'
import {LeagueModel} from '@/models/League'
import {SettingModel} from '@/models/Setting'
import {UserModel} from '@/models/User'

export interface MobileNewsletterInterface extends NewsletterInterface {
  token: string
}

export class MobileNewsletterModel extends Model<MobileNewsletterInterface> {
  radius!: number
  name!: string
  email!: string
  currentPosition!: boolean
  latitude!: number | null
  longitude!: number | null
  token!: string
  locale!: string

  public static KEY = 'mobile-newsletters'
  public static TOKEN_KEY = 'mobile-newsletter-token'

  get setting(): SettingModel {
    return this.singleRelationship(SettingModel, 'setting')
  }

  get leagues(): LeagueModel[] {
    return this.manyRelationship(LeagueModel, 'leagues')
  }

  get leagueIds(): string[] {
    return this.leagues.map((league) => league.id)
  }

  get creator(): UserModel | null {
    return this.singleRelationship(UserModel, 'creator')
  }

  public toInitValues(): MobileNewsletterProps {
    return {
      ...this.only([
        'id',
        'token',
        'radius',
        'latitude',
        'longitude',
        'currentPosition',
      ]),
      name: this.name || '',
      setting: this.setting?.getAttributes(),
      leagueIds: this.leagueIds,
    }
  }
}
