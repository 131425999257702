import React, {ReactChild, useState} from 'react'

import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Placement} from '@popperjs/core/lib/enums'

import List from '@/components/List'

import {PopperContainer} from '@/lib/popper'

interface ItemProp<Value = any> {
  label: ReactChild | ReactChild[]
  value?: Value
  onClick?: (item: ItemProp) => void
}

interface DropdownProps<Value = any> {
  className?: string
  buttonClassName?: string
  containerClassName?: string
  items: ItemProp<Value>[]
  children?: any
  value?: Value
  noArrow?: boolean
  noSameWidth?: boolean
  placement?: Placement
  onChange?: (item: ItemProp<Value>) => void
  multi?: boolean
  noActive?: boolean
}

const Dropdown = ({
  buttonClassName,
  items,
  value,
  children,
  onChange,
  noActive = false,
  noArrow = false,
  multi = false,
  ...props
}: DropdownProps) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <PopperContainer
      hidden={!isOpen}
      onOutsideClick={() => setOpen(false)}
      refChildren={({setRefElement}) => (
        <button
          ref={setRefElement}
          type="button"
          className={buttonClassName}
          onClick={() => setOpen(!isOpen)}
        >
          {children}
          {!noArrow && (
            <FontAwesomeIcon
              className="ml-2"
              icon={isOpen ? faChevronUp : faChevronDown}
            />
          )}
        </button>
      )}
      {...props}
    >
      <List
        items={items}
        isActive={(item) =>
          noActive
            ? false
            : multi
            ? value.includes(item.value)
            : item.value === value
        }
        onItemClick={(item) => {
          item.onClick && item.onClick(item)
          onChange && onChange(item)
          !multi && setOpen(false)
        }}
        accessor="label"
      />
    </PopperContainer>
  )
}

export default Dropdown
