import React, {useState} from 'react'

import {faThumbsDown, faThumbsUp} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {t} from '@lingui/macro'
import classNames from 'classnames'
import {motion} from 'framer-motion'
import {toast as hotToast, useToaster} from 'react-hot-toast'
import {Toast as HotToast} from 'react-hot-toast/dist/core/types'
import {ToastType} from 'react-hot-toast/src/core/types'
import {useInterval} from 'react-use'

import {CloseIcon} from '@/components/Icons'
import Loader from '@/components/Loader'

const Toasts = () => {
  const {toasts, handlers} = useToaster({
    duration: 10 * 1000,
    success: {
      icon: <FontAwesomeIcon icon={faThumbsUp} fixedWidth />,
      iconTheme: {
        primary: 'text-primary',
        secondary: 'text-primary',
      },
    },
    error: {
      icon: <FontAwesomeIcon icon={faThumbsDown} fixedWidth />,
      iconTheme: {
        primary: 'text-danger',
        secondary: 'text-danger',
      },
    },
    loading: {
      icon: <Loader />,
      iconTheme: {
        primary: 'text-gray',
        secondary: 'text-gray',
      },
    },
  })
  const {startPause, endPause} = handlers

  return (
    <div
      className="relative z-[2147483641]"
      aria-live="polite"
      aria-atomic="true"
    >
      <div
        className="fixed bottom-8 left-8 right-8 -mb-4 md:w-96"
        onMouseEnter={startPause}
        onMouseLeave={endPause}
      >
        {toasts.map((toast) => (
          <Toast key={toast.id} toast={toast} />
        ))}
      </div>
    </div>
  )
}

const getTitleByType = (type: ToastType) => {
  if (type === 'success') {
    return t`Success`
  }

  if (type === 'error') {
    return t`Error`
  }

  if (type === 'loading') {
    return t`Loading`
  }

  return ''
}

interface ToastProps {
  toast: HotToast
}

const toastVariants = {
  hidden: {opacity: 0, height: 0, scale: 0.8},
  visible: {opacity: 1, height: 'auto', scale: 1},
}

const Toast = ({toast}: ToastProps) => {
  const [counter, setCounter] = useState(1)

  useInterval(() => setCounter(counter + 1), 1000)

  return (
    <motion.div
      className="overflow-hidden"
      initial="hidden"
      animate={toast.visible ? 'visible' : 'hidden'}
      variants={toastVariants}
    >
      <div
        className="mb-4 rounded border border-gray-light bg-white p-3 dark:bg-dark"
        {...toast.ariaProps}
      >
        <div
          className={classNames(toast.className, 'flex items-center space-x-2')}
        >
          <div className={toast.iconTheme?.primary}>{toast.icon}</div>

          <div className="w-full font-bold">{getTitleByType(toast.type)}</div>

          <div className="whitespace-nowrap text-sm">
            {counter === 1 && t`${counter} second`}
            {counter > 1 && t`${counter} seconds`}
          </div>

          <CloseIcon onClick={() => hotToast.dismiss(toast.id)} />
        </div>

        {toast.message}
      </div>
    </motion.div>
  )
}

export default Toasts
