import React from 'react'

import {Trans} from '@lingui/macro'

import FacebookWidget from '@/components/FacebookWidget'
import {Title} from '@/components/Title'

const FACEBOOK_WIDGET_CALENDAR_SRC =
  'https://www.facebook.com/plugins/video.php?height=340&href=https%3A%2F%2Fwww.facebook.com%2Fdartsturniere%2Fvideos%2F592940794699977%2F&show_text=true&width=552&t=0'

const TournamentCalendar = () => (
  <div>
    <Title>
      <Trans>Guide tournament calendar</Trans>
    </Title>

    <FacebookWidget src={FACEBOOK_WIDGET_CALENDAR_SRC} />
  </div>
)

export default TournamentCalendar
