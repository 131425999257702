import React from 'react'

import classNames from 'classnames'

import Loader from '@/components/Loader'

interface FacebookWidgetProps {
  className?: string
  src: string
}

const FacebookWidget = ({className = '', src}: FacebookWidgetProps) => (
  <div
    className={classNames(
      'relative mx-auto max-w-xl overflow-hidden rounded border-none',
      className
    )}
  >
    <iframe
      src={src}
      width="100%"
      height="496px"
      scrolling="no"
      frameBorder="0"
      allow="encrypted-media"
    />

    <div className="absolute inset-0 z-[-1] flex items-center justify-center bg-white">
      <Loader className="text-5xl text-primary" />
    </div>
  </div>
)

export default FacebookWidget
