import {SearchParamsProps} from '@/components/Table'

import {queryClient} from '@/lib/query'
import {TournamentModel} from '@/models/Tournament'
import {
  successAcceptTournamentToast,
  successActivateTournamentToast,
  successCanceledTournamentToast,
  successCreateTournamentToast,
  successDismissTournamentToast,
  successUpdateTournamentToast,
} from '@/toasts/tournament'

export const toggleTournamentCanceled = async (
  tournament: TournamentModel,
  searchParams: SearchParamsProps
) => {
  await queryClient.invalidateQueries([TournamentModel.KEY, searchParams])
  await queryClient.invalidateQueries([TournamentModel.MY_KEY, searchParams])

  tournament.games[0].canceled
    ? successActivateTournamentToast(tournament)
    : successCanceledTournamentToast(tournament)
}

export const acceptTournament = async (
  tournament: TournamentModel,
  searchParams: SearchParamsProps
) => {
  await queryClient.invalidateQueries([
    TournamentModel.REVIEW_KEY,
    searchParams,
  ])

  successAcceptTournamentToast(tournament)
}

export const dismissTournament = async (
  tournament: TournamentModel,
  searchParams: SearchParamsProps
) => {
  await queryClient.invalidateQueries([
    TournamentModel.REVIEW_KEY,
    searchParams,
  ])

  successDismissTournamentToast(tournament)
}

export const createTournament = (tournament: TournamentModel) =>
  successCreateTournamentToast(tournament)

export const updateTournament = (tournament: TournamentModel) =>
  successUpdateTournamentToast(tournament)
