import React from 'react'

import {Trans} from '@lingui/macro'

import {Title} from '@/components/Title'
import SentEmailsTable from '@/components/tabels/SentEmailsTable'

const SentEmails = () => (
  <div>
    <Title>
      <Trans>Sent emails</Trans>
    </Title>

    <SentEmailsTable />
  </div>
)

export default SentEmails
