import React from 'react'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import {postVerify} from '@/api/users'

import {LayoutLoader} from '@/components/Loader'

import {verifyUser} from '@/actions/user'
import {useAuth} from '@/lib/auth'
import {LocalizedNavigate, useLocalizedNavigate} from '@/lib/router'
import {
  errorEmailVerifiedToast,
  errorLoggedInToast,
  errorVerifyToast,
} from '@/toasts/auth'

const requiredQueryParams = ['id', 'expires', 'hash', 'signature']

export interface VerifyProps {
  id: string
  expires: string
  hash: string
  signature: string
}

const Verify = () => {
  const location = useLocation()
  const {user} = useAuth()
  const navigate = useLocalizedNavigate()
  const query = new URLSearchParams(location.search)

  if (!user) {
    errorLoggedInToast()
    return <LocalizedNavigate to="/auth/login" state={{from: location}} />
  }

  if (user.emailVerifiedAt) {
    errorEmailVerifiedToast()
    return <LocalizedNavigate to="/" />
  }

  if (
    requiredQueryParams.filter(
      (requiredQueryParam) => !query.has(requiredQueryParam)
    ).length
  ) {
    errorVerifyToast()

    return <LocalizedNavigate to="/" />
  }

  useEffect(() => {
    postVerify(query as unknown as VerifyProps)
      .then(() => {
        verifyUser(user)
        navigate(user.redirectUrl())
      })
      .catch(() => {
        errorVerifyToast()
        navigate('/auth/verify/email')
      })
  }, [])

  return <LayoutLoader />
}

export default Verify
