import React, {ReactChild, useEffect, useMemo, useState} from 'react'

import {
  faArrowDown,
  faArrowUp,
  faCalendar,
  faChevronLeft,
  faChevronRight,
  faClock,
  faHourglass,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {i18n} from '@lingui/core'
import {Trans} from '@lingui/macro'
import classNames from 'classnames'
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  getDate,
  getHours,
  getMinutes,
  intlFormat,
  isBefore,
  isSameDay,
  isSameMonth,
  isToday,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  startOfISOWeek,
  startOfMonth,
  subHours,
  subMinutes,
  subMonths,
} from 'date-fns'
import {useField, useFormikContext} from 'formik'
import {parseInt} from 'lodash'
import {useUpdateEffect} from 'react-use'

import {Input} from '@/components/fields/FieldInput'

import {getShortWeekDays, intlFormatDateTime} from '@/lib/date'
import {FieldInterface, FormControl} from '@/lib/form'
import {PopperContainer} from '@/lib/popper'

interface DatePickerProps {
  startDate?: Date
  className?: string
  value: Date[]
  onChange: (dates: Date[]) => void
  onBlur?: () => void
  disabledBefore?: Date
  loading?: boolean
  disabled?: boolean
  invalid?: boolean
  valid?: boolean
  required?: boolean
  icon?: ReactChild
  autoFocus?: boolean
  readOnly?: boolean
  disableDelete?: boolean
  disableAdd?: boolean
}

const DatePicker = ({
  value = [],
  className,
  startDate = new Date(),
  onChange,
  onBlur = () => null,
  disabledBefore,
  disableDelete,
  disableAdd,
  ...props
}: DatePickerProps) => {
  const [view, setView] = useState(disableAdd ? 'time' : 'calendar')
  const [isFocused, setFocused] = useState(false)
  const [viewDate, setViewDate] = useState(startDate)
  const [activeDateIndex, setActiveDateIndex] = useState<number | null>(
    value.length ? 0 : null
  )

  const updateActiveDate = (date: Date) => {
    if (activeDateIndex === null) {
      onChange([date])
    }

    if (activeDateIndex !== null) {
      const dates = [...value]
      dates[activeDateIndex] = date
      onChange(dates)
    }
  }

  useUpdateEffect(() => {
    if (activeDateIndex !== null && activeDateIndex > value.length - 1) {
      setActiveDateIndex(value.length === 0 ? null : value.length - 1)
    }
  }, [value])

  return (
    <PopperContainer
      hidden={!isFocused}
      placement="bottom"
      onOutsideClick={() => setFocused(false)}
      refChildren={({setRefElement}) => (
        <div ref={setRefElement} className="group">
          <Input
            value={value.map((date) => intlFormatDateTime(date)).join('; ')}
            onFocus={() => setFocused(true)}
            {...props}
            readOnly
          />

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setFocused(true)}
            disabled={props.disabled}
          >
            <FontAwesomeIcon icon={faCalendar} />
          </button>
        </div>
      )}
      noSameWidth
    >
      <div className="rounded border border-gray-light bg-white text-dark dark:bg-dark dark:text-white">
        <div className="mb-1 h-96 w-80">
          {view === 'calendar' && (
            <div className="divide-y-2 divide-gray-light dark:divide-gray-dark">
              <CalendarHeader date={viewDate} onChange={setViewDate} />

              <Weekdays />

              <Calendar
                viewDate={viewDate}
                activeDates={value}
                onChange={(date) => {
                  const dates = [...value, date]
                  onChange(dates)
                  setActiveDateIndex(dates.length - 1)
                  setView('time')
                }}
                disabledBefore={disabledBefore}
              />
            </div>
          )}

          {view === 'time' && (
            <div className="items-center divide-y-2 divide-gray-light dark:divide-gray-dark">
              <TimeHeader
                activeDates={value}
                activeDateIndex={activeDateIndex}
                onChange={setActiveDateIndex}
                disabledBefore={disabledBefore}
              />

              <Time
                date={
                  activeDateIndex === null
                    ? disabledBefore || new Date()
                    : value[activeDateIndex]
                }
                onChange={updateActiveDate}
                disabledBefore={disabledBefore}
              />
            </div>
          )}

          {view === 'remove' && (
            <RemoveDate
              dates={value}
              onChange={(index) => {
                value.splice(index, 1)
                onChange([...value])
              }}
            />
          )}
        </div>

        <ActionBar
          remove={view === 'remove'}
          today={isToday(viewDate)}
          viewIcon={view === 'calendar' ? faClock : faCalendar}
          onView={() => setView(view === 'calendar' ? 'time' : 'calendar')}
          onTrash={() => setView('remove')}
          onClose={() => {
            setFocused(false)
            onBlur()
          }}
          onToday={() => {
            if (view === 'calendar') {
              setViewDate(new Date())
              return
            }

            if (view === 'time') {
              updateActiveDate(new Date())
              return
            }

            setViewDate(new Date())
            setView('calendar')
          }}
          disableDelete={disableDelete}
          disableAdd={disableAdd}
        />
      </div>
    </PopperContainer>
  )
}

interface ActionBarProps {
  today: boolean
  remove: boolean
  viewIcon: any
  onTrash: () => void
  onView: () => void
  onToday: () => void
  onClose: () => void
  disableDelete?: boolean
  disableAdd?: boolean
}

const ActionBar = ({
  today,
  remove,
  viewIcon,
  onTrash,
  onView,
  onClose,
  onToday,
  disableDelete,
  disableAdd,
}: ActionBarProps) => (
  <div className="flex cursor-pointer divide-x divide-gray-light border-t border-gray-light text-center dark:divide-gray-dark dark:border-gray-dark">
    <Action onClick={onView} disabled={disableAdd}>
      <FontAwesomeIcon icon={viewIcon} fixedWidth />
    </Action>

    <Action onClick={onToday} active={today}>
      <FontAwesomeIcon icon={faHourglass} fixedWidth />
    </Action>

    <Action onClick={onTrash} active={remove} disabled={disableDelete}>
      <FontAwesomeIcon icon={faTrash} fixedWidth />
    </Action>

    <Action onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} fixedWidth />
    </Action>
  </div>
)

interface ActionProps {
  className?: string
  active?: boolean
  children: JSX.Element | JSX.Element[]
  onClick: () => void
  disabled?: boolean
}

const Action = ({className, active, ...props}: ActionProps) => (
  <button
    type="button"
    className={classNames(
      className,
      'w-full p-3',
      active && 'bg-primary',
      !active &&
        !props.disabled &&
        'hover:bg-gray-light dark:hover:bg-gray-dark',
      props.disabled && 'bg-gray-light bg-opacity-50'
    )}
    {...props}
  />
)

interface RemoveDateProps {
  dates: Date[]
  onChange: (index: number) => void
}

const RemoveDate = ({dates, onChange}: RemoveDateProps) => (
  <div className="divide-y-2 divide-gray-light dark:divide-gray-dark">
    <div className="p-3 text-center">
      <Trans>Remove dates</Trans>
    </div>

    <div className="h-85 divide-y divide-gray-light overflow-y-auto dark:divide-gray-dark">
      {!dates.length && (
        <div className="flex h-full items-center justify-center">
          <div>
            <Trans>No dates selected</Trans>
          </div>
        </div>
      )}

      {dates.map((date, index) => (
        <div
          key={index}
          className="flex cursor-pointer p-3 hover:bg-primary-light"
          onClick={() => onChange(index)}
        >
          <div className="w-full">{intlFormatDateTime(date)}</div>

          <div className="text-danger">
            <FontAwesomeIcon icon={faTrash} fixedWidth />
          </div>
        </div>
      ))}
    </div>
  </div>
)

interface TimeHeaderProps {
  activeDateIndex: number | null
  activeDates: Date[]
  onChange: (index: number | null) => void
  disabledBefore?: Date
}

const TimeHeader = ({
  activeDateIndex,
  activeDates,
  onChange,
  disabledBefore,
}: TimeHeaderProps) => (
  <div className="flex items-center">
    <HeaderAction
      onClick={() =>
        onChange(
          activeDateIndex === null
            ? null
            : activeDateIndex === 0
            ? activeDates.length - 1
            : activeDateIndex - 1
        )
      }
    >
      <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
    </HeaderAction>

    <div className="w-full text-center">
      {intlFormatDateTime(
        activeDateIndex === null
          ? disabledBefore || new Date()
          : activeDates[activeDateIndex]
      )}
    </div>

    <HeaderAction
      onClick={() =>
        onChange(
          activeDateIndex === null
            ? null
            : activeDateIndex === activeDates.length - 1
            ? 0
            : activeDateIndex + 1
        )
      }
    >
      <FontAwesomeIcon icon={faChevronRight} fixedWidth />
    </HeaderAction>
  </div>
)

interface TimeButton {
  children: JSX.Element | JSX.Element[]
  onClick: () => void
  disabled?: boolean
}

const TimeButton = (props: TimeButton) => (
  <button
    type="button"
    className={classNames(
      'rounded px-5 py-3',
      !props.disabled &&
        'cursor-pointer hover:bg-gray-light dark:hover:bg-gray-dark',
      props.disabled && 'cursor-default text-gray-dark'
    )}
    {...props}
  />
)

interface TimeProps {
  date: Date
  onChange: (date: Date) => void
  disabledBefore?: Date
}

const Time = ({date, onChange, disabledBefore}: TimeProps) => {
  const [tmpHours, setTmpHours] = useState(
    (getHours(date) < 10 ? '0' : '') + getHours(date)
  )
  const [tmpMinutes, setTmpMinutes] = useState(
    (getMinutes(date) < 10 ? '0' : '') + getMinutes(date)
  )

  useEffect(() => {
    setTmpHours((getHours(date) < 10 ? '0' : '') + getHours(date))
    setTmpMinutes((getMinutes(date) < 10 ? '0' : '') + getMinutes(date))
  }, [date])

  return (
    <div className="space-y-7 py-16 text-xl">
      <div className="flex justify-around">
        <TimeButton onClick={() => onChange(addHours(date, 1))}>
          <FontAwesomeIcon icon={faArrowUp} />
        </TimeButton>

        <div className="p-3" />

        <TimeButton onClick={() => onChange(addMinutes(date, 1))}>
          <FontAwesomeIcon icon={faArrowUp} />
        </TimeButton>
      </div>

      <div className="flex justify-around">
        <div className="p-3">
          <Input
            className="block w-8 min-w-0 bg-white text-center focus:outline-none dark:bg-dark"
            value={tmpHours}
            onChange={({target}) =>
              setTmpHours(target.value.replace(/[^0-9.]/g, ''))
            }
            onBlur={() => {
              if (parseInt(tmpHours) > 23) {
                return onChange(setHours(date, 23))
              }

              if (parseInt(tmpHours) < 0) {
                return onChange(setHours(date, 0))
              }

              onChange(setHours(date, parseInt(tmpHours)))
            }}
            noStyle
          />
        </div>

        <div className="p-3">:</div>

        <div className="p-3">
          <Input
            className="no block w-8 min-w-0 bg-white text-center focus:outline-none dark:bg-dark"
            value={tmpMinutes}
            onChange={({target}) =>
              setTmpMinutes(target.value.replace(/[^0-9.]/g, ''))
            }
            onBlur={() => {
              if (parseInt(tmpMinutes) > 59) {
                return onChange(setMinutes(date, 59))
              }

              if (parseInt(tmpMinutes) < 0) {
                return onChange(setMinutes(date, 0))
              }

              onChange(setMinutes(date, parseInt(tmpMinutes)))
            }}
            noStyle
          />
        </div>
      </div>

      <div className="flex justify-around">
        <TimeButton
          onClick={() => onChange(subHours(date, 1))}
          disabled={
            disabledBefore && isBefore(subHours(date, 1), disabledBefore)
          }
        >
          <FontAwesomeIcon icon={faArrowDown} />
        </TimeButton>

        <div className="p-3" />

        <TimeButton
          onClick={() => onChange(subMinutes(date, 1))}
          disabled={
            disabledBefore && isBefore(subMinutes(date, 1), disabledBefore)
          }
        >
          <FontAwesomeIcon icon={faArrowDown} />
        </TimeButton>
      </div>
    </div>
  )
}

interface CalendarHeaderProps {
  date: Date
  onChange: (date: Date) => void
}

const CalendarHeader = ({date, onChange}: CalendarHeaderProps) => (
  <div className="flex items-center">
    <HeaderAction onClick={() => onChange(subMonths(date, 1))}>
      <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
    </HeaderAction>

    <div className="w-full text-center">
      {intlFormat(
        date,
        {
          month: 'long',
          year: 'numeric',
        },
        {
          locale: i18n.locale,
        }
      )}
    </div>

    <HeaderAction onClick={() => onChange(addMonths(date, 1))}>
      <FontAwesomeIcon icon={faChevronRight} fixedWidth />
    </HeaderAction>
  </div>
)

const Weekdays = () => {
  const shortWeekDays = useMemo(getShortWeekDays, [])

  return (
    <div className="flex text-primary">
      {shortWeekDays.map((shortWeekDay) => (
        <div key={shortWeekDay} className="w-full p-3">
          {shortWeekDay}
        </div>
      ))}
    </div>
  )
}

const getMonth = (date: Date) => {
  const monthBegin = startOfMonth(date)
  let monthDay = startOfISOWeek(monthBegin)
  const weeks = []
  let week = []

  for (let i = 0; i < 42; i++) {
    week.push(
      setMilliseconds(setSeconds(setMinutes(setHours(monthDay, 12), 0), 0), 0)
    )

    if (week.length >= 7) {
      weeks.push(week)
      week = []
    }
    monthDay = addDays(monthDay, 1)
  }

  return weeks
}

interface CalendarProps {
  viewDate: Date
  onChange: (date: Date) => void
  activeDates: Date[]
  disabledBefore?: Date
}

const Calendar = ({
  viewDate,
  activeDates,
  onChange,
  disabledBefore,
}: CalendarProps) => (
  <table className="w-full">
    <tbody>
      {getMonth(viewDate).map((week, index) => (
        <tr key={index}>
          {week.map((date, index) => {
            const isToday = isSameDay(date, new Date())
            const isOther = !isSameMonth(date, viewDate)
            const isDisabled =
              disabledBefore && isBefore(setHours(date, 24), disabledBefore)
            const isTimeDisabled =
              disabledBefore && isBefore(setHours(date, 0), disabledBefore)
            const activeAmount = activeDates.filter((activeDate) =>
              isSameDay(date, activeDate)
            ).length

            return (
              <td
                key={index}
                className={classNames(
                  'relative rounded p-3 text-center',
                  isDisabled && 'text-gray-dark',
                  !isDisabled && 'cursor-pointer',
                  !isDisabled &&
                    !activeAmount &&
                    'hover:bg-gray-light dark:hover:bg-gray-dark',
                  !isDisabled && isOther && 'text-gray-dark hover:text-white',
                  isToday && 'today',
                  activeAmount &&
                    'bg-primary text-white hover:bg-primary hover:text-gray-dark'
                )}
                onClick={() =>
                  !isDisabled &&
                  onChange(
                    disabledBefore && isTimeDisabled
                      ? setHours(date, disabledBefore.getHours() + 1)
                      : date
                  )
                }
              >
                {getDate(date)}
                {activeAmount > 1 && (
                  <small className="absolute top-0 right-1 text-white">
                    {activeAmount}
                  </small>
                )}
              </td>
            )
          })}
        </tr>
      ))}
    </tbody>
  </table>
)

interface HeaderActionProps {
  onClick: () => void
  children: JSX.Element | JSX.Element[]
}

const HeaderAction = (props: HeaderActionProps) => (
  <div
    className="cursor-pointer p-3 text-primary hover:bg-gray-light dark:hover:bg-gray-dark"
    {...props}
  />
)

interface FieldDatePickerProps extends FieldInterface {
  disabledBefore?: Date
  startDate?: Date
  disabled?: boolean
  required?: boolean
  disableDelete?: boolean
  disableAdd?: boolean
}

export const FieldDate = ({name, disabled, ...props}: FieldDatePickerProps) => {
  const [field, meta, helpers] = useField(name)
  const {isSubmitting} = useFormikContext()

  return (
    <FormControl meta={meta}>
      <DatePicker
        {...props}
        value={field.value}
        onChange={helpers.setValue}
        onBlur={() => helpers.setTouched(true)}
        invalid={
          (meta.initialTouched || meta.touched) &&
          (!!meta.error || !!meta.initialError)
        }
        valid={
          (meta.touched && !meta.error) ||
          (meta.initialTouched && !meta.initialError)
        }
        disabled={isSubmitting || disabled}
      />
    </FormControl>
  )
}

export default FieldDate
