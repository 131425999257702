import {CalendarFilterProps} from '@/components/forms/CalendarFilterForm'

import {Model, ModelInterface} from '@/lib/model'
import {LeagueModel} from '@/models/League'
import {MultiSettingInterface, SettingModel} from '@/models/Setting'

export interface CalendarFilterInterface extends ModelInterface {
  radius: number
  name: string
  currentPosition: boolean
  latitude: number | null
  longitude: number | null
  leagueIds: string[]
  setting: MultiSettingInterface
}

export class CalendarFilterModel extends Model<CalendarFilterInterface> {
  radius!: number
  name!: string
  email!: string
  currentPosition!: boolean
  latitude!: number | null
  longitude!: number | null
  token: string

  public static KEY = 'calendar-filters'

  get setting(): SettingModel {
    return this.singleRelationship(SettingModel, 'setting')
  }

  set setting(settingModel: SettingModel) {
    this.relationships['setting'] = settingModel
  }

  get leagues(): LeagueModel[] {
    return this.manyRelationship(LeagueModel, 'leagues') || []
  }

  get leagueIds(): string[] {
    return this.leagues.map((league) => league.id)
  }

  public toInitValues(): CalendarFilterProps {
    return {
      ...this.only([
        'id',
        'radius',
        'latitude',
        'longitude',
        'currentPosition',
        'active',
      ]),
      name: this.name || '',
      setting: this.setting.getAttributes(),
      leagueIds: this.leagueIds,
    }
  }
}
