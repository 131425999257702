import {SearchParamsProps} from '@/components/Table'

import {Model, ModelInterface} from '@/lib/model'

export interface SentEmailInterface extends ModelInterface {
  from: string
  to: string
  cc?: string
  bcc?: string
  subject?: string
  locale: string
  body: string
}

export class SentEmailModel extends Model<SentEmailInterface> {
  from: string
  to: string
  cc?: string
  bcc?: string
  subject?: string
  locale: string
  body: string

  public static KEY = 'emails'
}

export const sentEmailSearchParams: SearchParamsProps = {
  'filter[search]': '',
  'page[size]': 25,
  'page[number]': 0,
  sort: '-createdAt',
}
