import React, {useRef, useState} from 'react'

import {faFile, faTrash} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import {useField, useFormikContext} from 'formik'

import {Input, InputProps} from '@/components/fields/FieldInput'

import {FormControl} from '@/lib/form'
import {errorMultiImagesToast} from '@/toasts/input'

interface FileInputProps extends InputProps<any> {
  hasValue?: boolean
  onRemove?: () => void
}

export const FileInput = ({
  className,
  hasValue,
  onRemove,
  ...props
}: FileInputProps) => {
  const ref = useRef<HTMLInputElement>(null)

  return (
    <div className="group">
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => ref.current?.click()}
        disabled={props.disabled}
      >
        <FontAwesomeIcon icon={faFile} fixedWidth />
      </button>

      <Input
        className={classNames('cursor-pointer', className)}
        {...props}
        ref={ref}
        type="file"
      />

      {hasValue && (
        <button
          type="button"
          className="btn btn-danger"
          onClick={onRemove}
          disabled={props.disabled}
        >
          <FontAwesomeIcon icon={faTrash} fixedWidth />
        </button>
      )}
    </div>
  )
}

interface FieldFileInputProps extends FileInputProps {
  multi?: boolean
}

export const FieldFile = ({
  name,
  disabled = false,
  multi = false,
  onChange,
  onRemove,
  ...props
}: FieldFileInputProps) => {
  const [, meta, helpers] = useField(name)
  const {isSubmitting} = useFormikContext()
  const [fileName, setFileName] = useState('')

  return (
    <FormControl meta={meta}>
      <FileInput
        value={fileName}
        invalid={
          (meta.initialTouched || meta.touched) &&
          (!!meta.error || !!meta.initialError)
        }
        valid={
          (meta.touched && !meta.error) ||
          (meta.initialTouched && !meta.initialError)
        }
        disabled={isSubmitting || disabled}
        onChange={(e) => {
          onChange && onChange(e)
          const files = e.currentTarget.files || []

          setFileName(e.currentTarget.value)

          if (!multi && files.length > 1) {
            errorMultiImagesToast()
          }

          helpers.setValue(multi ? files : files[0])

          setTimeout(() => helpers.setTouched(true), 500)
        }}
        onRemove={() => {
          setFileName('')
          onRemove && onRemove()
        }}
        {...props}
      />
    </FormControl>
  )
}

export default FieldFile
