import React, {Fragment, ReactNode, useEffect, useState} from 'react'

import {i18n} from '@lingui/core'
import classNames from 'classnames'
import {
  addDays,
  addMonths,
  differenceInDays,
  endOfMonth,
  intlFormat,
  isBefore,
  isSameDay,
  startOfMonth,
  subMonths,
} from 'date-fns'

import Loader from '@/components/Loader'

import {intlFormatDate} from '@/lib/date'

export const CALENDAR_VIEW_AGENDA_MONTH = 'agenda_month'

declare type CALENDAR_VIEWS = 'agenda_month' | 'week' | 'month' | 'day'

export const CALENDAR_NAVIGATE_TODAY = 'today'
export const CALENDAR_NAVIGATE_PREV = 'prev'
export const CALENDAR_NAVIGATE_NEXT = 'next'

declare type CALENDAR_NAVIGATE_ACTIONS = 'today' | 'prev' | 'next'

interface CalendarProps {
  className?: string
  defaultDate?: Date
  defaultView?: CALENDAR_VIEWS
  min?: Date
  events: any[]
  components: {
    toolbar: (toolbar: ToolbarProps) => any
    agendaMonth: (event: EventProps) => any
    noEvents: (noEvents: BaseCalendarProps) => any
  }
  onChange: (start: Date, end: Date) => void
  loading?: boolean
  error?: string
  reloadDep?: any[]
}

export interface EventProps {
  title: string
  start: Date
}

interface BaseCalendarProps {
  view: CALENDAR_VIEWS
  viewDate: Date
  onView: (view: CALENDAR_VIEWS) => void
  onNavigate: (action: CALENDAR_NAVIGATE_ACTIONS) => void
}

export interface ToolbarProps extends BaseCalendarProps {
  label: string
}

const Calendar = ({
  className,
  defaultDate = new Date(),
  defaultView = CALENDAR_VIEW_AGENDA_MONTH,
  components,
  events,
  onChange,
  ...props
}: CalendarProps) => {
  const [viewDate, setViewDate] = useState(defaultDate)
  const [view, setView] = useState(defaultView)

  const viewProps = {
    view,
    viewDate,
    events,
    onChange,
  }

  const baseCalendarProps = {
    view,
    viewDate,
    onView: setView,
    onNavigate: (action) => setViewDate(navigate(action, view, viewDate)),
  }

  return (
    <div className={classNames('flex flex-col', className)}>
      {React.createElement(components.toolbar, {
        label: intlFormat(
          viewDate,
          {
            month: 'long',
            year: 'numeric',
          },
          {
            locale: i18n.locale,
          }
        ),
        ...baseCalendarProps,
      })}

      <div className="h-nav">
        <ViewAgenda
          {...viewProps}
          children={components.agendaMonth}
          noEvents={components.noEvents(baseCalendarProps)}
          {...props}
        />
      </div>
    </div>
  )
}

const navigate = (
  action: CALENDAR_NAVIGATE_ACTIONS,
  view: CALENDAR_VIEWS,
  viewDate: Date
) => {
  if (
    action === CALENDAR_NAVIGATE_NEXT &&
    view.includes(CALENDAR_VIEW_AGENDA_MONTH)
  ) {
    return addMonths(viewDate, 1)
  }

  if (
    action === CALENDAR_NAVIGATE_PREV &&
    view.includes(CALENDAR_VIEW_AGENDA_MONTH)
  ) {
    return subMonths(viewDate, 1)
  }

  if (action === CALENDAR_NAVIGATE_TODAY) {
    return new Date()
  }

  return viewDate
}

interface ViewProps {
  view: CALENDAR_VIEWS
  viewDate: Date
  events: EventProps[]
  children: any
  onChange: (start: Date, end: Date) => void
  noEvents: ReactNode
  loading?: boolean
  error?: string
  min?: Date
  reloadDep?: any[]
}

const ViewAgenda = ({
  view,
  viewDate,
  events,
  onChange,
  noEvents,
  loading,
  error,
  min,
  reloadDep = [],
  ...props
}: ViewProps) => {
  if (view !== CALENDAR_VIEW_AGENDA_MONTH) {
    return null
  }
  const monthStart = startOfMonth(viewDate)
  const monthEnd = endOfMonth(viewDate)
  const diff = differenceInDays(monthEnd, monthStart) + 1
  const monthEvents = []

  for (let i = 0; i < diff; i++) {
    monthEvents.push(
      events.filter((event) => isSameDay(event.start, addDays(monthStart, i)))
    )
  }

  useEffect(() => {
    onChange(min && isBefore(monthStart, min) ? min : monthStart, monthEnd)
  }, [viewDate, ...reloadDep])

  return (
    <div className="h-full overflow-y-auto overflow-x-hidden rounded bg-white dark:bg-dark">
      {!loading && !error && !events.length && noEvents}

      {loading && (
        <div className="flex h-full items-center justify-center">
          <div className="text-6xl">
            <Loader />
          </div>
        </div>
      )}

      {error && (
        <div className="flex h-full items-center justify-center">
          <div className="text-xl">{error}</div>
        </div>
      )}

      {!loading &&
        !error &&
        !!events.length &&
        monthEvents.map((dailyEvents, index) => {
          if (!dailyEvents.length) {
            return null
          }
          const headerDate = dailyEvents[0].start

          return (
            <Fragment key={index}>
              <div className="flex justify-between bg-primary p-3 font-bold text-white hover:bg-primary-light">
                <div>{intlFormatDate(headerDate)}</div>

                <div>
                  {intlFormat(
                    headerDate,
                    {
                      weekday: 'long',
                    },
                    {
                      locale: i18n.locale,
                    }
                  )}
                </div>
              </div>

              <div className="divide-y divide-gray-light">
                {dailyEvents.map((event, index) => (
                  <div key={index}>
                    <props.children {...event} />
                  </div>
                ))}
              </div>
            </Fragment>
          )
        })}
    </div>
  )
}

export default Calendar
