import React from 'react'

import {Trans} from '@lingui/macro'

import {patchTournament} from '@/api/tournaments'

import {useTournament} from '@/hooks/query/useTournament'

import {LayoutLoader} from '@/components/Loader'
import {Title} from '@/components/Title'
import TournamentForm from '@/components/forms/TournamentForm'
import {CenteredLayoutMessage} from '@/components/layouts/DefaultLayout'

import {updateTournament} from '@/actions/tournament'
import {useAuth} from '@/lib/auth'
import {useLocalizedNavigate, useSlug} from '@/lib/router'
import {TournamentModel} from '@/models/Tournament'

const EditTournament = () => {
  const slug = useSlug()
  const {user} = useAuth()
  const navigate = useLocalizedNavigate()
  const {data: tournament, isLoading, isError} = useTournament(slug)

  if (isLoading) {
    return <LayoutLoader />
  }

  if (isError) {
    return (
      <CenteredLayoutMessage>
        <Trans>500 - Internal server error</Trans>
      </CenteredLayoutMessage>
    )
  }

  if (!tournament.creator.isAdmin() && tournament.creator.id === user.id) {
    return (
      <CenteredLayoutMessage>
        <Trans>403 - Permission denied error</Trans>
      </CenteredLayoutMessage>
    )
  }

  return (
    <div>
      <Title>
        <Trans>Edit tournament</Trans>
      </Title>

      <TournamentForm
        initialValues={tournament.toInitValues()}
        onSubmit={async (values, formikHelpers) => {
          const tournamentPayload = await patchTournament(values, formikHelpers)
          const tournament =
            TournamentModel.fromNormalizedPayload(tournamentPayload).data
          updateTournament(tournament)
          navigate('/profile/tournaments')
        }}
      />
    </div>
  )
}

export default EditTournament
