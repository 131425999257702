import React from 'react'

import {Trans} from '@lingui/macro'
import classNames from 'classnames'

import {useNextGames} from '@/hooks/query/useGame'

import {AlertDanger, AlertInfo} from '@/components/Alert'
import Loader from '@/components/Loader'
import {Title} from '@/components/Title'
import SmallGameInformation from '@/components/games/SmallGameInformation'
import LocationMap from '@/components/locations/LocationMap'

import {LocalizedLink} from '@/lib/router'
import {GameModel} from '@/models/Game'

const NextGames = () => (
  <section className="pt-16 md:pb-16">
    <div className="container">
      <Title>
        <Trans>Current darts tournaments</Trans>
      </Title>

      <GameList />
    </div>
  </section>
)

const GameList = () => {
  const {data: nextGames, isLoading, isError, error} = useNextGames()

  if (isLoading) {
    return (
      <div className="py-16 text-center">
        <Loader className="text-5xl" />
      </div>
    )
  }

  if (isError || !nextGames) {
    return (
      <AlertDanger className="mx-auto w-full md:w-6/12">
        {String(error)}
      </AlertDanger>
    )
  }

  if (!nextGames.length) {
    return (
      <AlertInfo className="mx-auto w-full md:w-6/12">
        <Trans>There are no tournaments at the moment!</Trans>
      </AlertInfo>
    )
  }

  return (
    <div data-test-id="next-games">
      {nextGames.map((game, index) => (
        <GameListItem key={game.id} game={game} right={index % 2 === 0} />
      ))}
    </div>
  )
}

interface GameListItemProps {
  game: GameModel
  right: boolean
}

const GameListItem = ({game, right}: GameListItemProps) => (
  <div className="mb-10 flex flex-wrap">
    <div className="w-full lg:order-2 lg:w-4/12">
      <div className="flex h-full items-center justify-center bg-white px-5 py-16 dark:bg-dark">
        <div className="inline-block text-gray">
          <LocalizedLink
            className="text-xl font-bold md:text-2xl"
            to={game.link()}
          >
            {game.tournament.name}
          </LocalizedLink>

          <SmallGameInformation game={game} />

          <hr className="mt-5 mb-0 ml-0 hidden w-3/4 border-2 border-primary lg:block" />
        </div>
      </div>
    </div>

    <div className={classNames('w-full lg:w-8/12', !right && 'lg:order-last')}>
      <LocationMap height="500px" location={game.tournament.location} />
    </div>
  </div>
)

export default NextGames
