import React, {MouseEvent, ReactChild} from 'react'

import classNames from 'classnames'

interface GlobalListProps<T> {
  accessor?: ((item: T) => string | ReactChild) | string | ReactChild
  isActive?: (item: T) => boolean
  itemProps?: (item: T) => object
  onItemClick?: (item: T, event: MouseEvent) => void
}

interface ListProps<T> extends GlobalListProps<T> {
  className?: string
  items: T[] | ReadonlyArray<T>
  emptyMessage?: string
}

const List = ({items, className, emptyMessage, ...props}: ListProps<any>) => (
  <div
    className={classNames(
      'divide-y divide-gray-light rounded border border-gray-light bg-white text-dark dark:bg-dark dark:text-white',
      className
    )}
  >
    {items.map((item, index) => (
      <ListItem key={index} item={item} {...props} />
    ))}
    {!items.length && <ListItem item={emptyMessage} />}
  </div>
)

interface ListItemProps<T> extends GlobalListProps<T> {
  item: T
}

const ListItem = ({
  item,
  accessor,
  isActive = () => false,
  itemProps = () => ({}),
  onItemClick = () => null,
}: ListItemProps<any>) => {
  const active = isActive(item)
  const isString = typeof accessor === 'string'
  const isFunction = typeof accessor === 'function'
  const isNoAccessor = !accessor

  return (
    <div
      className={classNames(
        'cursor-pointer p-4',
        active && 'bg-primary text-white',
        !active && 'hover:bg-primary hover:bg-opacity-50 hover:text-white'
      )}
      onClick={(e) => onItemClick(item, e)}
      {...itemProps(item)}
    >
      {isFunction && accessor(item)}
      {isString && item[accessor]}
      {isNoAccessor && item}
    </div>
  )
}

export default List
