import React from 'react'

import {Switch as UISwitch} from '@headlessui/react'
import classNames from 'classnames'
import {useField, useFormikContext} from 'formik'

import {FieldInterface, FormControl} from '@/lib/form'

export interface SwitchProps {
  type?: 'button' | 'submit' | 'reset'
  checked?: boolean
  onChange?: (checked: boolean) => void
  className?: string
  children?: any
  disabled?: boolean
}

const Switch = ({
  type = 'button',
  disabled = false,
  checked = false,
  onChange = () => null,
  className = '',
  children,
}: SwitchProps) => (
  <UISwitch
    type={type}
    className={classNames(
      className,
      'flex items-center space-x-2 focus:outline-none'
    )}
    checked={checked}
    onChange={onChange}
    disabled={disabled}
  >
    <div
      className={classNames(
        'flex h-7 w-12 items-center justify-start rounded-full transition-colors duration-200 ease-in-out',
        disabled && 'bg-gray-light',
        !disabled &&
          'cursor-pointer bg-primary focus:ring-4 focus:ring-primary focus:ring-opacity-50 dark:focus:ring-primary dark:focus:ring-opacity-50',
        !disabled && checked && 'bg-opacity-50'
      )}
    >
      <div
        className={classNames(
          'pointer-events-none relative ml-1 inline-block h-5 w-5 transform rounded-full bg-white shadow-lg transition duration-200 ease-in-out',
          checked ? 'translate-x-5' : 'translate-x-0'
        )}
      />
    </div>

    <div>{children}</div>
  </UISwitch>
)

export const FieldSwitch = ({name, ...props}: SwitchProps & FieldInterface) => {
  const [field, meta, helpers] = useField(name)
  const {isSubmitting} = useFormikContext()

  return (
    <FormControl meta={meta}>
      <Switch
        disabled={isSubmitting}
        {...field}
        {...props}
        checked={field.value}
        onChange={helpers.setValue}
      />
    </FormControl>
  )
}

export default Switch
