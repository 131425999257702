import {CalendarFilterModel} from '@/models/CalendarFilter'
import {UserModel} from '@/models/User'
import {
  successCreateCalendarFilterToast,
  successUpdateCalendarFilterToast,
} from '@/toasts/tournament'

export const createCalendarFilter = (
  user: UserModel | null,
  calendarFilter: CalendarFilterModel
) => {
  successCreateCalendarFilterToast(calendarFilter)
  if (user) {
    user.calendarFilter = calendarFilter
  }
}

export const updateCalendarFilter = (
  user: UserModel,
  calendarFilter: CalendarFilterModel
) => {
  successUpdateCalendarFilterToast(calendarFilter)
  user.calendarFilter = calendarFilter
}
