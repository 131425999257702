import React from 'react'

import {Title} from '@/components/Title'
import LocationLabels from '@/components/locations/LocationLabels'
import LocationMap from '@/components/locations/LocationMap'

import {LocationModel} from '@/models/Location'

interface LocationInformationProps {
  location: LocationModel
}

const LocationInformation = ({location}: LocationInformationProps) => (
  <div className="bg-white p-5 dark:bg-dark md:p-10">
    <div className="space-y-5">
      <Title>{location.name}</Title>

      <div className="space-y-5 md:flex md:space-y-0 md:space-x-5">
        <LocationMap height="550px" location={location} />

        <div>
          <LocationLabels location={location} />
        </div>
      </div>
    </div>
  </div>
)

export default LocationInformation
