import React from 'react'

import {Trans, t} from '@lingui/macro'

import FieldInput from '@/components/fields/FieldInput'
import FieldSubmitButton from '@/components/fields/FieldSubmitButton'
import {FieldSwitch} from '@/components/fields/FieldSwitch'

import Form, {CustomFormInterface} from '@/lib/form'
import {LocalizedLink} from '@/lib/router'
import Yup from '@/lib/yup'

export interface LoginProps {
  email: string
  password: string
  rememberMe: boolean
}

const loginInitValues: LoginProps = {
  email: '',
  password: '',
  rememberMe: false,
}

const LoginForm = (props: CustomFormInterface<LoginProps>) => {
  const loginSchema = Yup.object().shape({
    email: Yup.email().required(),
    password: Yup.password().required(),
    rememberMe: Yup.bool().label(t`Remember me`),
  })

  return (
    <Form
      initialValues={loginInitValues}
      validationSchema={loginSchema}
      {...props}
    >
      {({handleSubmit, isSubmitting}) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <FieldInput name="email" placeholder={t`Email`} required autoFocus />

          <FieldInput
            type="password"
            name="password"
            placeholder={t`Password`}
            required
          />

          <FieldSwitch className="mx-auto" name="rememberMe">
            <Trans>Remember me</Trans>
          </FieldSwitch>

          <div className="mb-5 flex flex-wrap space-y-5 md:flex-nowrap md:space-y-0 md:space-x-5">
            <LocalizedLink
              to="/auth/password/forgot"
              className="block w-full"
              noStyle
            >
              <button
                type="button"
                className="btn btn-white w-full text-primary"
                disabled={isSubmitting}
              >
                {t`Forgot password?`}
              </button>
            </LocalizedLink>

            <FieldSubmitButton className="w-full whitespace-nowrap md:w-auto">
              {t`Log In`}
            </FieldSubmitButton>
          </div>

          <LocalizedLink to="/auth/registration">
            <button type="button" className="btn btn-gray w-full">
              {t`You have no account?`} <strong>{t`Register`}</strong>
            </button>
          </LocalizedLink>
        </form>
      )}
    </Form>
  )
}

export default LoginForm
