import React from 'react'

import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import DefaultLayout from '@/components/layouts/DefaultLayout'

export const DefaultLayoutLoader = () => (
  <DefaultLayout>
    <LayoutLoader />
  </DefaultLayout>
)

export const LayoutLoader = () => (
  <div className="flex min-h-content items-center justify-center text-6xl">
    <Loader />
  </div>
)

interface LoaderProps {
  className?: string
}

const Loader = ({className}: LoaderProps) => (
  <FontAwesomeIcon className={className} icon={faSpinner} spin />
)

export default Loader
