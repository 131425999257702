import React from 'react'

import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {t} from '@lingui/macro'
import {useField, useFormikContext} from 'formik'

import {IconTrophyPlacement} from '@/components/Icons'
import {Input} from '@/components/fields/FieldInput'

import {FieldInterface, FormControl} from '@/lib/form'

const MAX_PRICES = 9

interface FieldPricesProps extends FieldInterface {
  required?: boolean
}

const FieldPrices = ({name, ...props}: FieldPricesProps) => {
  const [{value}, , helpers] = useField(name)
  const prices = Array.isArray(value)
    ? !value.length
      ? ['']
      : value
    : value || ['']

  return (
    <div className="space-y-5">
      {prices.map((price: string, index: number) => (
        <FieldPrice
          key={index}
          name={`${name}.${index}`}
          onRemove={() => {
            prices.splice(index, 1)
            helpers.setValue([...prices])
          }}
          onAdd={() => helpers.setValue([...prices, ''])}
          addDisabled={index + 1 >= MAX_PRICES}
          place={index + 1}
          isLastPlace={index + 1 === prices.length}
          {...props}
        />
      ))}
    </div>
  )
}

interface FieldPrice {
  name: string
  onRemove: () => void
  onAdd: () => void
  addDisabled: boolean
  place: number
  isLastPlace: boolean
  required?: boolean
}

const FieldPrice = ({
  name,
  place,
  isLastPlace,
  addDisabled,
  onRemove,
  onAdd,
  ...props
}: FieldPrice) => {
  const [field, meta] = useField(name)
  const {isSubmitting} = useFormikContext()
  const isFirstPlace = place === 1

  return (
    <FormControl meta={meta}>
      <div className="group">
        <button type="button" className="btn btn-primary active" disabled>
          {place === 1 && (
            <IconTrophyPlacement placement={1} className="text-gold" />
          )}
          {place === 2 && (
            <IconTrophyPlacement placement={2} className="text-silver" />
          )}
          {place === 3 && (
            <IconTrophyPlacement placement={1} className="text-bronze" />
          )}
          {place > 3 && (
            <IconTrophyPlacement placement={place} className="text-white" />
          )}
        </button>

        <Input
          placeholder={t`Price`}
          {...field}
          value={field.value || ''}
          invalid={
            (meta.initialTouched || meta.touched) &&
            (!!meta.error || !!meta.initialError)
          }
          valid={
            (meta.touched && !meta.error) ||
            (meta.initialTouched && !meta.initialError)
          }
          disabled={isSubmitting}
          {...props}
        />

        {!(isLastPlace && isFirstPlace) && (
          <button
            type="button"
            className="btn btn-danger"
            onClick={onRemove}
            disabled={isSubmitting}
          >
            <FontAwesomeIcon icon={faMinus} fixedWidth />
          </button>
        )}

        {isLastPlace && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={onAdd}
            disabled={addDisabled || isSubmitting}
          >
            <FontAwesomeIcon icon={faPlus} className="fa-fw" />
          </button>
        )}
      </div>
    </FormControl>
  )
}

export default FieldPrices
