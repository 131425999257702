import React from 'react'

import {Trans, t} from '@lingui/macro'

import {FieldGroupRadiusAddressPosition} from '@/components/fields/FieldAddressAutocomplete'
import FieldGroupMapRadius from '@/components/fields/FieldGroupMapRadius'
import FieldGroupSetting from '@/components/fields/FieldGroupSetting'
import FieldLeaguesSelect from '@/components/fields/FieldLeaguesSelect'
import {FieldRequiredSubmitButton} from '@/components/fields/FieldSubmitButton'
import {FieldSwitch} from '@/components/fields/FieldSwitch'

import Form, {CustomFormInterface} from '@/lib/form'
import Yup from '@/lib/yup'
import {MultiSettingInterface} from '@/models/Setting'
import {UserModel} from '@/models/User'

export interface CalendarFilterProps {
  id?: string
  radius?: number
  name: string
  currentPosition: boolean
  latitude?: number
  longitude?: number
  leagueIds: string[]
  setting: MultiSettingInterface
}

const createCalendarFilterProps = (
  user?: UserModel | null
): CalendarFilterProps =>
  (user?.calendarFilter && user.calendarFilter.toInitValues()) || {
    radius: undefined,
    name: '',
    currentPosition: false,
    latitude: undefined,
    longitude: undefined,
    leagueIds: [],
    setting: {
      variant: [],
      value: [],
      size: [],
      out: [],
      dyp: false,
      doubleIn: false,
      mixed: false,
    },
  }

interface CalendarFilterFormProps
  extends CustomFormInterface<CalendarFilterProps> {
  user: UserModel
}

const CalendarFilterForm = ({user, ...props}: CalendarFilterFormProps) => {
  const calendarFilterForm = Yup.object().shape({
    setting: Yup.multiSetting(),
    name: Yup.string()
      .when('radius', {
        is: (radius: number) => !!radius,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      })
      .label(t`Address`),
    radius: Yup.radius().nullable(),
    latitude: Yup.latitude().when('radius', {
      is: (radius: number) => !!radius,
      then: Yup.latitude().required(),
      otherwise: Yup.latitude().nullable(),
    }),
    longitude: Yup.longitude().when('radius', {
      is: (radius: number) => !!radius,
      then: Yup.longitude().required(),
      otherwise: Yup.longitude().nullable(),
    }),
    leagueIds: Yup.leagues(),
    currentPosition: Yup.currentPosition(),
  })

  return (
    <Form
      initialValues={createCalendarFilterProps(user)}
      validationSchema={calendarFilterForm}
      {...props}
    >
      {({handleSubmit, setFieldValue}) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <FieldGroupSetting namePrefix="setting." multi />

          <FieldGroupRadiusAddressPosition
            onChange={({name, position}) => {
              setFieldValue('name', name)
              setFieldValue('latitude', position.lat())
              setFieldValue('longitude', position.lng())
            }}
            onlyAddress
          />

          <FieldLeaguesSelect required />

          <FieldGroupMapRadius />

          <FieldSwitch name="active">
            <Trans>Apply calendar filter automatically</Trans>
          </FieldSwitch>

          <FieldRequiredSubmitButton>
            {user?.calendarFilter ? (
              <Trans>Update</Trans>
            ) : (
              <Trans>Apply</Trans>
            )}
          </FieldRequiredSubmitButton>
        </form>
      )}
    </Form>
  )
}

export default CalendarFilterForm
