import {MobileNewsletterModel} from '@/models/MobileNewsletter'
import {UserModel} from '@/models/User'
import {
  successSubscribeNewsletterToast,
  successUnsubscribeNewsletterToast,
  successUpdateNewsletterToast,
} from '@/toasts/newsletter'

export const unsubscribeMobileNewsletter = (user: UserModel) => {
  successUnsubscribeNewsletterToast()
  user.removeMobileNewsletter()
}

export const subscribeMobileNewsletter = (
  user: UserModel | null,
  newsletter: MobileNewsletterModel
) => {
  successSubscribeNewsletterToast(newsletter)
  if (user) {
    user.mobileNewsletters = [newsletter]
  }
}

export const updateMobileNewsletter = (
  user: UserModel,
  newsletter: MobileNewsletterModel
) => {
  successUpdateNewsletterToast(newsletter)
  user.mobileNewsletters = [newsletter]
}
