import {formatISO} from 'date-fns'
import {FormikHelpers} from 'formik/dist/types'

import {SearchParamsProps} from '@/components/Table'
import {TournamentProps} from '@/components/forms/TournamentForm'

import {client, formClient} from '@/lib/api-client'
import {TournamentModel} from '@/models/Tournament'

export const getTournament = async (slug: string) => {
  const tournamentPayload = await client
    .get(`tournaments${slug}`, {
      searchParams: {
        include:
          'creator,location,games,games.leagues,games.setting,games.leagues,games.priceList',
      },
    })
    .json()

  return TournamentModel.normalizePayload(tournamentPayload)
}

export const getMyTournaments = async (searchParams: SearchParamsProps) => {
  const tournamentsPayload = await client
    .get('tournaments', {
      searchParams: {
        include: 'games,games.setting,games.leagues,location,creator',
        'filter[my]': true,
        ...searchParams,
      },
    })
    .json()

  return TournamentModel.normalizePayload(tournamentsPayload)
}

export const getTournaments = async (searchParams: SearchParamsProps) => {
  const tournamentsPayload = await client
    .get('tournaments', {
      searchParams: {
        include: 'games,games.setting,games.leagues,location,creator',
        ...searchParams,
      },
    })
    .json()

  return TournamentModel.normalizePayload(tournamentsPayload)
}

export const getReviewTournaments = async (searchParams: SearchParamsProps) => {
  const tournamentsPayload = await client
    .get('tournaments', {
      searchParams: {
        include: 'games,games.setting,games.leagues,location,creator',
        'filter[review]': true,
        ...searchParams,
      },
    })
    .json()

  return TournamentModel.normalizePayload(tournamentsPayload)
}

export const postToggleCancelTournament = async (slug: string) => {
  await client.post(`tournaments/${slug}/cancel`).json()
}

export const postAcceptTournament = async (slug: string) => {
  await client.post(`tournaments/${slug}/accept`).json()
}

export const postDismissTournament = async (slug: string) => {
  await client.post(`tournaments/${slug}/dismiss`).json()
}

const buildFormData = (
  formData: FormData,
  data: any,
  parentKey: string = ''
) => {
  const isDate = data instanceof Date
  const isNormalObject =
    data && typeof data === 'object' && !isDate && !(data instanceof File)
  const isBool = typeof data === 'boolean'

  if (isNormalObject) {
    Object.keys(data).forEach((key) =>
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      )
    )
  }

  if (isDate) {
    formData.append(parentKey, formatISO(data))
  }

  if (isBool) {
    formData.append(parentKey, data ? '1' : '0')
  }

  if (!isNormalObject && !isDate && !isBool) {
    formData.append(parentKey, data == null ? '' : data)
  }
}

export const createFormData = (data: any) => {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}

export const postTournament = async (
  {locationId, ...attributes}: TournamentProps,
  formikHelpers: FormikHelpers<TournamentProps>
) => {
  const tournamentPayload = await formClient
    .post(formikHelpers, 'tournaments', {
      body: createFormData({
        data: {
          type: 'tournaments',
          attributes,
          relationships: {
            location: {
              data: {
                type: 'locations',
                id: locationId,
              },
            },
          },
        },
      }),
    })
    .json()

  return TournamentModel.normalizePayload(tournamentPayload)
}

export const patchTournament = async (
  tournamentProps: TournamentProps,
  formikHelpers: FormikHelpers<TournamentProps>
) => {
  const tournamentPayload = await formClient
    .patch(formikHelpers, `tournaments/${tournamentProps.tournamentId}`, {
      json: {
        data: {
          id: tournamentProps.tournamentId,
          type: 'tournaments',
          attributes: {
            description: tournamentProps.description,
            starts: tournamentProps.starts,
            tournamentGames: tournamentProps.tournamentGames.map(
              (tournamentGame) => ({
                priceListId: tournamentGame.priceListId,
                buyIn: tournamentGame.buyIn,
              })
            ),
          },
        },
      },
    })
    .json()

  return TournamentModel.normalizePayload(tournamentPayload)
}

export const getExistsPredictionTournaments = async (
  name: string,
  locationSlug: string
) => {
  const tournamentsPayload = await client
    .get('tournaments', {
      searchParams: {
        'filter[search]': name,
        'filter[location][slug]': locationSlug,
      },
    })
    .json()

  return TournamentModel.normalizePayload(tournamentsPayload)
}
