import React, {MouseEvent, ReactChild} from 'react'

import {
  faAsterisk,
  faCalendar,
  faCheck,
  faClock,
  faEuroSign,
  faExclamation,
  faFilter,
  faHandHolding,
  faMapMarker,
  faPlus,
  faSpinner,
  faTimes,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

interface IconLayerProps {
  className?: string
  layerClassName?: string
}

export const IconHandHoldingEuro = ({
  className,
  layerClassName,
}: IconLayerProps) => (
  <div className={classNames('fa-layers fa-fw inline-block', className)}>
    <FontAwesomeIcon icon={faHandHolding} className="fa-fw" />
    <FontAwesomeIcon
      icon={faEuroSign}
      className={classNames('fa-fw', layerClassName)}
      transform="shrink-6 up-4"
    />
  </div>
)

interface IconLayerTrophyPlacementProps extends IconLayerProps {
  placement: number
}

export const IconTrophyPlacement = ({
  className = 'text-primary',
  layerClassName = 'text-dark dark:text-gray-light',
  placement,
}: IconLayerTrophyPlacementProps) => (
  <div
    className={classNames('d-inline-block fa-layers fa-fw relative', className)}
  >
    <FontAwesomeIcon icon={faTrophy} fixedWidth />
    <NumberSpan className={classNames('fa-layers-text', layerClassName)}>
      {placement}
    </NumberSpan>
  </div>
)

export const IconCalendarClock = ({
  className = '',
  layerClassName = 'text-primary',
}: IconLayerProps) => (
  <div className={classNames('d-inline-block fa-layers fa-fw', className)}>
    <FontAwesomeIcon icon={faCalendar} className="fa-fw" />
    <FontAwesomeIcon
      icon={faClock}
      className={classNames('fa-fw', layerClassName)}
      transform="shrink-9 down-3"
    />
  </div>
)

interface NumberSpanProps {
  className?: string
  children?: ReactChild
}

const NumberSpan = ({className, children}: NumberSpanProps) => (
  <span className={`absolute top-1.5 text-[0.625rem] ${className}`}>
    {children}
  </span>
)

export const RequiredIcon = () => (
  <FontAwesomeIcon
    className="text-xs text-gray-dark dark:text-gray-light"
    icon={faAsterisk}
  />
)
export const InvalidIcon = () => (
  <FontAwesomeIcon className="text-xs text-danger" icon={faExclamation} />
)
export const ValidIcon = () => (
  <FontAwesomeIcon className="text-xs text-primary" icon={faCheck} />
)
export const ErrorIcon = () => (
  <FontAwesomeIcon className="text-danger" icon={faTimes} />
)

interface CloseIconProps {
  className?: string
  disabled?: boolean
  onClick: (event: MouseEvent) => void
}

export const CloseIcon = ({
  className = '',
  disabled,
  onClick,
  ...props
}: CloseIconProps) => (
  <FontAwesomeIcon
    icon={faTimes}
    className={classNames(
      !disabled && 'cursor-pointer text-gray-light hover:text-primary',
      disabled && 'text-gray',
      className
    )}
    onClick={(e) => !disabled && onClick && onClick(e)}
    fixedWidth
    {...props}
  />
)

export const TrophyPlus = ({
  className = '',
  layerClassName = 'text-primary',
  ...props
}: IconLayerProps) => (
  <div
    className={classNames('d-inline-block fa-layers fa-fw', className)}
    {...props}
  >
    <FontAwesomeIcon icon={faTrophy} className="fa-fw" />
    <FontAwesomeIcon
      icon={faPlus}
      className={classNames('fa-fw', layerClassName)}
      transform="shrink-9 up-4"
    />
  </div>
)

export const CalendarFilter = ({
  className,
  layerClassName = 'text-primary',
  ...props
}: IconLayerProps) => (
  <div
    className={classNames('d-inline-block fa-layers fa-fw', className)}
    {...props}
  >
    <FontAwesomeIcon icon={faCalendar} className="fa-fw" />
    <FontAwesomeIcon
      icon={faFilter}
      className={classNames('fa-fw', layerClassName)}
      transform="shrink-9 down-3"
    />
  </div>
)

export const MapMarkerPlus = ({
  className = '',
  layerClassName = '',
  ...props
}: IconLayerProps) => (
  <div
    className={classNames('d-inline-block fa-layers fa-fw', className)}
    {...props}
  >
    <FontAwesomeIcon icon={faMapMarker} className="fa-fw" />
    <FontAwesomeIcon
      icon={faPlus}
      className={classNames('fa-fw text-primary', layerClassName)}
      transform="shrink-9 up-2"
    />
  </div>
)

interface TournamentActiveIconProps {
  isActive: boolean
}

export const TournamentActiveIcon = ({isActive}: TournamentActiveIconProps) => {
  if (isActive === null) {
    return (
      <FontAwesomeIcon icon={faSpinner} className="text-gray" spin fixedWidth />
    )
  }

  if (!isActive) {
    return <FontAwesomeIcon icon={faTimes} className="text-danger" fixedWidth />
  }

  return <FontAwesomeIcon icon={faCheck} className="text-primary" fixedWidth />
}
