import React from 'react'

import {Trans} from '@lingui/macro'

import {prefetchTournaments, useTournaments} from '@/hooks/query/useTournament'

import {Title} from '@/components/Title'
import TournamentsTable from '@/components/tabels/TournamentsTable'

const Tournaments = () => (
  <div>
    <Title>
      <Trans>Tournaments</Trans>
    </Title>

    <TournamentsTable
      useTableTournaments={useTournaments}
      prefetchTableTournaments={prefetchTournaments}
    />
  </div>
)

export default Tournaments
