import React from 'react'

import {Trans} from '@lingui/macro'

import {ExternalLink} from '@/lib/router'
import {GameModel} from '@/models/Game'

interface GameFlyerProps {
  game: GameModel
}

const GameFlyer = ({game}: GameFlyerProps) => {
  if (!game.image) {
    return null
  }

  return (
    <div>
      <h3 className="mb-5 text-center text-2xl text-primary">
        <Trans>Flyer</Trans>
      </h3>

      <ExternalLink to={game.image}>
        <img
          className="mx-auto block max-h-screen"
          src={game.image}
          alt={game.tournament.name}
        />
      </ExternalLink>
    </div>
  )
}

export default GameFlyer
