import {queryClient} from '@/lib/query'
import {PriceListModel} from '@/models/PriceList'
import {successCreatePriceListToast} from '@/toasts/price-list'

export const createPriceList = (priceList: PriceListModel) => {
  queryClient.setQueryData<any>(
    PriceListModel.KEY,
    (priceLists = {data: []}) => ({
      ...priceLists,
      data: [...priceLists.data, priceList.getAttributes()],
    })
  )
  successCreatePriceListToast(priceList)
}
