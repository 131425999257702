import React from 'react'

import {Trans} from '@lingui/macro'
import classNames from 'classnames'

import {MarkdownViewer} from '@/components/markdownEditor/MarkdownEditor'

import {TournamentModel} from '@/models/Tournament'

interface TournamentDescriptionProps {
  className?: string
  tournament: TournamentModel
}

const TournamentDescription = ({
  className,
  tournament,
}: TournamentDescriptionProps) => {
  if (!tournament.description) {
    return null
  }

  return (
    <div
      className={classNames('space-y-5 bg-white p-5 dark:bg-dark', className)}
    >
      <div className="text-center text-2xl text-primary">
        <Trans>Information</Trans>
      </div>

      <MarkdownViewer>{tournament.description}</MarkdownViewer>
    </div>
  )
}

export default TournamentDescription
