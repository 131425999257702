import {FormikHelpers} from 'formik/dist/types'

import {SearchParamsProps} from '@/components/Table'
import {ReportProps} from '@/components/forms/ReportGameModalForm'

import {client, formClient} from '@/lib/api-client'
import {ReportModel} from '@/models/Report'

export const getReports = async (searchParams: SearchParamsProps) => {
  const reportsPayload = await client
    .get('reports', {
      searchParams: {
        include: 'locations,games,creator',
        ...searchParams,
      },
    })
    .json()

  return ReportModel.normalizePayload(reportsPayload)
}

export const postReportGame = async (
  {id, ...attributes}: ReportProps,
  formikHelpers: FormikHelpers<ReportProps>
) => {
  const reportPayload = await formClient
    .post(formikHelpers, `games/${id}/report`, {
      json: {
        data: {
          id,
          type: 'games',
          attributes,
        },
      },
    })
    .json()

  return ReportModel.normalizePayload(reportPayload)
}

export const postReportLocation = async (
  {id, ...attributes}: ReportProps,
  formikHelpers: FormikHelpers<ReportProps>
) => {
  const reportPayload = await formClient
    .post(formikHelpers, `locations/${id}/report`, {
      json: {
        data: {
          id,
          type: 'locations',
          attributes,
        },
      },
    })
    .json()

  return ReportModel.normalizePayload(reportPayload)
}
