import {useQuery} from 'react-query'

import {getEmailNewsletters} from '@/api/email-newsletters'

import {SearchParamsProps} from '@/components/Table'

import {queryClient} from '@/lib/query'
import {
  EmailNewsletterModel,
  newsletterSearchParams,
} from '@/models/EmailNewsletter'

export const useEmailNewsletters = (searchParams: SearchParamsProps) =>
  useQuery(
    [EmailNewsletterModel.KEY, searchParams],
    () => getEmailNewsletters(searchParams),
    {
      select: (newslettersPayload) =>
        EmailNewsletterModel.fromNormalizedPayload(newslettersPayload),
      keepPreviousData: true,
    }
  )

export const prefetchEmailNewsletters = (
  searchParams: SearchParamsProps = newsletterSearchParams
) =>
  !queryClient.getQueryData([EmailNewsletterModel.KEY, searchParams]) &&
  queryClient.prefetchQuery([EmailNewsletterModel.KEY, searchParams], () =>
    getEmailNewsletters(searchParams)
  )
