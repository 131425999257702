import React from 'react'

import {faPaperPlane} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans} from '@lingui/macro'

import {
  patchEmailNewsletter,
  postEmailNewsletter,
} from '@/api/email-newsletters'

import EmailNewsletterForm from '@/components/forms/EmailNewsletterForm'

import {
  subscribeEmailNewsletter,
  updateEmailNewsletter,
} from '@/actions/email-newsletter'
import {useAuth} from '@/lib/auth'
import {EmailNewsletterModel} from '@/models/EmailNewsletter'

const EmailNewsletter = () => {
  const {user} = useAuth()

  return (
    <section className="py-16" id="newsletter">
      <div className="container">
        <div className="mx-auto text-center md:w-10/12 lg:w-8/12">
          <FontAwesomeIcon icon={faPaperPlane} className="fa-2x mb-4" />

          <h2 className="mb-10 text-xl md:text-3xl">
            <Trans>
              Sign up for the email newsletter to receive personalized
              tournament suggestions!
            </Trans>
          </h2>

          <EmailNewsletterForm
            user={user}
            onSubmit={async (values, formikHelpers) => {
              if (user?.emailNewsletter) {
                const emailNewsletterPayload = await patchEmailNewsletter(
                  values,
                  formikHelpers
                )

                updateEmailNewsletter(
                  user,
                  EmailNewsletterModel.fromNormalizedPayload(
                    emailNewsletterPayload
                  ).data
                )
              }

              if (!user?.emailNewsletter) {
                const emailNewsletterPayload = await postEmailNewsletter(
                  values,
                  formikHelpers
                )

                subscribeEmailNewsletter(
                  user,
                  EmailNewsletterModel.fromNormalizedPayload(
                    emailNewsletterPayload
                  ).data
                )
              }

              formikHelpers.setSubmitting(false)
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default EmailNewsletter
