import React from 'react'

import {FormikHelpers} from 'formik/dist/types'

import {SearchParamsProps} from '@/components/Table'
import {MobileNewsletterProps} from '@/components/forms/MobileNewsletterForm'

import {client, formClient} from '@/lib/api-client'
import {MobileNewsletterModel} from '@/models/MobileNewsletter'

export const deleteMobileNewsletter = async (id: string, token: string) => {
  await client.delete(`mobile-newsletters/${id}`, {
    searchParams: {
      token,
    },
  })
}

export const postMobileNewsletter = async (
  {leagueIds, setting, ...newsletterProps}: MobileNewsletterProps,
  formikHelpers: FormikHelpers<MobileNewsletterProps>
) => {
  const newsletterPayload = await formClient
    .post(formikHelpers, 'mobile-newsletters', {
      json: {
        data: {
          type: 'mobile-newsletters',
          attributes: {
            settings: setting,
            ...newsletterProps,
          },
          relationships: {
            leagues: {
              data: leagueIds.map((leagueId) => ({
                type: 'leagues',
                id: leagueId,
              })),
            },
          },
        },
      },
    })
    .json()

  return MobileNewsletterModel.normalizePayload(newsletterPayload)
}

export const patchMobileNewsletter = async (
  {id, leagueIds, setting, ...newsletterProps}: MobileNewsletterProps,
  formikHelpers: FormikHelpers<MobileNewsletterProps>
) => {
  const newsletterPayload = await formClient
    .patch(formikHelpers, `mobile-newsletters/${id}`, {
      json: {
        data: {
          id,
          type: 'mobile-newsletters',
          attributes: {
            settings: setting,
            ...newsletterProps,
          },
          relationships: {
            leagues: {
              data: leagueIds.map((leagueId) => ({
                type: 'leagues',
                id: leagueId,
              })),
            },
          },
        },
      },
    })
    .json()

  return MobileNewsletterModel.normalizePayload(newsletterPayload)
}

export const getMobileNewsletters = async (searchParams: SearchParamsProps) => {
  const newslettersPayload = await client
    .get('mobile-newsletters', {
      searchParams: {
        include: 'leagues,creator',
        ...searchParams,
      },
    })
    .json()

  return MobileNewsletterModel.normalizePayload(newslettersPayload)
}

export const getMobileNewsletter = async (token: string) => {
  const newsletterPayload = await client
    .get(`mobile-newsletters/${token}`, {
      searchParams: {
        include: 'setting',
      },
    })
    .json()

  return MobileNewsletterModel.normalizePayload(newsletterPayload)
}
