import React from 'react'

import classNames from 'classnames'
import {motion} from 'framer-motion'

interface CollapseProps {
  hiddenClassName?: string
  visibleClassName?: string
  className?: string
  open: boolean
  children: JSX.Element | JSX.Element[]
  onAnimationStart?: any
  onAnimationComplete?: any
}

const collapseVariants = {
  hidden: {
    height: 0,
  },
  visible: {
    height: 'auto',
  },
}

const Collapse = ({
  hiddenClassName,
  visibleClassName,
  className,
  open,
  ...props
}: CollapseProps) => (
  <motion.div
    className={classNames(
      'overflow-hidden',
      open ? visibleClassName : hiddenClassName,
      className
    )}
    initial={open ? 'visible' : 'hidden'}
    animate={open ? 'visible' : 'hidden'}
    variants={collapseVariants}
    {...props}
  />
)

export default Collapse
