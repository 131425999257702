import React, {useMemo, useState} from 'react'
import {UseQueryResult} from 'react-query'

import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans, t} from '@lingui/macro'

import {
  prefetchEmailNewsletters,
  useEmailNewsletters,
} from '@/hooks/query/useEmailNewsletter'

import {AlertDanger} from '@/components/Alert'
import DartLeagues from '@/components/DartLeagues'
import {flags} from '@/components/Footer'
import Table, {SearchParamsProps} from '@/components/Table'

import newsletter from '@/pages/guides/Newsletter'

import {ExternalLink} from '@/lib/router'
import {
  EmailNewsletterModel,
  newsletterSearchParams,
} from '@/models/EmailNewsletter'

interface EmailNewsletterProps {
  useTableNewsletters?: (searchParams: SearchParamsProps) => UseQueryResult<any>
  prefetchTableNewsletters?: (searchParams: SearchParamsProps) => void
}

const EmailNewsletterTable = ({
  useTableNewsletters = useEmailNewsletters,
  prefetchTableNewsletters = prefetchEmailNewsletters,
}: EmailNewsletterProps) => {
  const [searchParams, setSearchParams] = useState<SearchParamsProps>(
    newsletterSearchParams
  )
  const {data: newslettersData, ...newsletterQuery} =
    useTableNewsletters(searchParams)
  const newsletterColumns = useMemo(
    () => [
      {
        Header: t`Creator`,
        accessor: (newsletter: EmailNewsletterModel) =>
          newsletter.creator?.username || '-',
        sort: false,
      },
      {
        id: 'name',
        Header: t`Address`,
        accessor: (newsletter: EmailNewsletterModel) =>
          newsletter.radius === 0 ? (
            '-'
          ) : (
            <ExternalLink
              to={`https://maps.google.com?q=${newsletter.latitude},${newsletter.longitude}&z=5`}
            >
              {newsletter.name}
            </ExternalLink>
          ),
      },
      {
        id: 'email',
        Header: t`Email`,
        accessor: (newsletter: EmailNewsletterModel) => (
          <ExternalLink to={`mailto:${newsletter.email}`}>
            {newsletter.email}
          </ExternalLink>
        ),
      },
      {
        id: 'radius',
        Header: t`Radius`,
        accessor: (newsletter: EmailNewsletterModel) => {
          if (!newsletter.radius) {
            return <Trans>All</Trans>
          }

          return <Trans>{newsletter.radius / 1000} KM</Trans>
        },
      },
      {
        id: 'leagues',
        Header: t`Leagues`,
        accessor: (newsletter: EmailNewsletterModel) => (
          <DartLeagues leagues={newsletter.leagues} />
        ),
        sort: false,
      },
      {
        id: 'currentPosition',
        Header: t`Current position`,
        accessor: (newsletter: EmailNewsletterModel) => (
          <div className="text-center">
            <FontAwesomeIcon
              className={
                newsletter.currentPosition ? 'text-primary' : 'text-danger'
              }
              icon={newsletter.currentPosition ? faCheck : faTimes}
            />
          </div>
        ),
        show: false,
      },
      {
        id: 'locale',
        Header: t`Language`,
        accessor: (newsletter: EmailNewsletterModel) => (
          <img
            className="mx-auto h-3.5 w-auto"
            src={flags[newsletter.locale]}
            alt={newsletter.locale}
            width="28px"
            height="14px"
          />
        ),
        show: false,
      },
      {
        id: 'createdAt',
        Header: t`Added at`,
        accessor: (newsletter: EmailNewsletterModel) =>
          newsletter.formatCreatedAt(),
        show: false,
      },
    ],
    []
  )

  if (newsletterQuery.isError) {
    return <AlertDanger>{String(newsletterQuery.error)}</AlertDanger>
  }

  const {
    data: newsletters,
    meta: {page: pagination},
  } = newslettersData || {
    data: [],
    meta: {page: {}},
  }

  return (
    <Table
      placeholder={t`Email newsletters`}
      data={newsletters}
      columns={newsletterColumns}
      pagination={pagination}
      searchParams={searchParams}
      query={newsletterQuery}
      onChange={setSearchParams}
      onPrefetch={prefetchTableNewsletters}
    />
  )
}

export default EmailNewsletterTable
