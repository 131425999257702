import React, {ReactElement, useMemo} from 'react'

import {t} from '@lingui/macro'

import calendarFilter from '@/images/information/calendar-filter.webp'
import iPad from '@/images/information/i-pad.webp'
import {LocalizedLink} from '@/lib/router'

const Information = () => {
  const informationBlocks: InformationBlockProps[] = useMemo(
    () => [
      {
        to: '/tournaments/calendar',
        title: t`Visit our tournament calendar!`,
        content: t`In our tournament calendar you can view all registered tournaments and for example filter them according to your league and your favourite game mode!`,
        buttonText: t`To tournament calendar`,
        children: (
          <img
            className="mx-auto block"
            src={calendarFilter}
            alt={t`Tournament calendar filter`}
            title={t`Tournament calendar filter`}
            width="100%"
            height="auto"
          />
        ),
      },
      {
        to: '/auth/registration',
        title: t`Register now!`,
        content: t`By registering you can save calendar settings and specify an area from which tournaments should be displayed! You can also add your own tournaments to our calendar.`,
        buttonText: t`To register`,
        children: (
          <img
            className="mx-auto block"
            src={iPad}
            alt={t`Calendar filter in an iPad`}
            title={t`Calendar filter in an iPad`}
            width="100%"
            height="auto"
          />
        ),
      },
    ],
    []
  )

  return (
    <section className="bg-information pt-6 text-center">
      <div className="container space-y-16">
        {informationBlocks.map(({children, ...informationBlock}) => (
          <InformationBlock
            key={informationBlock.to}
            children={children}
            {...informationBlock}
          />
        ))}
      </div>
    </section>
  )
}

interface InformationBlockProps {
  title: string
  content: string
  to: string
  buttonText: string
  children: ReactElement
}

const InformationBlock = ({
  title,
  content,
  buttonText,
  to,
  children,
}: InformationBlockProps) => (
  <>
    <div className="mx-auto mb-10 w-full md:w-8/12">
      <h2 className="mb-8 text-xl text-white md:text-3xl">{title}</h2>

      <p className="mb-3 text-gray">{content}</p>

      <LocalizedLink to={to}>
        <button type="button" className="btn-sm btn-primary">
          {buttonText}
        </button>
      </LocalizedLink>
    </div>

    <div>{children}</div>
  </>
)

export default Information
