import {useQuery} from 'react-query'

import {
  getPriceLists,
  getSelectPriceLists,
  getUserPriceLists,
} from '@/api/price-lists'

import {SearchParamsProps} from '@/components/Table'

import {user} from '@/lib/auth'
import {queryClient} from '@/lib/query'
import {PriceListModel, priceListSearchParams} from '@/models/PriceList'

export const useSelectPriceLists = () =>
  useQuery(PriceListModel.SELECT_KEY, getSelectPriceLists, {
    select: (priceListPayload) =>
      PriceListModel.toSelectItems(priceListPayload),
  })

export const useUserPriceLists = (searchParams: SearchParamsProps) =>
  useQuery(
    [PriceListModel.USER_KEY, searchParams],
    () => getUserPriceLists(searchParams, user.id),
    {
      select: (priceListPayload) =>
        PriceListModel.fromNormalizedPayload(priceListPayload),
    }
  )

export const prefetchUserPriceLists = (
  searchParams: SearchParamsProps = priceListSearchParams,
  userId: string | undefined = user?.id
) =>
  !queryClient.getQueryData([PriceListModel.USER_KEY, searchParams]) &&
  queryClient.prefetchQuery([PriceListModel.USER_KEY, searchParams], () =>
    getUserPriceLists(searchParams, userId)
  )

export const usePriceLists = (searchParams: SearchParamsProps) =>
  useQuery(
    [PriceListModel.KEY, searchParams],
    () => getPriceLists(searchParams),
    {
      select: (priceListPayload) =>
        PriceListModel.fromNormalizedPayload(priceListPayload),
    }
  )

export const prefetchPriceLists = (
  searchParams: SearchParamsProps = priceListSearchParams
) =>
  !queryClient.getQueryData([PriceListModel.KEY, searchParams]) &&
  queryClient.prefetchQuery([PriceListModel.KEY, searchParams], () =>
    getPriceLists(searchParams)
  )
