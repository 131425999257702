import React from 'react'

import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans} from '@lingui/macro'

import {AlertDanger} from '@/components/Alert'

import {GameModel} from '@/models/Game'

interface GameStatusProps {
  game: GameModel
}

const GameStatus = ({game}: GameStatusProps) => {
  if (game.tournament.isActive) {
    return null
  }

  const content =
    game.tournament.isActive === false ? (
      <Trans>The tournament was dismissed!</Trans>
    ) : (
      <Trans>This tournament has not yet been reviewed!</Trans>
    )

  return (
    <AlertDanger>
      <div className="flex">
        <div className="w-auto">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>

        <div className="flex-grow">{content}</div>

        <div className="w-auto">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
      </div>
    </AlertDanger>
  )
}

export default GameStatus
