import React, {ReactChild} from 'react'
import {Outlet} from 'react-router-dom'

import Footer from '@/components/Footer'
import Navigation from '@/components/Navigation'

interface DefaultLayoutProps {
  children?: ReactChild
}

const DefaultLayout = ({children}: DefaultLayoutProps) => (
  <div>
    <Navigation />

    <div className="min-h-content">{children ? children : <Outlet />}</div>

    <Footer />
  </div>
)

interface CenteredLayoutMessageProps {
  children?: ReactChild
}

export const CenteredLayoutMessage = ({
  children,
}: CenteredLayoutMessageProps) => (
  <div className="flex min-h-content items-center justify-center text-6xl">
    <div className="text-2xl text-primary dark:text-white">{children}</div>
  </div>
)

export default DefaultLayout
