import React from 'react'
import {Outlet} from 'react-router-dom'

import {
  faEnvelope,
  faExclamationTriangle,
  faMapMarkerAlt,
  faMobile,
  faPaperPlane,
  faTachometerAlt,
  faToggleOn,
  faTrophy,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import {Trans} from '@lingui/macro'

import {prefetchEmailNewsletters} from '@/hooks/query/useEmailNewsletter'
import {prefetchLocations} from '@/hooks/query/useLocation'
import {prefetchMobileNewsletters} from '@/hooks/query/useMobileNewsletter'
import {prefetchPriceLists} from '@/hooks/query/usePriceList'
import {prefetchReports} from '@/hooks/query/useReport'
import {prefetchSentEmails} from '@/hooks/query/useSentEmail'
import {prefetchStat} from '@/hooks/query/useStat'
import {
  prefetchReviewTournaments,
  prefetchTournaments,
} from '@/hooks/query/useTournament'
import {prefetchUsers} from '@/hooks/query/useUser'

import Nav from '@/components/Nav'

import {RequireIsAdmin} from '@/lib/auth'

const AdminLayout = () => (
  <RequireIsAdmin>
    <Nav
      navItems={[
        {
          title: <Trans>Dashboard</Trans>,
          url: '/admin/dashboard',
          icon: faTachometerAlt,
          onMouseOver: async () => prefetchStat(),
        },
        {
          title: <Trans>Tournament reviews</Trans>,
          url: '/admin/tournaments/review',
          icon: faToggleOn,
          onMouseOver: async () => prefetchReviewTournaments(),
        },
        {
          title: <Trans>Reports</Trans>,
          url: '/admin/reports',
          icon: faExclamationTriangle,
          onMouseOver: async () => prefetchReports(),
        },
        {
          title: <Trans>Tournaments</Trans>,
          url: '/admin/tournaments',
          icon: faTrophy,
          onMouseOver: async () => prefetchTournaments(),
        },
        {
          title: <Trans>Tournament locations</Trans>,
          url: '/admin/locations',
          icon: faMapMarkerAlt,
          onMouseOver: async () => prefetchLocations(),
        },
        {
          title: <Trans>Price lists</Trans>,
          url: '/admin/price-lists',
          icon: faTrophy,
          onMouseOver: async () => prefetchPriceLists(),
        },
        {
          title: <Trans>Users</Trans>,
          url: '/admin/users',
          icon: faUsers,
          onMouseOver: async () => prefetchUsers(),
        },
        {
          title: <Trans>Email newsletters</Trans>,
          url: '/admin/newsletters/email',
          icon: faPaperPlane,
          onMouseOver: async () => await prefetchEmailNewsletters(),
        },
        {
          title: <Trans>Mobile newsletters</Trans>,
          url: '/admin/newsletters/mobile',
          icon: faMobile,
          onMouseOver: async () => await prefetchMobileNewsletters(),
        },
        {
          title: <Trans>Sent emails</Trans>,
          url: '/admin/emails/sent',
          icon: faEnvelope,
          onMouseOver: async () => await prefetchSentEmails(),
        },
      ]}
      children={<Outlet />}
    />
  </RequireIsAdmin>
)

export default AdminLayout
