import React, {MouseEvent, ReactChild, useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {faBars} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import {useUpdateEffect} from 'react-use'

import {
  useDefaultNavigationItems,
  useUserNavigationItems,
} from '@/hooks/useNavigationItems'

import Collapse from '@/components/Collapse'
import Logo from '@/components/Logo'

import {LocalizedLink, useLocalizedLocation} from '@/lib/router'

export interface NavigationItemProp {
  to?: string
  children?: ReactChild | ReactChild[] | string
  className?: string

  onActive?(pathname: string): boolean
  onClick?(event: MouseEvent): void
  onMouseOver?(event: MouseEvent): void
}

const Navigation = () => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const toggleNav = () => setOpen(!open)
  const hideNav = () => setOpen(false)
  const defaultNavigationItems = useDefaultNavigationItems()
  const userNavigationItems = useUserNavigationItems()
  const mobileNavigationItems = useMemo(
    () => [...defaultNavigationItems, ...userNavigationItems],
    [defaultNavigationItems, userNavigationItems]
  )

  useUpdateEffect(() => {
    hideNav()
  }, [location])

  return (
    <nav className="bg-white p-6 text-dark shadow-lg dark:bg-dark dark:text-white">
      <div className="container flex items-center justify-between">
        <div className="flex items-center space-x-5">
          <LocalizedLink to="" onClick={hideNav}>
            <Logo size={60} />
          </LocalizedLink>

          <NavigationMenu items={defaultNavigationItems} />
        </div>

        <div className="justify-end">
          <NavigationMenu items={userNavigationItems} />
        </div>

        <button
          className="p-1 text-4xl hover:text-primary focus:outline-none lg:hidden"
          onClick={toggleNav}
          aria-label="Bars"
        >
          <FontAwesomeIcon className="fa-fw" icon={faBars} />
        </button>
      </div>

      <MobileNavbarMenu open={open} items={mobileNavigationItems} />
    </nav>
  )
}

interface MobileNavbarMenuProps extends NavbarItemsProps {
  open: boolean
}

const MobileNavbarMenu = ({open, items}: MobileNavbarMenuProps) => (
  <div className="container lg:hidden">
    <Collapse open={open}>
      <div className="mt-6 space-y-4">
        {items.map((navbarItem, index) => (
          <NavbarItem key={index} {...navbarItem} />
        ))}
      </div>
    </Collapse>
  </div>
)

interface NavbarItemsProps {
  items: NavigationItemProp[]
}

const NavigationMenu = ({items}: NavbarItemsProps) => (
  <div className="container hidden space-x-3 lg:block">
    {items.map((navbarItem, index) => (
      <NavbarItem key={index} {...navbarItem} />
    ))}
  </div>
)

const NavbarItem = ({
  to,
  className = '',
  children,
  onClick,
  onActive,
  onMouseOver,
}: NavigationItemProp) => {
  const {pathname} = useLocalizedLocation()
  const isActive = onActive ? onActive(pathname) : pathname === to

  if (!to) {
    return (
      <span
        className={classNames(
          'block cursor-pointer capitalize lg:inline-block',
          className,
          isActive && 'text-primary',
          !isActive &&
            'text-dark hover:text-primary dark:text-white dark:hover:text-primary'
        )}
        children={children}
        onClick={onClick}
        onMouseOver={onMouseOver}
      />
    )
  }

  return (
    <LocalizedLink
      to={to}
      className={classNames(
        'block cursor-pointer capitalize lg:inline-block',
        className,
        isActive && 'text-primary',
        !isActive &&
          'text-dark hover:text-primary dark:text-white dark:hover:text-primary'
      )}
      children={children}
      onClick={onClick}
      onMouseOver={onMouseOver}
    />
  )
}

export default Navigation
