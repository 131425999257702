import React from 'react'

import {Trans} from '@lingui/macro'

import {useSelectLeagues} from '@/hooks/query/useLeague'

import Tooltip from '@/components/Tooltip'

import {LeagueModel} from '@/models/League'

interface DartLeaguesProps {
  leagues: LeagueModel[]
}

const DartLeagues = ({leagues}: DartLeaguesProps) => {
  const {data: allLeagues, isLoading} = useSelectLeagues()
  const leaguesTooltip = leagues.map((league) => league.name).join(', ')

  if (leagues.length === 1) {
    return <Tooltip tooltip={leaguesTooltip}>{leagues[0].shortcut}</Tooltip>
  }

  if (
    !isLoading &&
    (leagues.length === allLeagues.length || leagues.length === 0)
  ) {
    return (
      <Tooltip
        tooltip={<Trans>All hobby and league players may participate</Trans>}
      >
        <Trans>Open</Trans>
      </Tooltip>
    )
  }

  const leagueIdCount = leagues.reduce(
    (previousValue, currentValue) => previousValue + Number(currentValue.id),
    0
  )
  const firstId = Number(leagues[0].id)
  const condition = Array.from(
    {length: Number(leagues.length)},
    (_, i) => firstId + i
  )
  const conditionCount = condition.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  )
  const isRange = leagueIdCount === conditionCount
  const highestLeague = leagues[leagues.length - 1]

  if (isRange) {
    return (
      <Tooltip tooltip={leaguesTooltip}>
        {`${leagues[0].shortcut} - ${highestLeague.shortcut}`}
      </Tooltip>
    )
  }

  return (
    <Tooltip tooltip={leaguesTooltip}>
      {leagues.map((league) => league.shortcut).join(',')}
    </Tooltip>
  )
}

export default DartLeagues
