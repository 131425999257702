import React, {useMemo, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans, t} from '@lingui/macro'
import classNames from 'classnames'
import {
  addMonths,
  endOfMonth,
  formatISO,
  isBefore,
  isSameMonth,
  startOfMonth,
} from 'date-fns'
import {useFormikContext} from 'formik'

import {prefetchEventGames, useEventGames} from '@/hooks/query/useGame'

import Calendar, {
  CALENDAR_NAVIGATE_NEXT,
  CALENDAR_NAVIGATE_PREV,
  CALENDAR_NAVIGATE_TODAY,
  EventProps,
  ToolbarProps,
} from '@/components/Calendar'
import Collapse from '@/components/Collapse'
import DartLeagues from '@/components/DartLeagues'
import DartsSetting from '@/components/DartsSetting'
import {FieldGroupRadiusAddressPosition} from '@/components/fields/FieldAddressAutocomplete'
import FieldGroupSetting from '@/components/fields/FieldGroupSetting'
import FieldLeaguesSelect from '@/components/fields/FieldLeaguesSelect'
import FieldSubmitButton from '@/components/fields/FieldSubmitButton'

import {useAuth} from '@/lib/auth'
import {intlFormatTime} from '@/lib/date'
import Form from '@/lib/form'
import {LocalizedLink} from '@/lib/router'
import Yup from '@/lib/yup'
import {CalendarFilterModel} from '@/models/CalendarFilter'
import {GameModel} from '@/models/Game'
import {MultiSettingInterface} from '@/models/Setting'

const GameCalendarToolbar = ({label, onNavigate, viewDate}: ToolbarProps) => {
  const {user} = useAuth()
  const {submitForm, values, setFieldValue, initialValues, setValues} =
    useFormikContext<GameEventProps>()

  return (
    <div className="mb-5">
      <div className="flex flex-wrap items-center justify-between md:flex-nowrap">
        <div className="order-last mb-5 flex w-full justify-center space-x-3 md:order-first md:w-auto">
          <div className="group w-auto">
            <button
              type="button"
              className="btn-sm btn-primary"
              disabled={!values.review && isBefore(viewDate, new Date())}
              onClick={() => onNavigate(CALENDAR_NAVIGATE_PREV)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>

            <button
              type="button"
              className="btn-sm btn-primary"
              onClick={() => onNavigate(CALENDAR_NAVIGATE_NEXT)}
              onMouseOver={async () => {
                const nextMonth = addMonths(new Date(values.start), 1)
                await prefetchEventGames({
                  ...values,
                  start: formatISO(startOfMonth(nextMonth)),
                  end: formatISO(endOfMonth(nextMonth)),
                })
              }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>

          <button
            type="button"
            className={classNames(
              'btn-sm btn-primary',
              isSameMonth(viewDate, new Date()) && 'active'
            )}
            onClick={() => onNavigate(CALENDAR_NAVIGATE_TODAY)}
          >
            <Trans>Today</Trans>
          </button>
        </div>

        <div className="order-first mb-3 w-full text-center text-4xl text-primary md:order-1">
          {label}
        </div>

        <div className="order-last mb-3 w-full space-x-3 whitespace-nowrap text-center md:w-auto">
          <button
            type="button"
            className={classNames(
              'btn-sm btn-primary',
              values.active && 'active'
            )}
            onClick={() => setFieldValue('active', !values.active)}
          >
            <Trans>Filter</Trans>
          </button>

          <button
            type="button"
            className={classNames(
              'btn-sm btn-primary',
              values.review && 'active'
            )}
            onClick={() => setFieldValue('review', !values.review)}
          >
            <Trans>Review</Trans>
          </button>
        </div>
      </div>

      <Collapse visibleClassName="p-1" open={values.active}>
        <FieldGroupSetting namePrefix="setting." multi />

        <FieldGroupRadiusAddressPosition
          onChange={({name, position}) => {
            setFieldValue('name', name)
            setFieldValue('latitude', position.lat())
            setFieldValue('longitude', position.lng())
          }}
          onlyAddress
        />

        <FieldLeaguesSelect />

        <div className="space-y-3 text-right md:space-y-0 md:space-x-5">
          <button
            type="button"
            className="btn-sm btn-danger w-full md:w-auto"
            onClick={async () => {
              setValues({
                ...createGameCalendarInitValues(new Date(), values.locationId),
                active: true,
                start: values.start,
                end: values.end,
              })
              await submitForm()
            }}
          >
            <Trans>Remove tournament filter</Trans>
          </button>

          {!!user?.calendarFilter && (
            <button
              type="button"
              className="btn-sm btn-gray w-full md:w-auto"
              onClick={() => {
                setValues({
                  ...initialValues,
                  start: values.start,
                  end: values.end,
                })
                setTimeout(async () => {
                  await submitForm()
                }, 10)
              }}
            >
              <Trans>Reset tournament filter</Trans>
            </button>
          )}

          <FieldSubmitButton className="w-full md:w-auto" small>
            <Trans>Update tournament filter</Trans>
          </FieldSubmitButton>
        </div>
      </Collapse>
    </div>
  )
}

interface GameCalendarAgendaEventProps extends EventProps {
  game: GameModel
}

const GameCalendarAgendaEvent = ({
  title,
  game,
}: GameCalendarAgendaEventProps) => (
  <LocalizedLink to={game.link()} noStyle>
    <div className="flex cursor-pointer flex-wrap p-3 hover:bg-primary hover:bg-opacity-50">
      <div className="flex space-x-2">
        <div>{intlFormatTime(new Date(game.start))}</div>

        <div>
          <hr
            className={classNames(
              'inline-block w-0 rounded-full border-4',
              game.canceled ? 'border-danger' : 'border-primary'
            )}
          />
        </div>

        <div>
          <DartsSetting setting={game.setting} />
        </div>

        <div className="md:hidden">
          <DartLeagues leagues={game.leagues} />
        </div>
      </div>

      <div className="md:ml-2">{title}</div>

      <div className="ml-auto hidden md:block">
        <DartLeagues leagues={game.leagues} />
      </div>
    </div>
  </LocalizedLink>
)

const NoEvents = ({viewDate, onNavigate}) => {
  const {user} = useAuth()
  const {values} = useFormikContext()

  if (
    JSON.stringify(values) ===
      JSON.stringify(
        createGameCalendarInitValues(
          new Date(),
          undefined,
          user?.calendarFilter
        )
      ) &&
    isSameMonth(viewDate, new Date())
  ) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="max-w-lg space-y-10 px-5 text-center text-xl">
          <div>
            <Trans>
              The current month is coming to an end, so it seems that there are
              no more tournament games taking place
            </Trans>
          </div>

          <button
            className="btn-sm btn-primary"
            onClick={() => onNavigate(CALENDAR_NAVIGATE_NEXT)}
          >
            <Trans>To the next month</Trans>
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="flex h-full items-center justify-center">
      <div className="text-xl">
        <Trans>No tournament games found</Trans>
      </div>
    </div>
  )
}

export interface GameEventProps {
  locationId: string | null
  latitude: number | null
  longitude: number | null
  radius: number | null
  setting: MultiSettingInterface
  active: boolean
  leagueIds: string[]
  currentPosition: boolean
  name: string
  start: string
  end: string
  review: boolean
}

export const createGameCalendarInitValues = (
  date = new Date(),
  locationId?: string,
  calendar?: CalendarFilterModel
): GameEventProps => ({
  locationId: locationId || null,
  latitude: null,
  longitude: null,
  radius: null,
  setting: {
    variant: [],
    value: [],
    size: [],
    out: [],
    mixed: false,
    dyp: false,
    doubleIn: false,
  },
  active: false,
  leagueIds: [],
  currentPosition: false,
  ...(calendar?.toInitValues() || {}),
  name: calendar?.name || '',
  start: formatISO(date),
  end: formatISO(endOfMonth(date)),
  review: false,
})

interface GameCalendarProps {
  locationId?: string
}

export const getGameUrlParams = (params: URLSearchParams): GameEventProps => ({
  active: params.get('active') === 'true',
  name: params.get('name'),
  radius: params.get('radius') ? parseInt(params.get('radius') || '') : null,
  latitude:
    params.get('latitude') === 'undefined' || params.get('latitude') === null
      ? null
      : parseFloat(params.get('latitude') || ''),
  longitude:
    params.get('longitude') === 'undefined' || params.get('longitude') === null
      ? null
      : parseFloat(params.get('longitude') || ''),
  currentPosition: params.get('current_position') === 'true',
  locationId: null,
  setting: {
    variant: [],
    value: [],
    size: [],
    out: [],
    mixed: false,
    dyp: false,
    doubleIn: false,
  },
  leagueIds: [],
  start: formatISO(startOfMonth(new Date())),
  end: formatISO(endOfMonth(new Date())),
  review: false,
})

const GameCalendar = ({locationId}: GameCalendarProps) => {
  const {user} = useAuth()
  const {search} = useLocation()
  const query = new URLSearchParams(search)
  const defaultDate = query.has('date')
    ? new Date(query.get('date'))
    : new Date()
  const [eventParams, setEventParams] = useState(
    query.has('active')
      ? getGameUrlParams(query)
      : createGameCalendarInitValues(
          defaultDate,
          locationId,
          user?.calendarFilter
        )
  )
  const {data: games, isLoading, error} = useEventGames(eventParams)
  const events = useMemo(
    () =>
      (games || []).map((game) => ({
        title: getTitle(
          game,
          eventParams.latitude,
          eventParams.longitude,
          eventParams.radius
        ),
        start: new Date(game.start),
        game,
      })),
    [games, eventParams.latitude, eventParams.longitude, eventParams.radius]
  )

  const tournamentCalendarFilterSchema = Yup.object().shape({
    setting: Yup.multiSetting(),
    name: Yup.string()
      .when('radius', {
        is: (radius: number) => !radius,
        then: Yup.string().nullable(),
        otherwise: Yup.string().nullable().required(),
      })
      .label(t`Address`),
    radius: Yup.radius().nullable(),
    latitude: Yup.latitude().when('radius', {
      is: (radius: number) => !radius,
      then: Yup.number().nullable(),
      otherwise: Yup.number().required(),
    }),
    longitude: Yup.longitude().when('radius', {
      is: (radius: number) => !radius,
      then: Yup.number().nullable(),
      otherwise: Yup.number().required(),
    }),
    leagueIds: Yup.array()
      .of(Yup.string())
      .label(t`Leagues`),
    currentPosition: Yup.bool()
      .required()
      .label(t`Current position`),
  })

  return (
    <Form
      initialValues={eventParams}
      validationSchema={tournamentCalendarFilterSchema}
      onSubmit={(values, {setSubmitting}) => {
        setEventParams({
          ...eventParams,
          ...values,
        })
        setSubmitting(false)
      }}
    >
      {({values}) => (
        <Calendar
          events={events}
          defaultDate={defaultDate}
          components={{
            toolbar: GameCalendarToolbar,
            agendaMonth: GameCalendarAgendaEvent,
            noEvents: NoEvents,
          }}
          onChange={(start, end) => {
            const realStart = values.review ? start : new Date()

            setEventParams({
              ...eventParams,
              start: formatISO(realStart),
              end: formatISO(end),
            })
          }}
          loading={isLoading}
          error={error?.message}
          reloadDep={[values.review]}
        />
      )}
    </Form>
  )
}

const getTitle = (
  game: GameModel,
  latitude?: number,
  longitude?: number,
  radius?: number
) => {
  if (latitude !== null && longitude !== null && radius !== null) {
    const radiusInKM = radius / 1000
    const distance = Math.round(
      getDistanceInKm(
        game.tournament.location.latitude,
        game.tournament.location.longitude,
        latitude,
        longitude
      )
    )

    return t`„${game.tournament.name}“ ${
      distance > radiusInKM && radiusInKM !== 0 ? radiusInKM : distance
    } KM away`
  }

  return t`„${game.tournament.name}“ in ${game.tournament.location.city}`
}

const getDistanceInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const R = 6371
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

const deg2rad = (deg: number) => deg * (Math.PI / 180)

export default GameCalendar
