import {t} from '@lingui/macro'
import {toast} from 'react-hot-toast'

import {CalendarFilterModel} from '@/models/CalendarFilter'
import {TournamentModel} from '@/models/Tournament'

export const successCreateCalendarFilterToast = (
  calendarFilter: CalendarFilterModel
) => toast.success(t`The calendar filters were created`)
export const successUpdateCalendarFilterToast = (
  calendarFilter: CalendarFilterModel
) => toast.success(t`The calendar filter changes have been applied`)

export const successCreateTournamentToast = (tournament: TournamentModel) =>
  toast.success(t`The tournament „${tournament.name}“ has been created`)
export const successUpdateTournamentToast = (tournament: TournamentModel) =>
  toast.success(t`The tournament „${tournament.name}“ has been updated`)

export const successActivateTournamentToast = (tournament: TournamentModel) =>
  toast.success(t`The tournament „${tournament.name}“ has been activated`)
export const successCanceledTournamentToast = (tournament: TournamentModel) =>
  toast.success(t`The tournament „${tournament.name}“ has been canceled`)

export const successAcceptTournamentToast = (tournament: TournamentModel) =>
  toast.success(t`The tournament „${tournament.name}“ has been accepted`)
export const successDismissTournamentToast = (tournament: TournamentModel) =>
  toast.success(t`The tournament „${tournament.name}“ has been dismissed`)
