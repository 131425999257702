import {queryClient} from '@/lib/query'
import {LocationModel} from '@/models/Location'
import {successCreateLocationToast} from '@/toasts/location'

export const createLocation = (location: LocationModel) => {
  queryClient.setQueryData<any>(LocationModel.SELECT_KEY, (locations) => ({
    ...locations,
    data: [...locations.data, location.getAttributes()],
  }))
  successCreateLocationToast(location)
}
