import {i18n} from '@lingui/core'
import {
  addDays,
  format,
  formatDistanceToNow,
  intlFormat,
  startOfWeek,
} from 'date-fns'
import {de as dateDe, enUS as dateEn} from 'date-fns/locale'

import {ENGLISH, GERMAN} from '@/lib/i18n'

const getDateLocale = () => {
  const locale = i18n.locale

  if (locale === GERMAN) {
    return dateDe
  }

  if (locale === ENGLISH) {
    return dateEn
  }
}

export const getShortWeekDays = () => {
  const firstDOW = startOfWeek(new Date(), {
    locale: getDateLocale(),
  })

  return Array.from(Array(7)).map((e, i) =>
    format(addDays(firstDOW, i), 'EEEEEE', {
      locale: getDateLocale(),
    })
  )
}

export const intlFormatDateTime = (date: Date) =>
  intlFormat(
    date,
    {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',

      minute: '2-digit',
      hour: '2-digit',
    },
    {
      locale: i18n.locale,
    }
  )

export const intlFormatTime = (date: Date) =>
  intlFormat(
    date,
    {
      minute: '2-digit',
      hour: '2-digit',
    },
    {
      locale: i18n.locale,
    }
  )

export const intlFormatDate = (date: Date) =>
  intlFormat(
    date,
    {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
    {
      locale: i18n.locale,
    }
  )

export const intlFormatDistanceToNow = (date: Date) =>
  formatDistanceToNow(date, {
    addSuffix: true,
    locale: getDateLocale(),
  })
