import React, {ReactChild} from 'react'

import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

interface InputLabelProps {
  icon?: IconProp
  iconChild?: ReactChild
  itemProp?: string
  children?: ReactChild | ReactChild[] | string
}

const InputLabel = ({children, icon, iconChild, itemProp}: InputLabelProps) => (
  <div className="group h-full">
    <button type="button" className="btn btn-primary active" disabled>
      {!!icon && <FontAwesomeIcon icon={icon} fixedWidth />}
      {!!iconChild && iconChild}
    </button>

    <div
      itemProp={itemProp}
      className="input flex flex-col justify-center overflow-x-auto whitespace-nowrap p-5"
    >
      {children}
    </div>
  </div>
)

export default InputLabel
