import React from 'react'

import {Trans} from '@lingui/macro'

import {useLocation} from '@/hooks/query/useLocation'

import {LayoutLoader} from '@/components/Loader'
import GameCalendar from '@/components/games/GameCalendar'
import {CenteredLayoutMessage} from '@/components/layouts/DefaultLayout'
import LocationInformation from '@/components/locations/LocationInformation'

import {useSlug} from '@/lib/router'

const Location = () => {
  const slug = useSlug()
  const {data: location, isLoading, isError} = useLocation(slug)

  if (isLoading) {
    return <LayoutLoader />
  }

  if (isError) {
    return (
      <CenteredLayoutMessage>
        <Trans>500 - Internal server error</Trans>
      </CenteredLayoutMessage>
    )
  }

  return (
    <div className="container space-y-10 py-10">
      <LocationInformation location={location} />

      <GameCalendar locationId={location.dbId} />
    </div>
  )
}

export default Location
