import React from 'react'

import {Trans} from '@lingui/macro'
import {Formik} from 'formik'

import DartsSetting from '@/components/DartsSetting'
import {Title} from '@/components/Title'
import FieldGroupSetting from '@/components/fields/FieldGroupSetting'

import {SettingInterface} from '@/models/Setting'

const gameModesInitialValues: SettingInterface = {
  variant: 'sd',
  size: 'e',
  value: '501',
  out: 'do',
  mixed: false,
  dyp: false,
  doubleIn: false,
}

const GameModes = () => (
  <div>
    <Title>
      <Trans>Guide game modes</Trans>
    </Title>

    <div className="mb-5">
      <Trans>
        Here you can test how our compact game modes shortcut is made up. You
        can only select the options „Mixed“ and „DYP“ when more than one player
        is selected.
      </Trans>
    </div>

    <Formik initialValues={gameModesInitialValues} onSubmit={() => null}>
      {({values}) => (
        <div className="mb-5">
          <FieldGroupSetting />

          <div className="bg-primary-lighten mx-auto flex h-32 w-full items-center justify-center rounded md:w-1/2">
            <DartsSetting className="text-3xl" setting={values} noTooltips />
          </div>
        </div>
      )}
    </Formik>
  </div>
)

export default GameModes
