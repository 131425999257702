import {SearchParamsProps} from '@/components/Table'

import {Model, ModelInterface} from '@/lib/model'
import {GameModel} from '@/models/Game'
import {LocationModel} from '@/models/Location'
import {UserModel} from '@/models/User'

export interface ReportInterface extends ModelInterface {
  reason: string
  amendment?: string
}

export class ReportModel extends Model<ReportInterface> {
  reason!: string
  amendment!: string

  public static KEY = 'reports'

  get games(): GameModel[] | null {
    return this.manyRelationship(GameModel, 'games')
  }

  get locations(): LocationModel[] | null {
    return this.manyRelationship(LocationModel, 'locations')
  }

  get creator(): UserModel {
    return this.singleRelationship(UserModel, 'creator')
  }
}

export const reportSearchParams: SearchParamsProps = {
  'filter[search]': '',
  'page[size]': 25,
  'page[number]': 0,
  sort: '-createdAt',
}
