import React, {ReactChild, useEffect, useRef} from 'react'

import {FieldMetaProps, Formik} from 'formik'
import {FormikConfig, FormikHelpers, FormikValues} from 'formik/dist/types'

export interface FieldInterface {
  name: string
  placeholder?: string
}

export interface FieldGroupInterface {
  namePrefix?: string
}

export interface CustomFormInterface<Values> {
  onSubmit: (
    values: Values,
    formikHelpers: FormikHelpers<Values>
  ) => void | Promise<any>
}

const Form = <Values extends FormikValues = FormikValues, ExtraProps = {}>({
  children,
  ...props
}: FormikConfig<Values> & ExtraProps): JSX.Element => (
  <Formik {...props}>
    {(props: any) => {
      const containerRef = useRef<HTMLDivElement>(null)

      useEffect(() => {
        const inputs =
          containerRef.current?.querySelectorAll(
            "input:not([aria-haspopup='menu'])"
          ) || []
        const onKeydown = ({key}: any) => key === 'Enter' && props.submitForm()

        inputs.forEach((input) => input.addEventListener('keydown', onKeydown))

        return () =>
          inputs.forEach((input) =>
            input.removeEventListener('keydown', onKeydown)
          )
      }, [])

      return (
        <div ref={containerRef}>
          {typeof children === 'function' ? children(props) : children}
        </div>
      )
    }}
  </Formik>
)

export interface FormControlProps<Value = any> {
  className?: string
  meta?: FieldMetaProps<any>
  children: ReactChild | ReactChild[]
}

export const FormControl = ({
  className,
  meta = {
    value: undefined,
    touched: false,
    initialTouched: false,
  },
  children,
}: FormControlProps) => (
  <div className={`w-full ${className}`}>
    {children}

    <ErrorMessage {...meta} />
  </div>
)

interface ErrorMessageProps {
  error?: string[] | string
  touched: boolean
  initialError?: string
}

export const ErrorMessage = ({
  error,
  touched,
  initialError,
}: ErrorMessageProps) => {
  const errorMessage = touched
    ? Array.isArray(error)
      ? error[0]
      : error
    : initialError

  return (
    <small className="my-1 block min-h-[1.25rem] w-full text-left text-sm text-danger">
      {errorMessage}
    </small>
  )
}

export default Form
