import React, {ReactElement, useRef} from 'react'

import classNames from 'classnames'
import {useField, useFormikContext} from 'formik'

interface FieldButtonCheckbox {
  type: 'checkbox' | 'radio'
  name: string
  value?: string
  children: ReactElement | string
  disabled?: boolean
  asBoolean?: boolean
}

const FieldCheckboxButton = ({
  name,
  value,
  children,
  disabled,
  asBoolean,
  ...props
}: FieldButtonCheckbox) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const {isSubmitting} = useFormikContext()
  const [field, meta] = useField(name)
  const error = meta.touched && meta.error
  const isActive = asBoolean ? field.value : field.value?.includes(value)

  return (
    <button
      type="button"
      className={classNames(
        'whitespace-no-wrap btn-sm btn-primary w-full rounded-none capitalize',
        error && 'border-danger',
        isActive && 'active'
      )}
      onClick={() => inputRef.current?.click()}
      disabled={disabled || isSubmitting}
    >
      <input
        ref={inputRef}
        className="hidden"
        {...field}
        {...props}
        value={asBoolean ? '1' : value || ''}
        checked={isActive || false}
      />{' '}
      {children}
    </button>
  )
}

export default FieldCheckboxButton
