import {SearchParamsProps} from '@/components/Table'
import {EmailNewsletterProps} from '@/components/forms/EmailNewsletterForm'

import {Model, ModelInterface} from '@/lib/model'
import {LeagueModel} from '@/models/League'
import {MultiSettingInterface, SettingModel} from '@/models/Setting'
import {UserModel} from '@/models/User'

export interface NewsletterInterface extends ModelInterface {
  radius: number
  name: string
  currentPosition: boolean
  latitude: number | null
  longitude: number | null
  leagueIds: string[]
  locale: string
  setting: MultiSettingInterface
}

export interface EmailNewsletterInterface extends NewsletterInterface {
  email: string
  token: string
}

export class EmailNewsletterModel extends Model<EmailNewsletterInterface> {
  radius!: number
  name!: string
  email!: string
  currentPosition!: boolean
  latitude!: number | null
  longitude!: number | null
  token!: string
  locale!: string

  public static KEY = 'email-newsletters'

  get setting(): SettingModel {
    return this.singleRelationship(SettingModel, 'setting')
  }

  get leagues(): LeagueModel[] {
    return this.manyRelationship(LeagueModel, 'leagues')
  }

  get leagueIds(): string[] {
    return this.leagues.map((league) => league.id)
  }

  get creator(): UserModel | null {
    return this.singleRelationship(UserModel, 'creator')
  }

  public toInitValues(): EmailNewsletterProps {
    return {
      ...this.only([
        'id',
        'radius',
        'email',
        'latitude',
        'longitude',
        'currentPosition',
      ]),
      name: this.name || '',
      setting: this.setting.getAttributes(),
      leagueIds: this.leagueIds,
    }
  }
}

export const newsletterSearchParams: SearchParamsProps = {
  'filter[search]': '',
  'page[size]': 25,
  'page[number]': 0,
  sort: '-createdAt',
}
