import React, {useState} from 'react'

import {PopperContainer} from '@/lib/popper'

interface TooltipProps {
  tooltip: JSX.Element | string
  children: JSX.Element | string
}

const Tooltip = ({tooltip, children}: TooltipProps) => {
  const [show, setShow] = useState(false)

  return (
    <PopperContainer
      hidden={!show}
      className="px-2 py-1"
      refChildren={({setRefElement}) => (
        <span
          ref={setRefElement}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          {children}
        </span>
      )}
      placement="top"
      noSameWidth
      arrow
    >
      <div className="rounded border border-gray-light bg-white px-1.5  dark:bg-dark">
        <small>{tooltip}</small>
      </div>
    </PopperContainer>
  )
}

export default Tooltip
