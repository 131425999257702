import React from 'react'

import {Trans} from '@lingui/macro'

import {
  prefetchMobileNewsletters,
  useMobileNewsletters,
} from '@/hooks/query/useMobileNewsletter'

import {Title} from '@/components/Title'
import NewslettersTable from '@/components/tabels/MobileNewslettersTable'

const MobileNewsletters = () => (
  <div>
    <Title>
      <Trans>Mobile newsletters</Trans>
    </Title>

    <NewslettersTable
      useTableNewsletters={useMobileNewsletters}
      prefetchTableNewsletters={prefetchMobileNewsletters}
    />
  </div>
)

export default MobileNewsletters
