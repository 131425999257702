import React, {useMemo, useState} from 'react'
import {UseQueryResult} from 'react-query'

import {t} from '@lingui/macro'

import {prefetchReports, useReports} from '@/hooks/query/useReport'

import {AlertDanger} from '@/components/Alert'
import Table, {SearchParamsProps} from '@/components/Table'

import {ReportModel, reportSearchParams} from '@/models/Report'

interface ReportsTableProps {
  useTableReports?: (searchParams: SearchParamsProps) => UseQueryResult<any>
  prefetchTableReports?: (searchParams: SearchParamsProps) => void
}

const ReportsTable = ({
  useTableReports = useReports,
  prefetchTableReports = prefetchReports,
}: ReportsTableProps) => {
  const [searchParams, setSearchParams] =
    useState<SearchParamsProps>(reportSearchParams)
  const {data: reportsData, ...reportQuery} = useTableReports(searchParams)

  const reportColumns = useMemo(
    () => [
      {
        Header: t`Reason`,
        accessor: 'reason',
      },
      {
        Header: t`Username`,
        accessor: 'creator.username',
        sort: false,
      },
      {
        id: 'amendment',
        Header: t`Amendment`,
        accessor: (report: ReportModel) => report.amendment || '-',
      },
      {
        id: 'createdAt',
        Header: t`Added at`,
        accessor: (report: ReportModel) => report.formatCreatedAt(),
        show: false,
      },
    ],
    []
  )

  if (reportQuery.isError) {
    return <AlertDanger>{String(reportQuery.error)}</AlertDanger>
  }

  const {
    data: reports,
    meta: {page: pagination},
  } = reportsData || {
    data: [],
    meta: {page: {}},
  }

  return (
    <Table
      placeholder={t`Reports`}
      data={reports}
      columns={reportColumns}
      pagination={pagination}
      searchParams={searchParams}
      query={reportQuery}
      onChange={setSearchParams}
      onPrefetch={prefetchTableReports}
    />
  )
}

export default ReportsTable
