import React, {ReactChild} from 'react'

import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import {LocalizedLink, useLocalizedLocation} from '@/lib/router'

interface NavProps {
  navItems: NavItemProps[]
  children?: ReactChild
}

export const Nav = ({navItems, children}: NavProps) => (
  <div className="container flex flex-wrap py-10 lg:flex-nowrap lg:space-x-5">
    <div className="mb-4 w-full lg:w-3/12">
      <div
        className="flex flex-wrap"
        role="tablist"
        aria-orientation="vertical"
      >
        {navItems.map((navItem, index) => (
          <NavItem key={index} {...navItem} />
        ))}
      </div>
    </div>

    <div className="w-full lg:w-9/12">{children}</div>
  </div>
)

interface NavItemProps {
  title: string | ReactChild
  url: string
  icon?: IconProp
  iconChild?: JSX.Element
  active?: (pathname: string) => boolean
  onMouseOver?: (event: any) => void
}

const NavItem = ({
  active,
  title,
  url,
  icon,
  iconChild,
  onMouseOver = () => null,
}: NavItemProps) => {
  const {pathname} = useLocalizedLocation()

  if (!active) {
    active = (path) => path === url
  }
  const isActive = active(pathname)

  return (
    <LocalizedLink
      className={classNames(
        'block w-full cursor-pointer rounded px-3 py-2 capitalize',
        isActive && 'bg-primary text-white hover:text-white'
      )}
      to={url}
      onMouseOver={onMouseOver}
      noStyle
    >
      {!!icon && <FontAwesomeIcon icon={icon} className="mr-1" fixedWidth />}
      {!!iconChild && iconChild}
      {title}
    </LocalizedLink>
  )
}

export default Nav
