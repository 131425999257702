import React from 'react'

import {Trans} from '@lingui/macro'

import {useGame} from '@/hooks/query/useGame'

import {LayoutLoader} from '@/components/Loader'
import GameFlyer from '@/components/games/GameFlyer'
import GameInformation from '@/components/games/GameInformation'
import GameLeagues from '@/components/games/GameLeagues'
import GameList from '@/components/games/GameList'
import GamePrices from '@/components/games/GamePrices'
import {CenteredLayoutMessage} from '@/components/layouts/DefaultLayout'
import SmallLocationInformation from '@/components/locations/SmallLocationInformation'
import TournamentDescription from '@/components/tournaments/TournamentDescription'

import {useSlug} from '@/lib/router'

const Game = () => {
  const slug = useSlug()
  const {data: game, isLoading, isError, error} = useGame(slug)

  if (isLoading) {
    return <LayoutLoader />
  }

  if (error?.response?.status === 422 || !game || !game.tournament) {
    return (
      <CenteredLayoutMessage>
        <Trans>Tournament not found</Trans>
      </CenteredLayoutMessage>
    )
  }

  if (isError) {
    return (
      <CenteredLayoutMessage>
        <Trans>500 - Internal server error</Trans>
      </CenteredLayoutMessage>
    )
  }

  return (
    <div className="container space-y-10 py-10">
      <div className="divide-y-2 lg:flex lg:divide-x-2 lg:divide-y-0 lg:divide-primary-light">
        <GameInformation className="lg:w-2/3" game={game} />

        <GameLeagues className="lg:w-1/3" game={game} />
      </div>

      <div className="justify-center divide-y-2 lg:flex lg:divide-x-2 lg:divide-y-0 lg:divide-primary-light">
        <TournamentDescription
          className="lg:w-1/2"
          tournament={game.tournament}
        />

        <GamePrices className="lg:w-1/2" game={game} />
      </div>

      <SmallLocationInformation location={game.tournament.location} />

      <GameFlyer game={game} />

      {!!game.otherGames().length && (
        <div className="space-y-5">
          <h3 className="text-center text-2xl text-primary">
            <Trans>Other tournament games</Trans>
          </h3>

          <GameList games={game.otherGames()} />
        </div>
      )}
    </div>
  )
}

export default Game
