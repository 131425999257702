import {FormikHelpers} from 'formik/dist/types'

import {SearchParamsProps} from '@/components/Table'
import {ForgotPasswordProps} from '@/components/forms/ForgotPasswordForm'
import {ProfileProps} from '@/components/forms/ProfileForm'
import {ResetPasswordProps} from '@/components/forms/ResetPasswordForm'

import {VerifyProps} from '@/pages/auth/verify/Verify'

import {client, formClient} from '@/lib/api-client'
import {StatModel} from '@/models/Stat'
import {UserModel} from '@/models/User'

export const getMe = async () => {
  const userPayload = await client
    .get('users/me', {
      searchParams: {
        include:
          'emailNewsletter,emailNewsletter.leagues,emailNewsletter.setting,mobileNewsletter,mobileNewsletter.leagues,mobileNewsletter.setting,calendarFilter,calendarFilter.leagues,calendarFilter.setting',
      },
    })
    .json()

  return UserModel.normalizePayload(userPayload)
}

export const postResendVerifyEmail = async () =>
  await client.post('users/verify/send').json()

export const postVerify = async (verifyProps: VerifyProps) => {
  await client
    .post('users/verify', {
      json: verifyProps,
    })
    .json()
}

export const postForgotPassword = async (
  forgotPasswordProps: ForgotPasswordProps,
  formikHelpers: FormikHelpers<ForgotPasswordProps>
) => {
  await formClient
    .post(formikHelpers, 'users/reset/send', {
      json: {
        data: {
          type: 'users',
          attributes: forgotPasswordProps,
        },
      },
    })
    .json()
}

export const postResetPassword = async (
  resetPasswordProps: ResetPasswordProps,
  formikHelpers: FormikHelpers<ResetPasswordProps>
) => {
  await formClient
    .post(formikHelpers, 'users/reset', {
      json: {
        data: {
          type: 'users',
          attributes: resetPasswordProps,
        },
      },
    })
    .json()
}

export const patchUser = async (
  {id, ...profileProps}: ProfileProps,
  formikHelpers: FormikHelpers<ProfileProps>
) => {
  const userPayload = await formClient
    .patch(formikHelpers, `users/${id}`, {
      json: {
        data: {
          id,
          type: 'users',
          attributes: profileProps,
        },
      },
    })
    .json()

  return UserModel.normalizePayload(userPayload)
}

export const getStats = async () => {
  const statsPayload = await client.get('users/stats').json()

  return StatModel.fromNormalizedPayload(statsPayload)
}

export const getUsers = async (searchParams: SearchParamsProps) => {
  const usersPayload = await client
    .get('users', {
      searchParams: {
        include: '',
        ...searchParams,
      },
    })
    .json()

  return UserModel.normalizePayload(usersPayload)
}
