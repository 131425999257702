import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  ForwardedRef,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactChild,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react'

import classNames from 'classnames'
import {useField, useFormikContext} from 'formik'
import {useWindowSize} from 'react-use'

import {
  ErrorIcon,
  InvalidIcon,
  RequiredIcon,
  ValidIcon,
} from '@/components/Icons'
import Loader from '@/components/Loader'

import {FieldInterface, FormControl} from '@/lib/form'

export interface InputProps<Value> {
  type?: string
  name?: string
  placeholder?: string
  className?: string
  value?: Value
  multiple?: boolean
  checked?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  onMouseDown?: MouseEventHandler<HTMLInputElement>
  onIconContainerWidth?: (iconContainerWidth: number) => void
  loading?: boolean
  disabled?: boolean
  invalid?: boolean
  valid?: boolean
  error?: boolean
  required?: boolean
  icon?: ReactChild
  autoFocus?: boolean
  readOnly?: boolean
  step?: string
  accept?: string
  autoComplete?: string
  noStyle?: boolean
}

export const Input = forwardRef(
  (
    {
      className,
      value,
      loading,
      valid,
      invalid,
      required,
      icon,
      onIconContainerWidth,
      autoComplete,
      noStyle,
      error,
      autoFocus,
      ...props
    }: InputProps<any>,
    ref: ForwardedRef<any>
  ) => {
    const iconContainerRef = useRef<HTMLDivElement>(null)
    const [iconContainerWidth, setIconContainerWidth] = useState(0)
    const {width} = useWindowSize()

    useEffect(() => {
      if (!iconContainerRef.current) {
        return
      }
      setIconContainerWidth(iconContainerRef.current.offsetWidth)
      onIconContainerWidth &&
        onIconContainerWidth(iconContainerRef.current.offsetWidth)
    }, [iconContainerRef.current?.offsetWidth])

    return (
      <div className="relative w-full">
        <input
          ref={ref}
          className={classNames(
            className,
            !noStyle && 'input',
            !noStyle && invalid && 'invalid',
            !noStyle && valid && 'valid'
          )}
          style={{
            paddingRight: noStyle
              ? 0
              : `calc(${iconContainerWidth}px + 1.25rem)`,
          }}
          value={value === null || typeof value === 'undefined' ? '' : value}
          autoComplete={autoComplete || 'other'}
          autoFocus={autoFocus && width >= 1024}
          {...props}
        />

        <div
          ref={iconContainerRef}
          className="pointer-events-none absolute bottom-0 top-0 right-2 flex items-center space-x-1"
        >
          {icon}
          {error && <ErrorIcon />}
          {loading && <Loader />}
          {!error && invalid && <InvalidIcon />}
          {!error && valid && <ValidIcon />}
          {!error && !invalid && !valid && required && <RequiredIcon />}
        </div>
      </div>
    )
  }
)

const FieldInput = ({
  name,
  disabled,
  ...props
}: InputProps<any> & FieldInterface) => {
  const {isSubmitting} = useFormikContext()
  const [field, meta] = useField(name)

  return (
    <FormControl meta={meta}>
      <Input
        {...field}
        {...props}
        invalid={
          (meta.initialTouched || meta.touched) &&
          (!!meta.error || !!meta.initialError)
        }
        valid={
          (meta.touched && !meta.error) ||
          (meta.initialTouched && !meta.initialError)
        }
        disabled={isSubmitting || disabled}
      />
    </FormControl>
  )
}

export default FieldInput
