import {useQuery} from 'react-query'

import {getLocation, getLocations, getSelectLocations} from '@/api/locations'

import {SearchParamsProps} from '@/components/Table'

import {queryClient} from '@/lib/query'
import {LocationModel, locationSearchParams} from '@/models/Location'

export const useSelectLocations = () =>
  useQuery(LocationModel.SELECT_KEY, getSelectLocations, {
    select: (locationsPayload) => LocationModel.toSelectItems(locationsPayload),
  })

export const prefetchSelectLocations = () =>
  queryClient.prefetchQuery(LocationModel.SELECT_KEY, getSelectLocations)

export const useLocation = (slug: string) =>
  useQuery([LocationModel.KEY, slug], () => getLocation(slug), {
    select: (locationPayload) =>
      LocationModel.fromNormalizedPayload(locationPayload).data,
  })

export const prefetchLocation = (slug: string) =>
  queryClient.prefetchQuery([LocationModel.KEY, slug], () => getLocation(slug))

export const useLocations = (searchParams: SearchParamsProps) =>
  useQuery(
    [LocationModel.KEY, searchParams],
    () => getLocations(searchParams),
    {
      select: (locationsPayload) =>
        LocationModel.fromNormalizedPayload(locationsPayload),
      keepPreviousData: true,
    }
  )

export const prefetchLocations = (
  searchParams: SearchParamsProps = locationSearchParams
) =>
  queryClient.getQueryData([LocationModel.KEY, searchParams]) &&
  queryClient.prefetchQuery([LocationModel.KEY, searchParams], () =>
    getLocations(searchParams)
  )
