import React from 'react'

import {
  faCity,
  faGlobe,
  faGlobeEurope,
  faPhone,
  faRoad,
  faUniversity,
} from '@fortawesome/free-solid-svg-icons'

import InputLabel from '@/components/InputLabel'
import LocationReport from '@/components/locations/LocationReport'

import {ExternalLink} from '@/lib/router'
import {LocationModel} from '@/models/Location'

interface LocationLabelsProps {
  location: LocationModel
}

const LocationLabels = ({location}: LocationLabelsProps) => (
  <div className="space-y-5 whitespace-nowrap">
    <InputLabel icon={faGlobeEurope}>{location.country}</InputLabel>

    <InputLabel icon={faUniversity}>{location.areaLevel}</InputLabel>

    <InputLabel icon={faCity}>
      {location.postalCode} {location.city}
    </InputLabel>

    <InputLabel icon={faRoad}>{location.street}</InputLabel>

    {location.number && (
      <ExternalLink className="block" to={`tel:${location.number}`}>
        <InputLabel icon={faPhone}>{location.number}</InputLabel>
      </ExternalLink>
    )}

    {location.website && (
      <ExternalLink className="block" to={location.website}>
        <InputLabel icon={faGlobe}>{location.websiteHost()}</InputLabel>
      </ExternalLink>
    )}

    <div className="flex justify-end">
      <LocationReport location={location} />
    </div>
  </div>
)

export default LocationLabels
