import React from 'react'

import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans} from '@lingui/macro'

import {AlertDanger} from '@/components/Alert'

import {GameModel} from '@/models/Game'

interface GameCanceledProps {
  game: GameModel
}

const GameCanceled = ({game}: GameCanceledProps) => {
  if (!game.canceled) {
    return null
  }

  return (
    <AlertDanger>
      <div className="flex">
        <div className="w-auto">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>

        <div className="flex-grow">
          <Trans>This tournament has been cancelled!</Trans>
        </div>

        <div className="w-auto">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
      </div>
    </AlertDanger>
  )
}

export default GameCanceled
