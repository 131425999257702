import React from 'react'

import {Trans} from '@lingui/macro'

import {
  prefetchReviewTournaments,
  useReviewTournaments,
} from '@/hooks/query/useTournament'

import {Title} from '@/components/Title'
import TournamentsTable from '@/components/tabels/TournamentsTable'

const Review = () => (
  <div>
    <Title>
      <Trans>Tournament reviews</Trans>
    </Title>

    <TournamentsTable
      useTableTournaments={useReviewTournaments}
      prefetchTableTournaments={prefetchReviewTournaments}
      reviewActions
    />
  </div>
)

export default Review
