import React, {ReactChild} from 'react'

import {Trans} from '@lingui/macro'
import classNames from 'classnames'
import {useFormikContext} from 'formik'

import {RequiredIcon} from '@/components/Icons'

interface FieldSubmitButtonProps {
  className?: string
  children: ReactChild
  small?: boolean
}

const FieldSubmitButton = ({
  className = '',
  children,
  small,
}: FieldSubmitButtonProps) => {
  const {submitForm, isSubmitting} = useFormikContext()

  return (
    <button
      type="button"
      className={classNames('btn-primary', small ? 'btn-sm' : 'btn', className)}
      onClick={submitForm}
      disabled={isSubmitting}
    >
      {children}
    </button>
  )
}

export const FieldRequiredSubmitButton = ({
  className,
  ...props
}: FieldSubmitButtonProps) => (
  <div className="flex flex-wrap space-x-0 lg:flex-nowrap lg:space-x-5">
    <div className="order-last mt-5 w-full lg:order-first lg:mt-0">
      <RequiredIcon /> <Trans>Required fields</Trans>
    </div>

    <FieldSubmitButton
      className={`order-first w-full lg:order-last lg:w-auto lg:whitespace-nowrap ${className}`}
      {...props}
    />
  </div>
)

export default FieldSubmitButton
