import React, {useMemo, useState} from 'react'
import {UseQueryResult} from 'react-query'

import {t} from '@lingui/macro'

import {prefetchSentEmails, useSentEmails} from '@/hooks/query/useSentEmail'

import {AlertDanger} from '@/components/Alert'
import Table, {SearchParamsProps} from '@/components/Table'

import {ExternalLink} from '@/lib/router'
import {SentEmailModel, sentEmailSearchParams} from '@/models/SentEmail'

interface SentEmailsTableProps {
  useTableSentEmails?: (searchParams: SearchParamsProps) => UseQueryResult<any>
  prefetchTableSentEmails?: (searchParams: SearchParamsProps) => void
}

const SentEmailsTable = ({
  useTableSentEmails = useSentEmails,
  prefetchTableSentEmails = prefetchSentEmails,
}: SentEmailsTableProps) => {
  const [searchParams, setSearchParams] = useState<SearchParamsProps>(
    sentEmailSearchParams
  )
  const {data: sentEmailsData, ...sentEmailQuery} =
    useTableSentEmails(searchParams)
  const sentEmailColumns = useMemo(
    () => [
      {
        Header: t`Subject`,
        accessor: 'subject',
      },
      {
        id: 'to',
        Header: t`To`,
        accessor: (sentEmail: SentEmailModel) => (
          <ExternalLink to={`mailto:${sentEmail.to}`}>
            {sentEmail.to}
          </ExternalLink>
        ),
      },
      {
        Header: t`Cc`,
        accessor: 'cc',
        show: false,
      },
      {
        Header: t`Bcc`,
        accessor: 'bcc',
        show: false,
      },
      {
        Header: t`From`,
        accessor: 'from',
        show: false,
      },
      {
        id: 'createdAt',
        Header: t`Sent at`,
        accessor: (sentEmail: SentEmailModel) => sentEmail.formatCreatedAt(),
      },
    ],
    []
  )

  if (sentEmailQuery.isError) {
    return <AlertDanger>{String(sentEmailQuery.error)}</AlertDanger>
  }

  const {
    data: sentEmails,
    meta: {page: pagination},
  } = sentEmailsData || {
    data: [],
    meta: {page: {}},
  }

  return (
    <Table
      placeholder={t`Sent emails`}
      data={sentEmails}
      columns={sentEmailColumns}
      pagination={pagination}
      searchParams={searchParams}
      query={sentEmailQuery}
      onChange={setSearchParams}
      onPrefetch={prefetchTableSentEmails}
    />
  )
}

export default SentEmailsTable
