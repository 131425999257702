import {t} from '@lingui/macro'
import * as Yup from 'yup'

const MIN_LATITUDE = -90
const MAX_LATITUDE = 90

const MIN_LONGITUDE = -180
const MAX_LONGITUDE = 180

const MIN_RADIUS = 0
const MAX_RADIUS = 6371000

export const SUPPORTED_IMAGE_FORMATS = ['image/png', 'image/jpeg', 'image/jpg']

export const MAX_FILE_SIZE = 100 * 1000
export const MAX_FILE_SIZE_FORMATTED = '100 MB'

const latitude = () =>
  Yup.number()
    .min(MIN_LATITUDE)
    .max(MAX_LATITUDE)
    .label(t`Latitude`)

const longitude = () =>
  Yup.number()
    .min(MIN_LONGITUDE)
    .max(MAX_LONGITUDE)
    .label(t`Longitude`)

const radius = () =>
  Yup.number()
    .integer()
    .min(MIN_RADIUS)
    .max(MAX_RADIUS)
    .label(t`Radius`)

const currentPosition = () =>
  Yup.bool()
    .required()
    .label(t`Current position`)

const email = () =>
  Yup.string()
    .email()
    .label(t`Email`)

const leagues = () =>
  Yup.array()
    .of(Yup.string())
    .label(t`Leagues`)

const password = () =>
  Yup.string()
    .min(8)
    .label(t`Password`)

const file = () =>
  Yup.mixed()
    .nullable()
    .test(
      'fileSize',
      ({path}) => t`${path} maximum file size is ${MAX_FILE_SIZE_FORMATTED}`,
      (value) => value?.size || 0 <= MAX_FILE_SIZE
    )
    .test(
      'fileType',
      ({path}) => t`${path} unsupported file format`,
      (value) => !value || SUPPORTED_IMAGE_FORMATS.includes(value?.type)
    )

const singleSetting = () =>
  Yup.object()
    .required()
    .shape({
      variant: Yup.string()
        .required()
        .min(1)
        .oneOf(['sd', 'ed'])
        .label(t`Darts variant`),
      value: Yup.string()
        .required()
        .min(1)
        .oneOf(['301', '501', '701'])
        .label(t`Darts value`),
      size: Yup.string()
        .required()
        .min(1)
        .oneOf(['e', 'd', 't'])
        .label(t`Darts size`),
      out: Yup.string()
        .required()
        .min(1)
        .oneOf(['so', 'do', 'mo'])
        .label(t`Darts out`),
      mixed: Yup.boolean().label(t`Darts mixed`),
      dyp: Yup.boolean().label(t`Darts dyp`),
      doubleIn: Yup.boolean().label(t`Darts double in`),
    })

const multiSetting = () =>
  Yup.object()
    .shape({
      variant: Yup.array()
        .of(Yup.string().oneOf(['sd', 'ed']))
        .label(t`Darts variant`),
      value: Yup.array()
        .of(Yup.string().oneOf(['301', '501', '701']))
        .label(t`Darts value`),
      size: Yup.array()
        .of(Yup.string().oneOf(['e', 'd', 't']))
        .label(t`Darts size`),
      out: Yup.array()
        .of(Yup.string().oneOf(['so', 'do', 'mo']))
        .label(t`Darts out`),
      mixed: Yup.boolean().label(t`Darts mixed`),
      dyp: Yup.boolean().label(t`Darts dyp`),
      doubleIn: Yup.boolean().label(t`Darts double in`),
    })
    .label(t`Darts setting`)

export default {
  ...Yup,
  latitude,
  longitude,
  leagues,
  radius,
  currentPosition,
  email,
  password,
  file,
  singleSetting,
  multiSetting,
}
