import jsonApiNormalizer from '@spiderdan98/json-api-normalizer'
import _ from 'lodash'

import {intlFormatDateTime} from '@/lib/date'

export interface ModelInterface {
  id: string
  createdAt: string
  updatedAt: string
}

type Constructor<T> = {new (attributes: object): T}

export class Model<Model extends ModelInterface> implements ModelInterface {
  public id!: string
  protected attributes!: Model
  protected relationships: any = {}
  public createdAt!: string
  public updatedAt!: string

  public static KEY = 'model'

  constructor(attributes: object) {
    this.attributes = attributes as Model

    Object.keys(attributes).forEach((key) => {
      const attribute = attributes[key]
      const isSingleRelationship =
        typeof attribute === 'object' && attribute?.id && attribute?.type
      const isManyRelationship =
        Array.isArray(attribute) && attribute[0]?.id && attribute[0]?.type

      if (
        isSingleRelationship ||
        isManyRelationship ||
        (Array.isArray(attribute) && typeof attribute[0] === 'undefined')
      ) {
        return
      }

      this[key] = attribute
    })
  }

  public static create<T>(this: Constructor<T>, data: object): T {
    return new this({...data})
  }

  public static createMany<T>(this: Constructor<T>, data: object[]): T[] {
    return [...data].filter((data) => data).map((data) => new this(data))
  }

  public static normalizePayload(payload: any): any {
    return jsonApiNormalizer(payload, {
      includeJsonapi: false,
      includeLinks: false,
    })
  }

  public static fromNormalizedPayload(normalizedPayload: any): any {
    const data = Array.isArray(normalizedPayload.data)
      ? this.createMany(normalizedPayload.data)
      : this.create(normalizedPayload.data)

    return {
      ...normalizedPayload,
      data,
    }
  }

  private key(): string {
    return (this.constructor as typeof Model).KEY
  }

  protected singleRelationship<T>(relation: any, name: string): T {
    if (this.relationships[name]) {
      return this.relationships[name]
    }

    const relationshipData = this.getAttributes()[name]

    if (!relationshipData) {
      return null
    }

    return (this.relationships[name] = relation.create(relationshipData))
  }

  protected manyRelationship<T>(relation: any, name: string): T {
    if (this.relationships[name]) {
      return this.relationships[name]
    }

    const relationshipData = this.getAttributes()[name]

    if (!relationshipData) {
      return [] as any as T
    }

    return (this.relationships[name] = relation.createMany(relationshipData))
  }

  public getAttributes(): Model {
    return this.attributes
  }

  public toJson(): string {
    return JSON.stringify(this.attributes)
  }

  public formatCreatedAt(): string {
    return this.createdAt ? intlFormatDateTime(new Date(this.createdAt)) : '-'
  }

  public formatUpdatedAt(): string {
    return this.updatedAt ? intlFormatDateTime(new Date(this.updatedAt)) : '-'
  }

  public only(keys: string[]): any {
    return _.pick(this.getAttributes(), keys)
  }
}
