import React from 'react'

import {faEuroSign} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useField, useFormikContext} from 'formik'

import {Input} from '@/components/fields/FieldInput'

import {FieldInterface, FormControl} from '@/lib/form'

interface FieldBuyInProps extends FieldInterface {
  required?: boolean
}

const FieldBuyIn = ({name, ...props}: FieldBuyInProps) => {
  const {isSubmitting} = useFormikContext()
  const [{value}, meta, helpers] = useField(name)

  return (
    <FormControl meta={meta}>
      <div className="group">
        <Input
          type="number"
          value={value / 100 || ''}
          name={name}
          step=".01"
          onChange={(e) =>
            helpers.setValue(
              parseInt(
                parseFloat(e.target.value)
                  .toFixed(2)
                  .toString()
                  .replace('.', '')
              ) || undefined
            )
          }
          onBlur={() => helpers.setTouched(true)}
          invalid={
            (meta.initialTouched || meta.touched) &&
            (!!meta.error || !!meta.initialError)
          }
          valid={
            (meta.touched && !meta.error) ||
            (meta.initialTouched && !meta.initialError)
          }
          disabled={isSubmitting}
          {...props}
        />

        <button type="button" className="btn btn-primary active" disabled>
          <FontAwesomeIcon icon={faEuroSign} />
        </button>
      </div>
    </FormControl>
  )
}

export default FieldBuyIn
