import React, {useState} from 'react'

import {Trans} from '@lingui/macro'
import classNames from 'classnames'

import {postCancel} from '@/api/games'

import {SearchParamsProps} from '@/components/Table'

import {toggleGameCanceled} from '@/actions/game'
import Modal, {ModalProps} from '@/lib/modal'
import {GameModel} from '@/models/Game'

interface CancelGameModelProps extends ModalProps {
  game: GameModel
  searchParams: SearchParamsProps
}

const ToggleCancelGameModal = ({
  game,
  searchParams,
  ...props
}: CancelGameModelProps) => {
  const [isSubmitting, setSubmitting] = useState(false)

  return (
    <Modal
      title={<Trans>Cancel game</Trans>}
      footer={
        <button
          type="button"
          className={classNames(
            'btn',
            game.canceled && 'btn-primary',
            !game.canceled && 'btn-danger'
          )}
          onClick={async () => {
            setSubmitting(true)
            await postCancel(game.id)
            await toggleGameCanceled(game, searchParams)
            setSubmitting(false)
            props.onHide()
          }}
          disabled={isSubmitting}
        >
          {!game.canceled && <Trans>Cancel game</Trans>}
          {game.canceled && <Trans>Activate game</Trans>}
        </button>
      }
      {...props}
    >
      {!game.canceled && (
        <Trans>
          Do you really want to cancel the game „{game.tournament.name}“ on{' '}
          {game.formatStart()}?
        </Trans>
      )}

      {game.canceled && (
        <Trans>
          Do you really want to activate the game „{game.tournament.name}“ on{' '}
          {game.formatStart()}?
        </Trans>
      )}
    </Modal>
  )
}

export default ToggleCancelGameModal
