import {i18n} from '@lingui/core'
import {fromNavigator, fromPath, multipleDetect} from '@lingui/detect-locale'
import {de, en} from 'make-plural'
import 'regenerator-runtime/runtime.js'
import {de as yupDe} from 'yup-locales'

import Yup from '@/lib/yup'

export const GERMAN = 'de'
export const ENGLISH = 'en'
export const supportedLocales = [ENGLISH, GERMAN]
export const defaultLocale = supportedLocales[0]

i18n.loadLocaleData({
  [ENGLISH]: {plurals: en},
  [GERMAN]: {plurals: de},
})

export const loadDetectedLocale = async () => {
  const locale = detectLocale()

  await changeLocale(locale)
}

export const detectLocale = () => {
  const detectedLocales = multipleDetect(fromPath(0), fromNavigator())

  const detectedIso2Codes = detectedLocales.map(
    (detectLocale) => detectLocale.split('-')[0]
  )

  return (
    detectedIso2Codes.find((detectedIso2Code) =>
      supportedLocales.includes(detectedIso2Code)
    ) || defaultLocale
  )
}

const getYupLocale = (locale: string) => {
  if (locale === GERMAN) {
    return yupDe
  }

  return null
}

export const changeLocale = async (locale: string) => {
  if (locale !== ENGLISH) {
    Yup.setLocale(getYupLocale(locale))
  }
  const {messages} = await import(`../locales/${locale}/messages.ts`)
  i18n.load(locale, messages)
  i18n.activate(locale)
}
