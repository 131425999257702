import {t} from '@lingui/macro'
import {toast} from 'react-hot-toast'

import {EmailNewsletterModel} from '@/models/EmailNewsletter'
import {MobileNewsletterModel} from '@/models/MobileNewsletter'

export const successSubscribeNewsletterToast = (
  newsletter: EmailNewsletterModel | MobileNewsletterModel
) => toast.success(t`The newsletter has been subscribed.`)
export const successUpdateNewsletterToast = (
  newsletter: EmailNewsletterModel | MobileNewsletterModel
) => toast.success(t`The newsletter has been changed.`)
export const successUnsubscribeNewsletterToast = () =>
  toast.success(t`The newsletter has been unsubscribed.`)
export const errorUnsubscribeNewsletterToast = () =>
  toast.error(t`The newsletter can not be unsubscribed.`)
