import React from 'react'

import {Trans} from '@lingui/macro'

const NotFound = () => (
  <div className="flex min-h-content items-center justify-center text-2xl">
    <Trans>404 - Page not found</Trans>
  </div>
)

export default NotFound
