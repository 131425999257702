import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import {deleteEmailNewsletter} from '@/api/email-newsletters'

import {LayoutLoader} from '@/components/Loader'

import {useLocalizedNavigate} from '@/lib/router'
import {
  errorUnsubscribeNewsletterToast,
  successUnsubscribeNewsletterToast,
} from '@/toasts/newsletter'

const UnsubscribeEmailNewsletter = () => {
  const {search} = useLocation()
  const query = new URLSearchParams(search)
  const navigate = useLocalizedNavigate()

  useEffect(() => {
    deleteEmailNewsletter(
      query.get('id'),
      query.get('email'),
      query.get('token')
    )
      .then(() => successUnsubscribeNewsletterToast())
      .catch(() => errorUnsubscribeNewsletterToast())
      .finally(() => navigate('/'))
  }, [])

  return <LayoutLoader />
}

export default UnsubscribeEmailNewsletter
