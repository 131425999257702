import {useQuery} from 'react-query'

import {getStats} from '@/api/users'

import {queryClient} from '@/lib/query'
import {StatModel} from '@/models/Stat'

export const useStat = () =>
  useQuery(StatModel.KEY, getStats, {
    select: (statPayload) => StatModel.fromNormalizedPayload(statPayload).data,
  })

export const prefetchStat = () =>
  !queryClient.getQueryData(StatModel.KEY) &&
  queryClient.prefetchQuery(StatModel.KEY, getStats)
