import React, {Fragment, useMemo, useState} from 'react'
import {UseQueryResult} from 'react-query'

import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faEdit,
  faEllipsisV,
  faEye,
  faPowerOff,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans, t} from '@lingui/macro'
import classNames from 'classnames'
import {Row} from 'react-table'

import {
  postAcceptTournament,
  postDismissTournament,
  postToggleCancelTournament,
} from '@/api/tournaments'

import {
  prefetchMyTournaments,
  useMyTournaments,
} from '@/hooks/query/useTournament'

import {AlertDanger} from '@/components/Alert'
import DartLeagues from '@/components/DartLeagues'
import DartsSetting from '@/components/DartsSetting'
import Dropdown from '@/components/Dropdown'
import {TournamentActiveIcon} from '@/components/Icons'
import Table, {SearchParamsProps} from '@/components/Table'
import ToggleCancelGameModal from '@/components/modals/ToggleCancelGameModal'

import {
  acceptTournament,
  dismissTournament,
  toggleTournamentCanceled,
} from '@/actions/tournament'
import {LocalizedLink, useLocalizedNavigate} from '@/lib/router'
import {GameModel} from '@/models/Game'
import {TournamentModel, tournamentSearchParams} from '@/models/Tournament'
import {errorResponseToast} from '@/toasts/api'

interface TournamentsTableProps {
  useTableTournaments?: (searchParams: SearchParamsProps) => UseQueryResult<any>
  prefetchTableTournaments?: (searchParams: SearchParamsProps) => void
  reviewActions?: boolean
}

const TournamentsTable = ({
  useTableTournaments = useMyTournaments,
  prefetchTableTournaments = prefetchMyTournaments,
  reviewActions,
}: TournamentsTableProps) => {
  const [searchParams, setSearchParams] = useState<SearchParamsProps>(
    tournamentSearchParams
  )
  const {data: tournamentsData, ...tournamentQuery} =
    useTableTournaments(searchParams)
  const tournamentColumns = useMemo(
    () => [
      {
        id: 'name',
        Header: t`Tournament name`,
        accessor: (tournament: TournamentModel) => (
          <>
            <TournamentActiveIcon isActive={tournament.isActive} />{' '}
            <LocalizedLink to={tournament.link()}>
              {tournament.name}
            </LocalizedLink>
            <br />
            <small>{tournament.creator?.username}</small>
          </>
        ),
      },
      {
        id: 'locations.name',
        Header: t`Tournament location`,
        accessor: (tournament: TournamentModel) => (
          <>
            <LocalizedLink to={tournament.location.link()}>
              {tournament.location.name}
            </LocalizedLink>
            <br />
            <small>
              {tournament.location.postalCode} {tournament.location.city}
            </small>
          </>
        ),
        sort: false,
      },
      {
        id: 'creator.username',
        Header: t`Added by`,
        accessor: 'creator.username',
        show: false,
        sort: false,
      },
      {
        id: 'createdAt',
        Header: t`Created at`,
        accessor: (tournament: TournamentModel) => tournament.formatCreatedAt(),
        show: false,
      },
      {
        id: 'controls',
        Header: '',
        className: 'w-1 whitespace-nowrap',
        Cell: ({row}: any) => (
          <TournamentActions
            row={row}
            searchParams={searchParams}
            reviewActions={reviewActions}
          />
        ),
        sort: false,
      },
    ],
    []
  )

  if (tournamentQuery.isError) {
    return <AlertDanger>{String(tournamentQuery.error)}</AlertDanger>
  }

  const {
    data: tournaments,
    meta: {page: pagination},
  } = tournamentsData || {
    data: [],
    meta: {page: {}},
  }

  return (
    <Table
      placeholder={t`Tournaments`}
      data={tournaments}
      columns={tournamentColumns}
      pagination={pagination}
      searchParams={searchParams}
      query={tournamentQuery}
      onChange={setSearchParams}
      onPrefetch={prefetchTableTournaments}
      onExpand={({original}) => (
        <TournamentGamesExpand
          tournament={original}
          searchParams={searchParams}
        />
      )}
    />
  )
}

interface TournamentActionsProps {
  row: Row
  searchParams: SearchParamsProps
  reviewActions?: boolean
}

const TournamentActions = ({
  row: {getToggleRowExpandedProps, original, isExpanded},
  searchParams,
  reviewActions,
}: TournamentActionsProps) => {
  const navigate = useLocalizedNavigate()
  const [isSubmitting, setSubmitting] = useState(false)
  const isAllCanceled = original.games.some((game) => !game.canceled)
  const actionItems = useMemo(
    () => [
      {
        label: (
          <Fragment>
            <FontAwesomeIcon icon={faEdit} fixedWidth />{' '}
            <Trans>Edit tournament</Trans>
          </Fragment>
        ),
        onClick: () => navigate(original.editLink()),
      },
      {
        label: (
          <Fragment>
            <FontAwesomeIcon icon={faPowerOff} fixedWidth />{' '}
            {isAllCanceled && <Trans>Cancel tournament</Trans>}
            {!isAllCanceled && <Trans>Activate tournament</Trans>}
          </Fragment>
        ),
        onClick: async () => {
          await postToggleCancelTournament(original.id)
          await toggleTournamentCanceled(original, searchParams)
        },
      },
    ],
    [original]
  )

  return (
    <div className="flex space-x-5">
      {!!reviewActions && (
        <>
          <button
            type="button"
            className="btn-sm btn-primary"
            onClick={async () => {
              setSubmitting(true)
              try {
                await postAcceptTournament(original.id)
                await acceptTournament(original, searchParams)
              } catch ({response}) {
                errorResponseToast(response)
              }
              setSubmitting(false)
            }}
            disabled={isSubmitting}
          >
            <FontAwesomeIcon icon={faCheck} fixedWidth />
          </button>

          <button
            type="button"
            className="btn-sm btn-danger"
            onClick={async () => {
              setSubmitting(true)
              try {
                await postDismissTournament(original.id)
                await dismissTournament(original, searchParams)
              } catch ({response}) {
                errorResponseToast(response)
              }
              setSubmitting(false)
            }}
            disabled={isSubmitting}
          >
            <FontAwesomeIcon icon={faTimes} fixedWidth />
          </button>
        </>
      )}

      <button
        type="button"
        className="btn-sm btn-gray py-1"
        {...getToggleRowExpandedProps()}
      >
        <FontAwesomeIcon
          icon={isExpanded ? faChevronUp : faChevronDown}
          fixedWidth
        />
      </button>

      <Dropdown
        className="inline-block"
        buttonClassName="btn-sm btn-gray py-1"
        placement="bottom-end"
        items={actionItems}
        noSameWidth
        noActive
        noArrow
      >
        <FontAwesomeIcon icon={faEllipsisV} fixedWidth />
      </Dropdown>
    </div>
  )
}

interface TournamentGamesExpandProps {
  tournament: TournamentModel
  searchParams: SearchParamsProps
}

const TournamentGamesExpand = (props: TournamentGamesExpandProps) => (
  <>
    {(props.tournament.games || []).map((game) => (
      <TournamentGameExpand key={game.id} game={game} {...props} />
    ))}
  </>
)

interface TournamentGameExpandProps extends TournamentGamesExpandProps {
  game: GameModel
}

const TournamentGameExpand = ({
  game,
  tournament,
  searchParams,
}: TournamentGameExpandProps) => {
  const navigate = useLocalizedNavigate()
  const [showCancelGameModal, setShowCancelGameModal] = useState(false)

  game.tournament = tournament

  return (
    <div className="flex items-center space-x-6 whitespace-nowrap px-6 py-4">
      <div className="w-full">
        <DartsSetting setting={game.setting} />
      </div>

      <div className="w-full">{game.formatStart()}</div>

      <div>
        <DartLeagues leagues={game.leagues} />
      </div>

      <div className="w-auto">
        <div className="space-x-5">
          <button
            type="button"
            className="btn-sm btn-info py-1"
            onClick={() => navigate(game.link())}
          >
            <FontAwesomeIcon icon={faEye} fixedWidth />
          </button>

          <ToggleCancelGameModal
            game={game}
            searchParams={searchParams}
            open={showCancelGameModal}
            onHide={() => setShowCancelGameModal(false)}
          />

          <button
            type="button"
            className={classNames(
              'btn-sm py-1',
              !game.canceled && 'btn-danger',
              game.canceled && 'btn-primary'
            )}
            onClick={() => setShowCancelGameModal(true)}
          >
            <FontAwesomeIcon icon={faPowerOff} fixedWidth />
          </button>
        </div>
      </div>
    </div>
  )
}

export default TournamentsTable
