import React from 'react'

import {Trans, t} from '@lingui/macro'

import {postReportLocation} from '@/api/reports'

import FieldInput from '@/components/fields/FieldInput'
import FieldSubmitButton from '@/components/fields/FieldSubmitButton'
import FieldTextArea from '@/components/fields/FieldTextArea'

import {reportLocation} from '@/actions/game'
import Form from '@/lib/form'
import Modal, {ModalProps} from '@/lib/modal'
import Yup from '@/lib/yup'
import {LocationModel} from '@/models/Location'
import {ReportModel} from '@/models/Report'
import {errorResponseToast} from '@/toasts/api'

interface ReportLocationModalFormProps extends ModalProps {
  location: LocationModel
  onSuccess?: (report: ReportModel) => void
}

const reportLocationInitValues = {
  id: '',
  reason: '',
  amendment: '',
}

const ReportLocationModelForm = ({
  location,
  onSuccess = () => null,
  ...props
}: ReportLocationModalFormProps) => {
  const reportLocationValidationSchema = Yup.object().shape({
    reason: Yup.string()
      .min(3)
      .max(191)
      .required()
      .label(t`Reason`),
    amendment: Yup.string()
      .min(3)
      .label(t`Amendment`),
  })

  return (
    <Form
      initialValues={{
        ...reportLocationInitValues,
        id: location.id,
      }}
      validationSchema={reportLocationValidationSchema}
      onSubmit={async (values, formikHelpers) => {
        try {
          const reportPayload = await postReportLocation(values, formikHelpers)
          const report = ReportModel.fromNormalizedPayload(reportPayload)
          reportLocation(location, report)
          onSuccess(report)
          props.onHide()
        } catch (response) {
          errorResponseToast(response)
        }
      }}
    >
      {() => (
        <Modal
          title={t`Report tournament location`}
          footer={
            <FieldSubmitButton>
              <Trans>Report tournament location</Trans>
            </FieldSubmitButton>
          }
          center
          {...props}
        >
          <FieldInput name="reason" placeholder={t`Reason`} required />

          <FieldTextArea name="amendment" placeholder={t`Amendment`} />
        </Modal>
      )}
    </Form>
  )
}

export default ReportLocationModelForm
