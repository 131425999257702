import React from 'react'
import {Outlet} from 'react-router-dom'

import {
  faCalendarAlt,
  faClipboardList,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons'
import {Trans} from '@lingui/macro'

import Nav from '@/components/Nav'

const GuideLayout = () => (
  <Nav
    navItems={[
      {
        title: <Trans>Game modes</Trans>,
        url: '/guides/game-modes',
        icon: faClipboardList,
      },
      {
        title: <Trans>Tournament calendar</Trans>,
        url: '/guides/tournaments/calendar',
        icon: faCalendarAlt,
      },
      {
        title: <Trans>Newsletter</Trans>,
        url: '/guides/newsletter',
        icon: faPaperPlane,
      },
    ]}
    children={<Outlet />}
  />
)

export default GuideLayout
