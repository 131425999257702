import React from 'react'

import {t} from '@lingui/macro'
import classNames from 'classnames'

import logo from '@/images/darts-turniere-logo.svg'

export interface LogoProps {
  className?: string
  size: number
  onClick?: () => void
}

const Logo = ({size, className = '', ...props}: LogoProps) => (
  <img
    src={logo}
    className={classNames('inline-block', className)}
    width={size}
    height={size}
    alt={t`Darts Turniere Logo`}
    {...props}
  />
)

export default Logo
