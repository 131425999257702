import React, {useState} from 'react'

import {useField} from 'formik'
import {Circle, GoogleMap, Marker, withGoogleMap} from 'react-google-maps'
import {useUpdateEffect} from 'react-use'

import useGoogleMapsLoaded from '@/hooks/useGoogleMapsLoaded'

import Loader from '@/components/Loader'

import {FieldGroupInterface, FormControl} from '@/lib/form'

const centerGermany = {lat: 50.9434014, lng: 10.2931043}

interface MapRadiusProps {
  position: google.maps.LatLngLiteral | false
  center: google.maps.LatLngLiteral
  radius?: number | false
}

const MapRadius = withGoogleMap<MapRadiusProps>(
  ({position, radius, ...props}) => (
    <GoogleMap
      defaultZoom={5}
      defaultOptions={{
        streetViewControl: false,
      }}
      {...props}
    >
      {!!position && <Marker position={position} />}

      {!!position && !!radius && (
        <Circle
          center={position}
          radius={radius}
          defaultOptions={{
            strokeColor: '#64a19d',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#64a19d',
            fillOpacity: 0.35,
          }}
        />
      )}
    </GoogleMap>
  )
)

const FieldGroupMapRadius = ({namePrefix = ''}: FieldGroupInterface) => {
  const googleMapsLoaded = useGoogleMapsLoaded()
  const [{value}] = useField(namePrefix)
  const position = value.latitude &&
    value.longitude && {
      lat: value.latitude,
      lng: value.longitude,
    }
  const [center, setCenter] = useState(position || centerGermany)

  useUpdateEffect(() => {
    if (value.currentPosition) {
      setCenter(position)
    }
  }, [value.currentPosition])

  useUpdateEffect(() => {
    if (value.latitude && value.longitude) {
      setCenter(position)
    }

    if (!value.latitude && !value.longitude) {
      setCenter(centerGermany)
    }
  }, [value.latitude, value.longitude])

  if (!googleMapsLoaded) {
    return (
      <div className="mb-7 flex h-96 items-center justify-center overflow-hidden rounded bg-white">
        <Loader className="text-6xl text-primary" />
      </div>
    )
  }

  return (
    <FormControl>
      <MapRadius
        containerElement={<div className="h-96 overflow-hidden rounded" />}
        mapElement={<div className="h-full" />}
        position={position}
        radius={value.radius}
        center={center}
      />
    </FormControl>
  )
}

export default FieldGroupMapRadius
