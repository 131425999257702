import {SearchParamsProps} from '@/components/Table'
import {TournamentProps} from '@/components/forms/TournamentForm'

import {Model, ModelInterface} from '@/lib/model'
import {GameModel} from '@/models/Game'
import {LocationModel} from '@/models/Location'
import {UserModel} from '@/models/User'

export interface TournamentInterface extends ModelInterface {
  name: string
  deadline?: number
  description: string | null
  isActive: boolean
  location: LocationModel
}

export class TournamentModel extends Model<TournamentInterface> {
  name!: string
  deadline!: number | null
  description!: string | null
  isActive!: boolean

  public static KEY = 'tournaments'
  public static MY_KEY = 'my-tournaments'
  public static REVIEW_KEY = 'review-tournaments'
  public static EXISTS_PREDICTION_KEY = 'exists-prediction-tournaments'

  get creator(): UserModel {
    return this.singleRelationship(UserModel, 'creator')
  }

  get location(): LocationModel {
    return this.singleRelationship(LocationModel, 'location')
  }

  get games(): GameModel[] {
    return this.manyRelationship(GameModel, 'games')
  }

  public slug(): string {
    return `/${this.id}`
  }

  public link(): string {
    return `/tournaments/${this.id}`
  }

  public editLink(): string {
    return `/profile/tournaments/${this.id}/edit`
  }

  public toInitValues(): TournamentProps {
    return {
      ...this.attributes,
      tournamentId: this.id,
      locationId: this.location.id,
      starts: this.games.map((game) => new Date(game.start)),
      tournamentGames: this.games.map((game) => game.toInitValues()),
      imageSlug: this.games[0].image,
      deadline: this.deadline ? String(this.deadline) : undefined,
    }
  }
}

export const tournamentSearchParams: SearchParamsProps = {
  'filter[search]': '',
  'page[size]': 25,
  'page[number]': 0,
  sort: '-createdAt',
}
