import React from 'react'

import {t} from '@lingui/macro'
import classNames from 'classnames'

import Tooltip from '@/components/Tooltip'

import {SettingInterface} from '@/models/Setting'

const getVariant = (variant: string) => {
  if (variant === 'ed') {
    return t`E-Dart`
  }

  return t`Steel dart`
}

const getSize = (size: string) => {
  if (size === 'e') {
    return t`Single`
  }

  if (size === 'd') {
    return t`Double`
  }

  return t`Triple`
}

const getOut = (out: string) => {
  if (out === 'so') {
    return t`Single out`
  }

  if (out === 'mo') {
    return t`Master out`
  }

  return t`Double out`
}

interface DartsSettingProps {
  className?: string
  setting?: SettingInterface | any
  noTooltips?: boolean
}

const DartsSetting = ({
  className = '',
  setting = {},
  noTooltips,
}: DartsSettingProps) => {
  if (!Object.keys(setting || {}).length) {
    return null
  }

  return (
    <div className={classNames('flex uppercase', className)}>
      <DartsSettingItem
        tooltip={!noTooltips && getVariant(setting.variant)}
        className="text-setting-variant"
        children={setting.variant?.toUpperCase()}
      />

      <DartsSettingItem
        tooltip={!noTooltips && getSize(setting.size)}
        className="text-setting-size"
        children={setting.size?.toUpperCase()}
      />

      <DartsSettingItem
        tooltip={!noTooltips && setting.value?.toUpperCase()}
        className="text-setting-value"
        children={setting.value?.toUpperCase()}
      />

      <DartsSettingItem
        tooltip={!noTooltips && getOut(setting.out)}
        className="text-setting-out"
        children={setting.out?.toUpperCase()}
      />

      {setting.mixed && (
        <DartsSettingItem
          tooltip={!noTooltips && t`Mixed`}
          className="text-setting-mixed"
          children="M"
        />
      )}

      {setting.dyp && (
        <DartsSettingItem
          tooltip={!noTooltips && t`Draw your Partner`}
          className="text-setting-dyp"
          children="DYP"
        />
      )}

      {setting.doubleIn && (
        <DartsSettingItem
          tooltip={!noTooltips && t`Double in`}
          className="text-setting-di"
          children="DI"
        />
      )}
    </div>
  )
}

interface DartsSettingItemProps {
  className?: string
  tooltip: false | string
  children: string
}

const DartsSettingItem = ({
  className = '',
  tooltip,
  ...props
}: DartsSettingItemProps) => {
  if (!props.children) {
    return null
  }

  if (!tooltip) {
    return (
      <div
        className={classNames(className, 'mr-1 inline font-bold')}
        {...props}
      />
    )
  }

  return (
    <Tooltip tooltip={tooltip}>
      <div
        className={classNames(className, 'mr-1 inline font-bold')}
        {...props}
      />
    </Tooltip>
  )
}

export default DartsSetting
