import React from 'react'

import {Trans} from '@lingui/macro'

import {Title} from '@/components/Title'

const PrivatePolicy = () => {
  return (
    <div className="container py-10">
      <Title>
        <Trans>Private policy</Trans>
      </Title>

      <div className="mb-6 block">
        <strong className="text-primary">
          Allgemeiner Hinweis und Pflichtinformationen
        </strong>
      </div>
      <div className="mb-6 block">
        <strong className="text-primary">
          Benennung der verantwortlichen Stelle
        </strong>
      </div>
      <div className="mb-6 block">
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </div>
      <div className="mb-6 block">
        <div id="s3-t-ansprechpartner">Daniel Kemna</div>
        <div id="s3-t-strasse">In den Herrenbenden 21-23</div>
        <div className="mr-1 inline-block" id="s3-t-plz">
          53879
        </div>
        <div className="inline-block" id="s3-t-ort">
          Euskirchen
        </div>
      </div>
      <p />
      <div className="mb-6 block">
        Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen
        über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
        (z.B. Namen, Kontaktdaten o. Ä.).
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </strong>
      </div>
      <div className="mb-6 block">
        Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
        Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
        Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
        formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf
        erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">
          Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
        </strong>
      </div>
      <div className="mb-6 block">
        Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
        Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist
        der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz
        unseres Unternehmens befindet. Der folgende Link stellt eine Liste der
        Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
        <a
          href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
          target="_blank"
        >
          BFDI Bund Kontaktdaten
        </a>
        .
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">Recht auf Datenübertragbarkeit</strong>
      </div>
      <div className="mb-6 block">
        Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
        Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten,
        an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt
        in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung
        der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
        soweit es technisch machbar ist.
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">
          Recht auf Auskunft, Berichtigung, Sperrung, Löschung
        </strong>
      </div>
      <div className="mb-6 block">
        Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den
        Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
        Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren
        Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über
        die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">SSL- bzw. TLS-Verschlüsselung</strong>
      </div>
      <div className="mb-6 block">
        Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
        Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website
        eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese
        Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine
        verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers
        und am Schloss-Symbol in der Browserzeile.
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">Server-Log-Dateien</strong>
      </div>
      <div className="mb-6 block">
        In Server-Log-Dateien erhebt und speichert der Provider der Website
        automatisch Informationen, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
      </div>
      <ul className="list-primary mb-6 pl-6">
        <li>Besuchte Seite auf unserer Domain</li>
        <li>Datum und Uhrzeit der Serveranfrage</li>
        <li>Browsertyp und Browserversion</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>IP-Adresse</li>
      </ul>
      <div className="mb-6 block">
        Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen
        statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b
        DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
        vorvertraglicher Maßnahmen gestattet.
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">
          Registrierung auf dieser Website
        </strong>
      </div>
      <div className="mb-6 block">
        Zur Nutzung bestimmter Funktionen können Sie sich auf unserer Website
        registrieren. Die übermittelten Daten dienen ausschließlich zum Zwecke
        der Nutzung des jeweiligen Angebotes oder Dienstes. Bei der
        Registrierung abgefragte Pflichtangaben sind vollständig anzugeben.
        Andernfalls werden wir die Registrierung ablehnen.
      </div>
      <div className="mb-6 block">
        Im Falle wichtiger Änderungen, etwa aus technischen Gründen, informieren
        wir Sie per E-Mail. Die E-Mail wird an die Adresse versendet, die bei
        der Registrierung angegeben wurde.
      </div>
      <div className="mb-6 block">
        Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
        auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein
        Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für
        den Widerruf genügt eine formlose Mitteilung per E-Mail. Die
        Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom
        Widerruf unberührt.
      </div>
      <div className="mb-6 block">
        Wir speichern die bei der Registrierung erfassten Daten während des
        Zeitraums, den Sie auf unserer Website registriert sind. Ihren Daten
        werden gelöscht, sollten Sie Ihre Registrierung aufheben. Gesetzliche
        Aufbewahrungsfristen bleiben unberührt.
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">Kontaktformular</strong>
      </div>
      <div className="mb-6 block">
        Per Kontaktformular übermittelte Daten werden einschließlich Ihrer
        Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um
        für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet
        ohne Ihre Einwilligung nicht statt.
      </div>
      <div className="mb-6 block">
        Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
        ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
        DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit
        möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail.
        Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
      </div>
      <div className="mb-6 block">
        Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie
        uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht.
        Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen -
        bleiben unberührt.
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">Newsletter-Daten</strong>
      </div>
      <div className="mb-6 block">
        Zum Versenden unseres Newsletters benötigen wir von Ihnen eine
        E-Mail-Adresse. Eine Verifizierung der angegebenen E-Mail-Adresse ist
        notwendig und der Empfang des Newsletters ist einzuwilligen. Ergänzende
        Daten werden nicht erhoben oder sind freiwillig. Die Verwendung der
        Daten erfolgt ausschließlich für den Versand des Newsletters.
      </div>
      <div className="mb-6 block">
        Die bei der Newsletteranmeldung gemachten Daten werden ausschließlich
        auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
        verarbeitet. Ein Widerruf Ihrer bereits erteilten Einwilligung ist
        jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per
        E-Mail oder Sie melden sich über den "Austragen"-Link im Newsletter ab.
        Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge
        bleibt vom Widerruf unberührt.
      </div>
      <div className="mb-6 block">
        Zur Einrichtung des Abonnements eingegebene Daten werden im Falle der
        Abmeldung gelöscht. Sollten diese Daten für andere Zwecke und an anderer
        Stelle an uns übermittelt worden sein, verbleiben diese weiterhin bei
        uns.
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">Cookies</strong>
      </div>
      <div className="mb-6 block">
        Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr
        Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei, unser
        Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
      </div>
      <div className="mb-6 block">
        Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende
        Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere
        Cookies auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen.
        Solche Cookies helfen uns, Sie bei Rückkehr auf unserer Website
        wiederzuerkennen.
      </div>
      <div className="mb-6 block">
        Mit einem modernen Webbrowser können Sie das Setzen von Cookies
        überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich
        so konfigurieren, dass Cookies mit dem Schließen des Programms von
        selbst gelöscht werden. Die Deaktivierung von Cookies kann eine
        eingeschränkte Funktionalität unserer Website zur Folge haben.
      </div>
      <div className="mb-6 block">
        Das Setzen von Cookies, die zur Ausübung elektronischer
        Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen
        erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf
        Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website
        haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur
        technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste.
        Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen)
        erfolgt, werden diese in dieser Datenschutzerklärung separat behandelt.
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">Google Analytics</strong>
      </div>
      <div className="mb-6 block">
        Unsere Website verwendet Funktionen des Webanalysedienstes Google
        Analytics. Anbieter des Webanalysedienstes ist die Google Inc., 1600
        Amphitheatre Parkway, Mountain View, CA 94043, USA.
      </div>
      <div className="mb-6 block">
        Google Analytics verwendet "Cookies." Das sind kleine Textdateien, die
        Ihr Webbrowser auf Ihrem Endgerät speichert und eine Analyse der
        Website-Benutzung ermöglichen. Mittels Cookie erzeugte Informationen
        über Ihre Benutzung unserer Website werden an einen Server von Google
        übermittelt und dort gespeichert. Server-Turnierort ist im Regelfall die
        USA.
      </div>
      <div className="mb-6 block">
        Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein
        berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser
        Webangebot und ggf. auch Werbung zu optimieren.
      </div>

      <div className="mb-6 block text-primary">IP-Anonymisierung</div>
      <div className="mb-6 block">
        Wir setzen Google Analytics in Verbindung mit der Funktion
        IP-Anonymisierung ein. Sie gewährleistet, dass Google Ihre IP-Adresse
        innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
        Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor
        der Übermittlung in die USA kürzt. Es kann Ausnahmefälle geben, in denen
        Google die volle IP-Adresse an einen Server in den USA überträgt und
        dort kürzt. In unserem Auftrag wird Google diese Informationen benutzen,
        um Ihre Nutzung der Website auszuwerten, um Reports über
        Websiteaktivitäten zu erstellen und um weitere mit der Websitenutzung
        und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu
        erbringen. Es findet keine Zusammenführung der von Google Analytics
        übermittelten IP-Adresse mit anderen Daten von Google statt.
      </div>

      <div className="mb-6 block text-primary">Browser Plugin</div>
      <div className="mb-6 block">
        Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige
        Funktionen unserer Website könnten dadurch jedoch eingeschränkt werden.
        Ebenso können Sie die Erfassung von Daten bezüglich Ihrer
        Website-Nutzung einschließlich Ihrer IP-Adresse mitsamt anschließender
        Verarbeitung durch Google unterbinden. Dies ist möglich, indem Sie das
        über folgenden Link erreichbare Browser-Plugin herunterladen und
        installieren:{' '}
        <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
          Browser plugin
        </a>
        .
      </div>

      <div className="mb-6 block text-primary">
        Widerspruch gegen die Datenerfassung
      </div>
      <div className="mb-6 block">
        Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern,
        indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie
        gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen unserer
        Website verhindert: Google Analytics deaktivieren.
      </div>

      <div className="mb-6 block">
        Einzelheiten zum Umgang mit Nutzerdaten bei Google Analytics finden Sie
        in der Datenschutzerklärung von Google:
        <a href="https://support.google.com/analytics/answer/6004245?hl=de">
          Google Analytics Datenschutzerklärung
        </a>
        .
      </div>

      <div className="mb-6 block text-primary">Auftragsverarbeitung</div>
      <div className="mb-6 block">
        Zur vollständigen Erfüllung der gesetzlichen Datenschutzvorgaben haben
        wir mit Google einen Vertrag über die Auftragsverarbeitung
        abgeschlossen.
      </div>

      <div className="mb-6 block text-primary">
        Demografische Merkmale bei Google Analytics
      </div>
      <div className="mb-6 block">
        Unsere Website verwendet die Funktion “demografische Merkmale” von
        Google Analytics. Mit ihr lassen sich Berichte erstellen, die Aussagen
        zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese
        Daten stammen aus interessenbezogener Werbung von Google sowie aus
        Besucherdaten von Drittanbietern. Eine Zuordnung der Daten zu einer
        bestimmten Person ist nicht möglich. Sie können diese Funktion jederzeit
        deaktivieren. Dies ist über die Anzeigeneinstellungen in Ihrem
        Google-Konto möglich oder indem Sie die Erfassung Ihrer Daten durch
        Google Analytics, wie im Punkt “Widerspruch gegen die Datenerfassung”
        erläutert, generell untersagen.
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">Google AdSense</strong>
      </div>
      <div className="mb-6 block">
        Unsere Website verwendet Google AdSense. Anbieter ist die Google Inc.,
        1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
      </div>
      <div className="mb-6 block">
        Google AdSense dient der Einbindung von Werbeanzeigen und setzt Cookies.
        Cookies sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät
        speichert, um die Nutzung der Website analysieren. Google AdSense setzt
        außerdem Web Beacons ein. Web Beacons sind unsichtbare Grafiken, die
        eine Analyse des Besucherverkehrs auf unserer Wesite ermöglichen.
      </div>
      <div className="mb-6 block">
        Durch Cookies und Web Beacons erzeugten Informationen werden an Server
        von Google übertragen und dort gespeichert. ServerTurnierort sind die
        USA. Google kann diese Informationen an Vertragspartner weiterreichen.
        Ihre IP-Adresse wird Google jedoch nicht mit anderen von Ihnen
        gespeicherten Daten zusammenführen.
      </div>
      <div className="mb-6 block">
        Die Speicherung von AdSense-Cookies erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO. Wir als Websitebetreiber haben ein berechtigtes
        Interesse an der Analyse des Nutzerverhaltens, um unser Webangebot und
        unsere Werbung zu optimieren.
      </div>
      <div className="mb-6 block">
        Mit einem modernen Webbrowser können Sie das Setzen von Cookies
        überwachen, einschränken und unterbinden. Die Deaktivierung von Cookies
        kann eine eingeschränkte Funktionalität unserer Website zur Folge haben.
        Durch die Nutzung unserer Website erklären Sie sich mit der Bearbeitung
        der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art
        und Weise sowie dem zuvor benannten Zweck einverstanden.
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">
          Google AdWords und Google Conversion-Tracking
        </strong>
      </div>
      <div className="mb-6 block">
        Unsere Website verwendet Google AdWords. Anbieter ist die Google Inc.,
        1600 Amphitheatre Parkway, Mountain View, CA 94043, United States.
      </div>
      <div className="mb-6 block">
        AdWords ist ein Online-Werbeprogramm. Im Rahmen des
        Online-Werbeprogramms arbeiten wir mit Conversion-Tracking. Nach einem
        Klick auf eine von Google geschaltete Anzeige wird ein Cookie für das
        Conversion-Tracking gesetzt. Cookies sind kleine Textdateien, die Ihr
        Webbrowser auf Ihrem Endgerät speichert. Google AdWords Cookies
        verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der
        persönlichen Identifizierung der Nutzer. Am Cookie können Google und wir
        erkennen, dass Sie auf eine Anzeige geklickt haben und zu unserer
        Website weitergeleitet wurden.
      </div>
      <div className="mb-6 block">
        Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies sind
        nicht über Websites von AdWords-Kunden nachverfolgbar. Mit
        Conversion-Cookies werden Conversion-Statistiken für AdWords-Kunden, die
        Conversion-Tracking einsetzen, erstellt. Adwords-Kunden erfahren wie
        viele Nutzer auf ihre Anzeige geklickt haben und auf Seiten mit
        Conversion-Tracking-Tag weitergeleitet wurden. AdWords-Kunden erhalten
        jedoch keine Informationen, die eine persönliche Identifikation der
        Nutzer ermöglichen. Wenn Sie nicht am Tracking teilnehmen möchten,
        können Sie einer Nutzung widersprechen. Hier ist das Conversion-Cookie
        in den Nutzereinstellungen des Browsers zu deaktivieren. So findet auch
        keine Aufnahme in die Conversion-Tracking Statistiken statt.
      </div>
      <div className="mb-6 block">
        Die Speicherung von “Conversion-Cookies” erfolgt auf Grundlage von Art.
        6 Abs. 1 lit. f DSGVO. Wir als Websitebetreiber haben ein berechtigtes
        Interesse an der Analyse des Nutzerverhaltens, um unser Webangebot und
        unsere Werbung zu optimieren.
      </div>
      <div className="mb-6 block">
        Einzelheiten zu Google AdWords und Google Conversion-Tracking finden Sie
        in den Datenschutzbestimmungen von Google:
        <a href="https://www.google.de/policies/privacy/">
          Google Datenschutzbestimmungen
        </a>
        .
      </div>
      <div className="mb-6 block">
        Mit einem modernen Webbrowser können Sie das Setzen von Cookies
        überwachen, einschränken oder unterbinden. Die Deaktivierung von Cookies
        kann eine eingeschränkte Funktionalität unserer Website zur Folge haben.
      </div>

      <div className="mb-6 block">
        <strong className="text-primary">Google Web Fonts</strong>
      </div>
      <div className="mb-6 block">
        Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google
        Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
      </div>
      <div className="mb-6 block">
        Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns
        gewünschte Darstellung unserer Website zu präsentieren, unabhängig davon
        welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt über den
        Abruf der Google Web Fonts von einem Server von Google in den USA und
        der damit verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es
        sich um Ihre IP-Adresse und welche Seite Sie bei uns besucht haben. Der
        Einsatz von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes
        Interesse an der optimalen Darstellung und Übertragung unseres
        Webauftritts.
      </div>
      <div className="mb-6 block">
        Das Unternehmen Google ist für das us-europäische
        Datenschutzübereinkommen "Privacy Shield" zertifiziert. Dieses
        Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden
        Datenschutzniveaus gewährleisten.
      </div>
      <div className="mb-6 block">
        Einzelheiten über Google Web Fonts finden Sie unter:{' '}
        <a href="https://www.google.com/fonts#AboutPlace:about">
          Google Web Fonts
        </a>{' '}
        und weitere Informationen in den Datenschutzbestimmungen von Google:{' '}
        <a href="https://policies.google.com/privacy/partners?hl=de">
          Google Datenschutzbestimmungen
        </a>
      </div>
      <div className="mb-6 block">
        <small>
          Quelle: Datenschutz-Konfigurator von{' '}
          <a href="http://www.mein-datenschutzbeauftragter.de" target="_blank">
            mein-datenschutzbeauftragter.de
          </a>
        </small>
      </div>
    </div>
  )
}

export default PrivatePolicy
