import React from 'react'

import Cards from '@/components/sections/Cards'
import EmailNewsletter from '@/components/sections/EmailNewsletter'
import Information from '@/components/sections/Information'
import Masthead from '@/components/sections/Masthead'
import NextGames from '@/components/sections/NextGames'

const Home = () => (
  <div>
    <Masthead />

    <Information />

    <NextGames />

    <EmailNewsletter />

    <Cards />
  </div>
)

export default Home
