import {formatISO, subDays} from 'date-fns'

import {SearchParamsProps} from '@/components/Table'
import {GameEventProps} from '@/components/games/GameCalendar'

import {client} from '@/lib/api-client'
import {GameModel} from '@/models/Game'

export const getNextGames = async () => {
  const gamesPayload = await client
    .get('games', {
      searchParams: {
        include:
          'tournament,tournament.location,tournament.creator,setting,leagues,priceList,priceList.prices',
        sort: 'deadline,start',
        'page[size]': 3,
        'filter[start]': formatISO(new Date()),
        'filter[canceled]': 0,
        'filter[tournament][isActive]': 1,
      },
    })
    .json()

  return GameModel.normalizePayload(gamesPayload)
}

export const getEventGames = async (gameEvents: GameEventProps) => {
  const searchParams = {
    include:
      'tournament,tournament.location,tournament.creator,setting,leagues',
    sort: 'start',
    'filter[start]': gameEvents.start,
    'filter[end]': gameEvents.end,
    'filter[setting][variant]': gameEvents.setting.variant.join(','),
    'filter[setting][size]': gameEvents.setting.size.join(','),
    'filter[setting][out]': gameEvents.setting.out.join(','),
    'filter[setting][value]': gameEvents.setting.value.join(','),
    'filter[tournament][isActive]': 1,
  }

  if (gameEvents.locationId) {
    searchParams['filter[tournament][locationId]'] = gameEvents.locationId
  }

  if (gameEvents.setting.mixed) {
    searchParams['filter[setting][mixed]'] = 1
  }

  if (gameEvents.setting.dyp) {
    searchParams['filter[setting][dyp]'] = 1
  }

  if (gameEvents.setting.doubleIn) {
    searchParams['filter[setting][doubleIn]'] = 1
  }

  if (gameEvents.radius) {
    searchParams[
      'filter[within]'
    ] = `${gameEvents.latitude},${gameEvents.longitude},${gameEvents.radius}`
  }

  const gamesPayload = await client
    .get('games', {
      searchParams,
    })
    .json()

  return GameModel.normalizePayload(gamesPayload)
}

export const getFavoriteGames = async (searchParams: SearchParamsProps) => {
  const gamesPayload = await client
    .get('games', {
      searchParams: {
        include:
          'tournament,tournament.location,tournament.creator,setting,leagues',
        'filter[favorite]': true,
        'filter[start]': formatISO(subDays(new Date(), 1)),
        ...searchParams,
      },
    })
    .json()

  return GameModel.normalizePayload(gamesPayload)
}

export const postFavorite = async (slug: string) => {
  await client.post(`games/${slug}/favorite`).json()
}

export const postCancel = async (slug: string) => {
  await client.post(`games/${slug}/cancel`).json()
}

export const getGame = async (slug: string) => {
  const gamePayload = await client
    .get(`games${slug}`, {
      searchParams: {
        include:
          'tournament,tournament.location,tournament.creator,tournament.games,tournament.games.setting,tournament.games.leagues,setting,leagues,priceList,priceList.prices,reports,tournament.location.reports',
      },
    })
    .json()

  return GameModel.normalizePayload(gamePayload)
}
