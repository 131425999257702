import {Model, ModelInterface} from '@/lib/model'

export interface PriceInterface extends ModelInterface {
  place: number
  price: string
}

export class PriceModel extends Model<PriceInterface> {
  place: number
  price: string

  public static KEY = 'prices'
}
