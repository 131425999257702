import React from 'react'

import {LocationModel} from '@/models/Location'

const removeInvalidParams = (string: string) =>
  string.replace('&', '').replace('?', '')

interface LocationMapProps {
  location: LocationModel
  height?: string
  width?: string
}

const LocationMap = ({
  location,
  height = '650px',
  width = '100%',
  ...props
}: LocationMapProps) => {
  const query = removeInvalidParams(
    (location.placeId ? location.name : location.street) + ',' + location.city
  )

  return (
    <iframe
      width={width}
      height={height}
      frameBorder="0"
      data-loading="true"
      src={`https://www.google.com/maps/embed/v1/place?key=${
        import.meta.env.VITE_GOOGLE_MAPS
      }&q=${query}`}
      allowFullScreen
      aria-label="Map"
      {...props}
    />
  )
}

export default LocationMap
