import React, {useState} from 'react'

import {faMapMarkerAlt, faSpinner} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {t} from '@lingui/macro'
import classNames from 'classnames'
import {useField, useFormikContext} from 'formik'

import useCurrentPosition from '@/hooks/useCurrentPosition'

import {FieldGroupInterface, FormControl} from '@/lib/form'

interface FieldCurrentPositionValues {
  name: string
  currentPosition: boolean
  latitude: number | undefined
  longitude: number | undefined
}

const FieldCurrentPosition = ({namePrefix = ''}: FieldGroupInterface) => {
  const {isSubmitting} = useFormikContext()
  const [field, meta] = useField(`${namePrefix}currentPosition`)
  const [isLoading, setLoading] = useState(false)
  const setCurrentPositionValues = useSetCurrentPositionValues(namePrefix)
  const getCurrentPosition = useCurrentPosition()
  const setCurrentLocation = async () => {
    setLoading(true)

    try {
      const position = await getCurrentPosition()
      setCurrentPositionValues({
        name: t`Current position`,
        currentPosition: true,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const resetCurrentLocation = () => {
    setCurrentPositionValues({
      name: '',
      currentPosition: false,
      latitude: undefined,
      longitude: undefined,
    })
  }

  return (
    <FormControl meta={meta}>
      <button
        type="button"
        className={classNames('btn btn-primary', field.value && 'active')}
        disabled={isSubmitting}
        onClick={() =>
          field.value ? resetCurrentLocation() : setCurrentLocation()
        }
        aria-label="Current position"
      >
        <FontAwesomeIcon
          icon={isLoading ? faSpinner : faMapMarkerAlt}
          spin={isLoading}
          fixedWidth
        />
      </button>
    </FormControl>
  )
}

const useSetCurrentPositionTouched = (prefixName: string) => {
  const {touched, getFieldMeta, setFieldTouched, setTouched} =
    useFormikContext<FieldCurrentPositionValues>()
  const updateTouched = {
    name: true,
    currentPosition: true,
    latitude: true,
    longitude: true,
  }

  return () => {
    if (prefixName) {
      const {touched}: {touched: any} = getFieldMeta(prefixName)

      setFieldTouched(prefixName, {
        ...touched,
        ...updateTouched,
      })
    }

    if (!prefixName) {
      setTouched({
        ...touched,
        ...updateTouched,
      })
    }
  }
}

const useSetCurrentPositionValues = (namePrefix: string) => {
  const {values, setValues, setFieldValue, getFieldProps} =
    useFormikContext<FieldCurrentPositionValues>()
  const prefixName = namePrefix.slice(0, -1)
  const setCurrentPositionTouched = useSetCurrentPositionTouched(prefixName)

  return (currentPositionValues: FieldCurrentPositionValues) => {
    if (prefixName) {
      const {value} = getFieldProps(prefixName)

      setFieldValue(prefixName, {
        ...value,
        ...currentPositionValues,
      })
    }

    if (!prefixName) {
      setValues({
        ...values,
        ...currentPositionValues,
      })
    }
    setCurrentPositionTouched()
  }
}

export default FieldCurrentPosition
