import React, {useMemo, useState} from 'react'
import {UseQueryResult} from 'react-query'

import {Trans, t} from '@lingui/macro'

import {prefetchUsers, useUsers} from '@/hooks/query/useUser'

import {AlertDanger} from '@/components/Alert'
import Table, {SearchParamsProps} from '@/components/Table'

import {ExternalLink} from '@/lib/router'
import {UserModel, userSearchParams} from '@/models/User'

interface UsersTableProps {
  useTableUsers?: (searchParams: SearchParamsProps) => UseQueryResult<any>
  prefetchTableUsers?: (searchParams: SearchParamsProps) => void
}

const GamesTable = ({
  useTableUsers = useUsers,
  prefetchTableUsers = prefetchUsers,
}: UsersTableProps) => {
  const [searchParams, setSearchParams] =
    useState<SearchParamsProps>(userSearchParams)
  const {data: usersData, ...userQuery} = useTableUsers(searchParams)
  const userColumns = useMemo(
    () => [
      {
        Header: t`Username`,
        accessor: 'username',
      },
      {
        id: 'email',
        Header: t`Email`,
        accessor: (user: UserModel) => (
          <ExternalLink to={`mailto:${user.email}`}>{user.email}</ExternalLink>
        ),
      },
      {
        id: 'emailVerifiedAt',
        Header: t`Verified at`,
        accessor: (user: UserModel) => user.formatEmailVerifiedAt(),
      },
      {
        id: 'roleId',
        Header: t`Role`,
        accessor: (user: UserModel) => {
          if (user.isAdmin()) {
            return <Trans>Admin</Trans>
          }

          return <Trans>User</Trans>
        },
        show: false,
      },
      {
        id: 'createdAt',
        Header: t`Added at`,
        accessor: (user: UserModel) => user.formatCreatedAt(),
        show: false,
      },
    ],
    []
  )

  if (userQuery.isError) {
    return <AlertDanger>{String(userQuery.error)}</AlertDanger>
  }

  const {
    data: users,
    meta: {page: pagination},
  } = usersData || {
    data: [],
    meta: {page: {}},
  }

  return (
    <Table
      placeholder={t`Users`}
      data={users}
      columns={userColumns}
      pagination={pagination}
      searchParams={searchParams}
      query={userQuery}
      onChange={setSearchParams}
      onPrefetch={prefetchTableUsers}
    />
  )
}

export default GamesTable
