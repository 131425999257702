import {SearchParamsProps} from '@/components/Table'

import {Model, ModelInterface} from '@/lib/model'
import {ReportModel} from '@/models/Report'
import {UserModel} from '@/models/User'

export interface LocationInterface extends ModelInterface {
  dbId: string
  name: string
  placeId: string | null
  country: string
  areaLevel: string
  postalCode: string
  street: string
  city: string
  latitude: number
  longitude: number
  website: string | null
  number: string | null
}

export class LocationModel extends Model<LocationInterface> {
  dbId!: string
  name!: string
  placeId!: string | null
  country!: string
  areaLevel!: string
  postalCode!: string
  street!: string
  city!: string
  latitude!: number
  longitude!: number
  website!: string | null
  number!: string | null

  public static KEY = 'locations'
  public static SELECT_KEY = 'select-locations'

  public static toSelectItems(normalizedLocationPayload: any) {
    const locations = normalizedLocationPayload.data

    return locations.map((location) => ({
      label: location.name,
      value: location.id,
      subLabel: `${location.postalCode} ${location.city}`,
    }))
  }

  get creator(): UserModel | null {
    return this.singleRelationship(UserModel, 'creator')
  }

  get report(): ReportModel | null {
    return this.reports?.length ? this.reports[0] : null
  }

  get reports(): ReportModel[] {
    return this.manyRelationship(ReportModel, 'reports')
  }

  set reports(reports: ReportModel[]) {
    this.relationships.reports = reports
  }

  public websiteHost(): string | null {
    if (!this.website) {
      return null
    }

    return new URL(this.website).host
  }

  public link(): string {
    return `/locations/${this.id}`
  }
}

export const locationSearchParams: SearchParamsProps = {
  'filter[search]': '',
  'page[size]': 25,
  'page[number]': 0,
  sort: '-createdAt',
}
