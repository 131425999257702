import React from 'react'

import {useField, useFormikContext} from 'formik'

import MarkdownEditor from '@/components/markdownEditor/MarkdownEditor'

import {FieldInterface, FormControl} from '@/lib/form'

interface FieldMarkdownEditorProps extends FieldInterface {
  className?: string
  placeholder: string
  disabled?: boolean
}

const FieldMarkdownEditor = ({
  name,
  disabled,
  ...props
}: FieldMarkdownEditorProps) => {
  const {isSubmitting} = useFormikContext()
  const [field, meta, helpers] = useField(name)

  return (
    <FormControl meta={meta}>
      <MarkdownEditor
        {...field}
        {...props}
        onBlur={() => helpers.setTouched(true)}
        onChange={helpers.setValue}
        invalid={
          (meta.initialTouched || meta.touched) &&
          (!!meta.error || !!meta.initialError)
        }
        valid={
          (meta.touched && !meta.error) ||
          (meta.initialTouched && !meta.initialError)
        }
        disabled={isSubmitting || disabled}
      />
    </FormControl>
  )
}

export default FieldMarkdownEditor
