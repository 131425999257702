import React from 'react'

import {FormikHelpers} from 'formik/dist/types'

import {SearchParamsProps} from '@/components/Table'
import {EmailNewsletterProps} from '@/components/forms/EmailNewsletterForm'

import {client, formClient} from '@/lib/api-client'
import {EmailNewsletterModel} from '@/models/EmailNewsletter'

export const deleteEmailNewsletter = async (
  id: string,
  email: string,
  token: string
) => {
  await client.delete(`email-newsletters/${id}`, {
    searchParams: {
      email,
      token,
    },
  })
}

export const postEmailNewsletter = async (
  {leagueIds, ...newsletterProps}: EmailNewsletterProps,
  formikHelpers: FormikHelpers<EmailNewsletterProps>
) => {
  const newsletterPayload = await formClient
    .post(formikHelpers, 'email-newsletters', {
      json: {
        data: {
          type: 'email-newsletters',
          attributes: newsletterProps,
          relationships: {
            leagues: {
              data: leagueIds.map((leagueId) => ({
                type: 'leagues',
                id: leagueId,
              })),
            },
          },
        },
      },
    })
    .json()

  return EmailNewsletterModel.normalizePayload(newsletterPayload)
}

export const patchEmailNewsletter = async (
  {id, leagueIds, ...newsletterProps}: EmailNewsletterProps,
  formikHelpers: FormikHelpers<EmailNewsletterProps>
) => {
  const newsletterPayload = await formClient
    .patch(formikHelpers, `email-newsletters/${id}`, {
      json: {
        data: {
          id,
          type: 'email-newsletters',
          attributes: newsletterProps,
          relationships: {
            leagues: {
              data: leagueIds.map((leagueId) => ({
                type: 'leagues',
                id: leagueId,
              })),
            },
          },
        },
      },
    })
    .json()

  return EmailNewsletterModel.normalizePayload(newsletterPayload)
}

export const getEmailNewsletters = async (searchParams: SearchParamsProps) => {
  const newslettersPayload = await client
    .get('email-newsletters', {
      searchParams: {
        include: 'leagues,creator',
        ...searchParams,
      },
    })
    .json()

  return EmailNewsletterModel.normalizePayload(newslettersPayload)
}
