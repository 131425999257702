import React from 'react'

import {Trans} from '@lingui/macro'

import {patchUser} from '@/api/users'

import {Title} from '@/components/Title'
import ProfileForm from '@/components/forms/ProfileForm'

import {updateUser} from '@/actions/user'
import {useAuth} from '@/lib/auth'

const MyProfile = () => {
  const {user} = useAuth()

  return (
    <div>
      <Title>
        <Trans>My profile</Trans>
      </Title>

      <ProfileForm
        user={user}
        onSubmit={async (values, formikHelpers) => {
          await patchUser(values, formikHelpers)
          updateUser(user, values)
          formikHelpers.setSubmitting(false)
        }}
      />
    </div>
  )
}

export default MyProfile
