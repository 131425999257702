import {UseQueryResult, useQuery} from 'react-query'

import ky from 'ky'

import {
  getEventGames,
  getFavoriteGames,
  getGame,
  getNextGames,
} from '@/api/games'

import {SearchParamsProps} from '@/components/Table'
import {GameEventProps} from '@/components/games/GameCalendar'

import {queryClient} from '@/lib/query'
import {GameModel, gameSearchParams} from '@/models/Game'

import HTTPError = ky.HTTPError

export const useFavoritesGames = (
  searchParams: SearchParamsProps
): UseQueryResult<GameModel[], HTTPError> =>
  useQuery(
    [GameModel.FAVORITE_KEY, searchParams],
    () => getFavoriteGames(searchParams),
    {
      select: (gamesPayload) => GameModel.fromNormalizedPayload(gamesPayload),
      keepPreviousData: true,
    }
  )

export const prefetchFavoriteGames = (
  searchParams: SearchParamsProps = gameSearchParams
) =>
  !queryClient.getQueryData([GameModel.FAVORITE_KEY, searchParams]) &&
  queryClient.prefetchQuery([GameModel.FAVORITE_KEY, searchParams], () =>
    getFavoriteGames(searchParams)
  )

export const useNextGames = (): UseQueryResult<GameModel[], HTTPError> =>
  useQuery(GameModel.NEXT_KEY, getNextGames, {
    select: (gamesPayload) =>
      GameModel.fromNormalizedPayload(gamesPayload).data,
  })

export const prefetchNextGames = () =>
  !queryClient.getQueryData(GameModel.NEXT_KEY) &&
  queryClient.prefetchQuery(GameModel.NEXT_KEY, getNextGames)

export const useEventGames = (
  eventGamesProps: GameEventProps
): UseQueryResult<GameModel[], HTTPError> =>
  useQuery(
    [GameModel.EVENT_KEY, eventGamesProps],
    () => getEventGames(eventGamesProps),
    {
      select: (gamesPayload) =>
        GameModel.fromNormalizedPayload(gamesPayload).data,
    }
  )

export const prefetchEventGames = (eventGamesProps: GameEventProps) =>
  !queryClient.getQueryData([GameModel.EVENT_KEY, eventGamesProps]) &&
  queryClient.prefetchQuery([GameModel.EVENT_KEY, eventGamesProps], () =>
    getEventGames(eventGamesProps)
  )

export const useGame = (slug: string): UseQueryResult<GameModel, HTTPError> =>
  useQuery([GameModel.KEY, slug], () => getGame(slug), {
    select: (gamePayload) => GameModel.fromNormalizedPayload(gamePayload).data,
  })

export const prefetchGame = (slug: string) =>
  !queryClient.getQueryData([GameModel.KEY, slug]) &&
  queryClient.prefetchQuery([GameModel.KEY, slug], () => getGame(slug))
