import React from 'react'

import {Trans, t} from '@lingui/macro'

import {postPriceList} from '@/api/price-lists'

import FieldInput from '@/components/fields/FieldInput'
import FieldPrices from '@/components/fields/FieldPrices'
import FieldSubmitButton from '@/components/fields/FieldSubmitButton'

import {createPriceList} from '@/actions/price-list'
import Form from '@/lib/form'
import Modal, {ModalProps} from '@/lib/modal'
import Yup from '@/lib/yup'
import {PriceListModel} from '@/models/PriceList'

export interface PriceListProps {
  name: string
  priceNames: string[]
}

const priceListInitValues: PriceListProps = {
  name: '',
  priceNames: [],
}

interface CreateLocationModalProps extends ModalProps {
  onSuccess?: (priceList: PriceListModel) => void
}

const PriceListModalForm = ({
  onSuccess = () => null,
  ...props
}: CreateLocationModalProps) => {
  const priceListValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required()
      .label(t`Price list name`),
    priceNames: Yup.array()
      .of(
        Yup.string()
          .required()
          .label(t`Price`)
      )
      .required()
      .min(1)
      .label(t`Prices`),
  })

  return (
    <Form
      initialValues={priceListInitValues}
      validationSchema={priceListValidationSchema}
      onSubmit={async (values, formikHelpers) => {
        const priceListPayload = await postPriceList(values, formikHelpers)
        const priceList =
          PriceListModel.fromNormalizedPayload(priceListPayload).data
        createPriceList(priceList)
        onSuccess(priceList)
        props.onHide()
      }}
    >
      {() => (
        <Modal
          {...props}
          title={t`Create price list`}
          footer={
            <FieldSubmitButton>
              <Trans>Create price list</Trans>
            </FieldSubmitButton>
          }
        >
          <FieldInput
            name="name"
            placeholder={t`Price list name`}
            required
            autoFocus
          />

          <FieldPrices name="priceNames" required />
        </Modal>
      )}
    </Form>
  )
}

export default PriceListModalForm
