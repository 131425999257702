import React, {useState} from 'react'

import {t} from '@lingui/macro'
import {useFormikContext} from 'formik'

import {useSelectPriceLists} from '@/hooks/query/usePriceList'

import {TrophyPlus} from '@/components/Icons'
import FieldSelect from '@/components/fields/FieldSelect'
import PriceListModalForm from '@/components/forms/PriceListModalForm'

import {FieldInterface} from '@/lib/form'
import {queryClient} from '@/lib/query'
import {PriceListModel} from '@/models/PriceList'

interface FieldPriceListProps extends FieldInterface {
  required?: boolean
}

const FieldPriceListSelect = ({placeholder, ...props}: FieldPriceListProps) => {
  const {setFieldValue} = useFormikContext()
  const [showPriceListModal, setShowPriceListModal] = useState(false)
  const {data: priceLists, isLoading, error} = useSelectPriceLists()

  return (
    <div className="flex space-x-5">
      <div className="w-full">
        <FieldSelect
          items={priceLists || []}
          placeholder={placeholder || t`price list`}
          error={error && String(error)}
          loading={isLoading}
          {...props}
        />
      </div>

      <div>
        <PriceListModalForm
          open={showPriceListModal}
          onHide={() => setShowPriceListModal(false)}
          onSuccess={async (priceList) => {
            setFieldValue(props.name, priceList.id)
            await queryClient.invalidateQueries(PriceListModel.SELECT_KEY)
          }}
        />

        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setShowPriceListModal(true)}
        >
          <TrophyPlus />
        </button>
      </div>
    </div>
  )
}

export default FieldPriceListSelect
