import {SearchParamsProps} from '@/components/Table'

import {queryClient} from '@/lib/query'
import {GameModel} from '@/models/Game'
import {LocationModel} from '@/models/Location'
import {ReportModel} from '@/models/Report'
import {TournamentModel} from '@/models/Tournament'
import {
  successActivateGameToast,
  successAddFavoriteToast,
  successCanceledGameToast,
  successRemoveFavoriteToast,
} from '@/toasts/game'
import {
  successReportedGameToast,
  successReportedLocationToast,
} from '@/toasts/report'

export const toggleGameFavorite = (game: GameModel) => {
  queryClient.setQueryData([GameModel.KEY, game.slug()], (gamePayload: any) =>
    gamePayload
      ? {
          data: {
            ...gamePayload.data,
            favorite: !game.favorite,
          },
        }
      : null
  )
  !game.favorite
    ? successAddFavoriteToast(game)
    : successRemoveFavoriteToast(game)
}

export const toggleGameCanceled = async (
  game: GameModel,
  searchParams: SearchParamsProps
) => {
  await queryClient.invalidateQueries([TournamentModel.KEY, searchParams])
  await queryClient.invalidateQueries([TournamentModel.MY_KEY, searchParams])

  game.canceled
    ? successActivateGameToast(game)
    : successCanceledGameToast(game)
}

export const reportGame = (game: GameModel, report: ReportModel) => {
  game.reports = [report]
  successReportedGameToast(game)
}

export const reportLocation = (
  location: LocationModel,
  report: ReportModel
) => {
  location.reports = [report]
  successReportedLocationToast(location)
}
