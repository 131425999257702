import {useEffect} from 'react'

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {BLUR_COMMAND, COMMAND_PRIORITY_LOW} from 'lexical'

interface OnBlurPluginProps {
  onBlur?: () => void
}

const OnBlurPlugin = ({onBlur}: OnBlurPluginProps) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    editor.registerCommand(
      BLUR_COMMAND,
      () => {
        onBlur?.()
        return false
      },
      COMMAND_PRIORITY_LOW
    )
  }, [])

  return null
}

export default OnBlurPlugin
