import React from 'react'

import {Trans} from '@lingui/macro'

import {Title} from '@/components/Title'
import TournamentsTable from '@/components/tabels/TournamentsTable'

const MyTournaments = () => {
  return (
    <div>
      <Title>
        <Trans>My tournaments</Trans>
      </Title>

      <TournamentsTable />
    </div>
  )
}

export default MyTournaments
