import React, {useMemo, useState} from 'react'

import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {t} from '@lingui/macro'
import classNames from 'classnames'
import {useField, useFormikContext} from 'formik'
import {useUpdateEffect, useWindowSize} from 'react-use'

import List from '@/components/List'
import Loader from '@/components/Loader'
import {Input} from '@/components/fields/FieldInput'

import {FieldInterface, FormControl} from '@/lib/form'
import {PopperContainer} from '@/lib/popper'

interface GlobalSelectProps<Value = any> {
  name: string
  items: {label: string | JSX.Element; subLabel?: string; value: Value}[]
  placeholder: string
  multi?: boolean
  disabled?: boolean
  required?: boolean
  invalid?: boolean
  valid?: boolean
  error?: any
  loading?: boolean
  actions?: (
    onChange: (value: Value) => void,
    filteredItems: {
      label: string | JSX.Element
      subLabel?: string
      value?: Value
    }[]
  ) => JSX.Element
}

interface SelectProps<Value = any> extends GlobalSelectProps {
  value?: Value
  onChange?: (value: Value) => void
  onBlur?: () => void
}

export const Select = ({
  name,
  value,
  items,
  placeholder,
  onChange = () => null,
  onBlur = () => null,
  multi = false,
  error,
  actions,
  ...props
}: SelectProps) => {
  const getSelectedItems = () =>
    multi
      ? items.filter((item) => (value || []).includes(item.value))
      : items.find((item) => item.value === value)
  const [selectedValue, setSelectedValue] = useState<any>(getSelectedItems())
  const [isFocused, setFocused] = useState(false)
  const [filteredItems, setFilteredItems] = useState(items)
  const [search, setSearch] = useState('')
  const [multiSelectPadding, setMultiSelectPadding] = useState(0)
  const {width} = useWindowSize()
  const isMobile = width <= 1024
  const openSelect = () => !props.disabled && setFocused(true)

  useUpdateEffect(() => {
    setFilteredItems(items)
    setSelectedValue(getSelectedItems())
  }, [items])

  useUpdateEffect(() => {
    setSelectedValue(getSelectedItems())
  }, [value])

  const updateValue = (newValue: any) => {
    if (!multi) {
      onChange(newValue.value)
      setSelectedValue(newValue)
      setFocused(false)
      return
    }

    if (Array.isArray(newValue)) {
      onChange(newValue.map((value) => value.value))
      setSelectedValue([...newValue])
      return
    }

    const index = selectedValue.indexOf(newValue)
    let newValues = [...selectedValue]

    if (index > -1) {
      newValues.splice(index, 1)
    }

    if (index === -1) {
      newValues.push(newValue)
    }

    onChange(newValues.map((value: any) => value.value))
    setSelectedValue(newValues)
  }

  return (
    <PopperContainer
      hidden={!isFocused}
      onOutsideClick={() => {
        setFocused(false)
        onBlur()
      }}
      refChildren={({setRefElement}) => (
        <div ref={setRefElement} className="relative">
          <Input
            value={
              isFocused
                ? search
                : !multi && selectedValue
                ? selectedValue.label
                : ''
            }
            icon={
              <FontAwesomeIcon
                className="mr-1 text-sm text-gray"
                icon={isFocused ? faChevronUp : faChevronDown}
              />
            }
            placeholder={
              isFocused
                ? t`Search ${placeholder}`
                : multi && !!selectedValue.length
                ? ''
                : t`Select ${placeholder}`
            }
            onMouseDown={(event) => {
              if (isMobile && !isFocused) {
                event.preventDefault()
              }
              openSelect()
            }}
            onChange={(event) => {
              const search = event.target.value
              setSearch(search)
              setFilteredItems(
                items.filter((item) => {
                  const label = String(item.label).toLocaleLowerCase()
                  const value = String(item.value).toLocaleLowerCase()

                  return (
                    label.includes(search.toLocaleLowerCase()) ||
                    value.includes(search.toLocaleLowerCase())
                  )
                })
              )
            }}
            onIconContainerWidth={setMultiSelectPadding}
            aria-haspopup="menu"
            autoComplete="off"
            error={!!error}
            {...props}
          />

          {multi && !isFocused && !!selectedValue.length && (
            <div
              className={classNames(
                'absolute left-4 bottom-4 top-4 space-x-2 overflow-hidden whitespace-nowrap p-1 text-left',
                !props.disabled && 'cursor-text'
              )}
              onClick={openSelect}
              style={{
                right: `calc(${multiSelectPadding}px + 1rem)`,
              }}
            >
              {selectedValue.map(
                (currentValue: {label: string; value: any}) => (
                  <button
                    type="button"
                    className="btn-sm btn-primary"
                    key={currentValue.value}
                    onClick={(event) => {
                      event.stopPropagation()
                      updateValue(currentValue)
                    }}
                    disabled={props.disabled}
                  >
                    {currentValue.label}
                  </button>
                )
              )}
            </div>
          )}
        </div>
      )}
    >
      <SelectMenu
        currentValue={selectedValue}
        filteredItems={filteredItems}
        placeholder={placeholder}
        search={search}
        onChange={updateValue}
        isEmpty={!items.length}
        loading={props.loading}
        actions={actions}
        error={error}
      />
    </PopperContainer>
  )
}

interface SelectMenuProps<Value = any> {
  currentValue: any
  filteredItems: {
    label: string | JSX.Element
    subLabel?: string
    value?: Value
  }[]
  placeholder: string
  search: string
  onChange: (value: Value) => void
  isEmpty: boolean
  loading?: boolean
  error?: string
  actions?: (
    onChange: (value: Value) => void,
    filteredItems: {
      label: string | JSX.Element
      subLabel?: string
      value?: Value
    }[]
  ) => JSX.Element
}

const SelectMenu = ({
  currentValue,
  filteredItems,
  placeholder,
  search,
  isEmpty,
  loading,
  error,
  onChange,
  actions,
}: SelectMenuProps) => {
  const items = useMemo(
    () =>
      actions
        ? [
            {
              label: actions(onChange, filteredItems),
            },
            ...filteredItems,
          ]
        : filteredItems,
    [actions, filteredItems]
  )

  if (error) {
    return (
      <div className="rounded border border-gray-light bg-white text-dark dark:bg-dark dark:text-white">
        <div className="p-4 text-center">{error}</div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="rounded border border-gray-light bg-white text-dark dark:bg-dark dark:text-white">
        <div className="p-4 text-center">
          <Loader />
        </div>
      </div>
    )
  }

  if (isEmpty) {
    return (
      <div className="rounded border border-gray-light bg-white text-dark dark:bg-dark dark:text-white">
        <div className="p-4 text-center">{t`No ${placeholder} defined`}</div>
      </div>
    )
  }

  if (!filteredItems.length && search) {
    return (
      <div className="rounded border border-gray-light bg-white text-dark dark:bg-dark dark:text-white">
        <div className="p-4 text-center">{t`No ${placeholder} found for „${search}“`}</div>
      </div>
    )
  }

  return (
    <List
      className="max-h-[28.6rem] overflow-y-auto"
      items={items}
      isActive={(filteredItem) =>
        Array.isArray(currentValue)
          ? currentValue.includes(filteredItem)
          : filteredItem === currentValue
      }
      onItemClick={(filteredItem) =>
        typeof filteredItem.value !== 'undefined' && onChange(filteredItem)
      }
      accessor={(filteredItem) => (
        <>
          {filteredItem.label}{' '}
          <small className="text-xs">{filteredItem.subLabel}</small>
        </>
      )}
    />
  )
}

declare type FliedSelectProps = GlobalSelectProps & FieldInterface

const FieldSelect = ({name, disabled, ...props}: FliedSelectProps) => {
  const [field, meta, helper] = useField(name)
  const {isSubmitting} = useFormikContext()

  return (
    <FormControl meta={meta}>
      <Select
        {...field}
        {...props}
        invalid={
          (meta.initialTouched || meta.touched) &&
          (!!meta.error || !!meta.initialError)
        }
        valid={
          (meta.touched && !meta.error) ||
          (meta.initialTouched && !meta.initialError)
        }
        disabled={disabled || isSubmitting}
        onChange={(item) => helper.setValue(item)}
        onBlur={() => helper.setTouched(true)}
      />
    </FormControl>
  )
}

export default FieldSelect
