import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import ReactGA from 'react-ga'

interface ProviderWrapperProps {
  children: JSX.Element
}

const GaWrapper = ({children}: ProviderWrapperProps) => {
  const {pathname, search} = useLocation()

  useEffect(() => {
    ReactGA.pageview(pathname + search)
  }, [pathname, search])

  return children
}

export default GaWrapper
