import React, {useRef, useState} from 'react'

import {
  faChevronDown,
  faChevronUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans, t} from '@lingui/macro'
import classNames from 'classnames'
import {addHours} from 'date-fns'
import {useFormikContext} from 'formik'
import {useUpdateEffect} from 'react-use'

import {useExistsPredictionTournaments} from '@/hooks/query/useTournament'

import Collapse from '@/components/Collapse'
import DartsSetting from '@/components/DartsSetting'
import {CloseIcon} from '@/components/Icons'
import List from '@/components/List'
import FieldBuyIn from '@/components/fields/FieldBuyIn'
import FieldDate from '@/components/fields/FieldDate'
import FieldFile from '@/components/fields/FieldFile'
import FieldGroupSetting from '@/components/fields/FieldGroupSetting'
import FieldInput from '@/components/fields/FieldInput'
import FieldLeaguesSelect from '@/components/fields/FieldLeaguesSelect'
import FieldLocationSelect from '@/components/fields/FieldLocationSelect'
import FieldMarkdownEditor from '@/components/fields/FieldMarkdownEditor'
import FieldPriceListSelect from '@/components/fields/FieldPriceListSelect'
import {FieldRequiredSubmitButton} from '@/components/fields/FieldSubmitButton'

import {intlFormatDateTime} from '@/lib/date'
import Form, {CustomFormInterface} from '@/lib/form'
import Yup from '@/lib/yup'
import {SettingInterface} from '@/models/Setting'

export interface TournamentProps {
  tournamentId?: string
  name: string
  locationId?: string
  starts: Date[]
  deadline?: string
  flyer?: Blob
  description?: string
  imageSlug?: string
  tournamentGames: GameProps[]
}

export interface GameProps {
  setting: SettingInterface
  leagueIds: string[]
  buyIn?: number
  priceListId?: string
}

const tournamentInitValues: TournamentProps = {
  tournamentId: '',
  name: '',
  locationId: undefined,
  starts: [],
  deadline: undefined,
  flyer: undefined,
  description: undefined,
  tournamentGames: [],
}

interface TournamentFormProps extends CustomFormInterface<TournamentProps> {
  initialValues?: TournamentProps
}

const TournamentForm = ({initialValues, ...props}: TournamentFormProps) => {
  const tournamentSchema = Yup.object().shape({
    name: Yup.string()
      .required()
      .min(3)
      .max(191)
      .label(t`Tournament name`),
    locationId: Yup.string()
      .required()
      .label(t`Location`),
    starts: Yup.array()
      .required()
      .min(1)
      .of(
        Yup.date()
          .min(
            addHours(new Date(), 1),
            ({min}) =>
              t`Date needs to be before ${intlFormatDateTime(new Date(min))}`
          )
          .label(t`Tournament start`)
      )
      .label(t`Tournament starts`),
    deadline: Yup.number()
      .nullable()
      .min(0)
      .integer()
      .label(t`Tournament deadline`),
    flyer: Yup.file().label(t`Tournament flyer`),
    description: Yup.string()
      .nullable()
      .min(3)
      .label(t`Tournament description`),
    tournamentGames: Yup.array()
      .required()
      .min(1)
      .of(
        Yup.object()
          .required()
          .shape({
            setting: Yup.singleSetting(),
            leagueIds: Yup.array()
              .required()
              .min(1)
              .of(Yup.string())
              .label(t`Leagues`),
            buyIn: Yup.number()
              .integer()
              .min(0)
              .label(t`Buy in`),
            priceListId: Yup.string().label(t`Prices`),
          })
      ),
  })

  return (
    <Form
      initialValues={initialValues || tournamentInitValues}
      validationSchema={tournamentSchema}
      {...props}
    >
      {({handleSubmit, values, setFieldValue}) => {
        const {data: existsPredictions} = useExistsPredictionTournaments(
          values.name,
          `/${values.locationId}`,
          !!(values.name && values.locationId && !initialValues)
        )

        useUpdateEffect(() => {
          if (values.starts.length > values.tournamentGames.length) {
            setFieldValue('tournamentGames', [
              ...values.tournamentGames,
              {
                setting: {
                  type: '',
                  value: '',
                  size: '',
                  out: '',
                  mixed: false,
                  dyp: false,
                  doubleIn: false,
                },
                leagueIds: [],
                buyIn: undefined,
                priceListId: undefined,
              },
            ])
          }
        }, [values.starts])

        return (
          <form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <div className="flex space-x-5">
              <FieldInput
                name="name"
                placeholder={t`Tournament name`}
                required
                disabled={!!values.tournamentId || !!initialValues}
              />

              {!!values.tournamentId && !initialValues && (
                <div>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setFieldValue('tournamentId', null)}
                  >
                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                  </button>
                </div>
              )}
            </div>

            <FieldLocationSelect
              disabled={!!values.tournamentId || !!initialValues}
              required
            />

            {!values.tournamentId && !!existsPredictions?.length && (
              <div>
                <div className="mb-3 text-center text-xl text-primary">
                  <Trans>Append to tournament</Trans>
                </div>

                <List
                  className="mb-7"
                  items={existsPredictions}
                  accessor="name"
                  onItemClick={(tournament) => {
                    setFieldValue('name', tournament.name)
                    setFieldValue('tournamentId', `/${tournament.id}`)
                  }}
                />
              </div>
            )}

            <FieldDate
              name="starts"
              placeholder={t`Tournament starts`}
              disabledBefore={addHours(new Date(), 1)}
              disableDelete={!!initialValues}
              disableAdd={!!initialValues}
              required
            />

            <FieldInput
              type="number"
              name="deadline"
              placeholder={t`Tournament deadline (in minutes before start)`}
              disabled={!!initialValues}
            />

            <FieldFile
              name="flyer"
              accept="image/*"
              hasValue={!!(values.flyer || values.imageSlug)}
              onChange={() => setFieldValue('imageSlug', undefined)}
              onRemove={() => {
                setFieldValue('imageSlug', undefined)
                setFieldValue('flyer', undefined)
              }}
              disabled={!!initialValues}
            />

            {(!!values.flyer || !!values.imageSlug) && (
              <img
                className="mx-auto mb-7 block"
                src={
                  values.flyer
                    ? URL.createObjectURL(values.flyer)
                    : values.imageSlug
                }
                alt={values.name}
              />
            )}

            <FieldMarkdownEditor
              name="description"
              placeholder={t`Tournament description`}
              disabled={!!values.tournamentId}
            />

            <div className="space-y-8">
              {values.starts.map((start: Date, index: number) => (
                <GameForm
                  key={index}
                  namePrefix={`tournamentGames.${index}.`}
                  game={values.tournamentGames[index]}
                  start={start}
                  onClose={() => {
                    setFieldValue(
                      'starts',
                      values.starts.filter(
                        (start: Date, startIndex: number) =>
                          startIndex !== index
                      )
                    )
                    setFieldValue(
                      'tournamentGames',
                      values.tournamentGames.filter(
                        (game: GameProps, startIndex: number) =>
                          startIndex !== index
                      )
                    )
                  }}
                  initialOpen={!initialValues}
                  isUpdate={!!initialValues}
                />
              ))}

              <FieldRequiredSubmitButton>
                {!!initialValues ? (
                  <Trans>Update tournament</Trans>
                ) : (
                  <Trans>Create tournament</Trans>
                )}
              </FieldRequiredSubmitButton>
            </div>
          </form>
        )
      }}
    </Form>
  )
}

interface GameFormProps {
  namePrefix: string
  game: GameProps
  start: Date
  onClose: () => void
  initialOpen: boolean
  isUpdate: boolean
}

const GameForm = ({
  namePrefix,
  game,
  start,
  onClose,
  initialOpen = true,
  isUpdate,
}: GameFormProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const {isSubmitting, touched, getFieldMeta} = useFormikContext()
  const [showGame, setShowGame] = useState(initialOpen)
  const gameName = namePrefix.slice(0, -1)
  const {error: gameErrors = {}} = getFieldMeta(gameName)
  const gameTouchedKeys = Object.keys(
    gameName.split('.').reduce((o, i) => o[i] || {}, touched)
  )
  const gameErrorKeys = Object.keys(gameErrors)
  const hasError = gameTouchedKeys.some((gameTouchedKey) =>
    gameErrorKeys.includes(gameTouchedKey)
  )

  return (
    <div
      ref={ref}
      className={classNames(
        'relative rounded border bg-white p-4 dark:bg-dark',
        hasError ? 'border-danger' : 'border-gray'
      )}
    >
      <div className="p-1">
        {!isUpdate && (
          <CloseIcon
            className="absolute top-4 right-4"
            onClick={onClose}
            disabled={isSubmitting}
          />
        )}

        <button
          type="button"
          className="btn-sm btn-gray"
          onClick={() => setShowGame(!showGame)}
        >
          <FontAwesomeIcon icon={showGame ? faChevronUp : faChevronDown} />
        </button>

        <div className="h-18 mb-10 w-full border-b border-primary text-center md:h-6 ">
          <div className="mt-2 inline-block bg-white px-3 text-xl text-primary dark:bg-dark">
            <div className="flex flex-wrap">
              <div className="w-full md:w-auto">
                <DartsSetting setting={game?.setting || {}} />
              </div>

              <div className="w-full md:w-auto">
                {intlFormatDateTime(start)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Collapse visibleClassName="p-1" open={showGame}>
        <FieldGroupSetting
          namePrefix={`${namePrefix}setting.`}
          disabled={isUpdate}
        />

        <FieldLeaguesSelect namePrefix={namePrefix} disabled={isUpdate} />

        <FieldPriceListSelect name={`${namePrefix}priceListId`} />

        <FieldBuyIn
          name={`${namePrefix}buyIn`}
          placeholder={t`Buy in per Person`}
        />
      </Collapse>
    </div>
  )
}

export default TournamentForm
