import {FormikHelpers} from 'formik/dist/types'

import {LoginProps} from '@/components/forms/LoginForm'
import {RegistrationProps} from '@/components/forms/RegistrationForm'

import {client, formClient} from '@/lib/api-client'
import {Model} from '@/lib/model'

export const postLogin = async (
  email: string,
  password: string,
  formikHelpers: FormikHelpers<LoginProps>
) => {
  const tokenPayload = await formClient
    .post(formikHelpers, 'users/login', {
      json: {
        data: {
          type: 'users',
          attributes: {
            email,
            password,
          },
        },
      },
    })
    .json()

  return Model.normalizePayload(tokenPayload)
}

export const postLogout = async () => await client.post('users/logout').json()

export const postRegister = async (
  registrationProps: RegistrationProps,
  formikHelpers: FormikHelpers<RegistrationProps>
) => {
  const tokenPayload = await formClient
    .post(formikHelpers, 'users', {
      json: {
        data: {
          type: 'users',
          attributes: registrationProps,
        },
      },
    })
    .json()

  return Model.normalizePayload(tokenPayload)
}
