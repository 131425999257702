import React, {useMemo, useState} from 'react'
import {UseQueryResult} from 'react-query'

import {t} from '@lingui/macro'

import {prefetchLocations, useLocations} from '@/hooks/query/useLocation'

import {AlertDanger} from '@/components/Alert'
import Table, {SearchParamsProps} from '@/components/Table'

import {ExternalLink, LocalizedLink} from '@/lib/router'
import {LocationModel, locationSearchParams} from '@/models/Location'

interface LocationsTableProps {
  useTableLocations?: (searchParams: SearchParamsProps) => UseQueryResult<any>
}

const LocationsTable = ({
  useTableLocations = useLocations,
}: LocationsTableProps) => {
  const [searchParams, setSearchParams] =
    useState<SearchParamsProps>(locationSearchParams)
  const {data: locationsData, ...locationQuery} =
    useTableLocations(searchParams)
  const locationColumns = useMemo(
    () => [
      {
        id: 'name',
        Header: t`Tournament location`,
        accessor: (location: LocationModel) => (
          <LocalizedLink to={location.link()}>{location.name}</LocalizedLink>
        ),
      },
      {
        id: 'city',
        Header: t`City`,
        accessor: (location: LocationModel) =>
          `${location.postalCode} ${location.city}`,
      },
      {
        Header: t`Street`,
        accessor: 'street',
      },
      {
        Header: t`Area level`,
        accessor: 'areaLevel',
        show: false,
      },
      {
        Header: t`Country`,
        accessor: 'country',
        show: false,
      },
      {
        id: 'number',
        Header: t`Number`,
        accessor: (location: LocationModel) =>
          location.number ? (
            <ExternalLink to={`tel:${location.number}`}>
              {location.number}
            </ExternalLink>
          ) : (
            '-'
          ),
        show: false,
      },
      {
        id: 'website',
        Header: t`Website`,
        accessor: (location: LocationModel) =>
          location.website ? (
            <ExternalLink to={location.website}>
              {location.websiteHost()}
            </ExternalLink>
          ) : (
            '-'
          ),
        show: false,
      },
      {
        id: 'createdAt',
        Header: t`Added at`,
        accessor: (location: LocationModel) => location.formatCreatedAt(),
        show: false,
      },
      {
        id: 'creator.username',
        Header: t`Creator`,
        accessor: 'creator.username',
        show: false,
        sort: false,
      },
    ],
    []
  )

  if (locationQuery.isError) {
    return <AlertDanger>{String(locationQuery.error)}</AlertDanger>
  }

  const {
    data: locations,
    meta: {page: pagination},
  } = locationsData || {
    data: [],
    meta: {page: {}},
  }

  return (
    <Table
      placeholder={t`Tournament locations`}
      data={locations}
      columns={locationColumns}
      pagination={pagination}
      searchParams={searchParams}
      query={locationQuery}
      onChange={setSearchParams}
      onPrefetch={prefetchLocations}
    />
  )
}

export default LocationsTable
