import React from 'react'

import {FormikHelpers} from 'formik/dist/types'

import {CalendarFilterProps} from '@/components/forms/CalendarFilterForm'

import {formClient} from '@/lib/api-client'
import {CalendarFilterModel} from '@/models/CalendarFilter'

export const postCalendarFilter = async (
  {leagueIds, ...calendarFilterProps}: CalendarFilterProps,
  formikHelpers: FormikHelpers<CalendarFilterProps>
) => {
  const calendarFilterPayload = await formClient
    .post(formikHelpers, 'calendar-filters', {
      json: {
        data: {
          type: 'calendar-filters',
          attributes: calendarFilterProps,
          relationships: {
            leagues: {
              data: leagueIds.map((leagueId) => ({
                type: 'leagues',
                id: leagueId,
              })),
            },
          },
        },
      },
    })
    .json()

  return CalendarFilterModel.normalizePayload(calendarFilterPayload)
}

export const patchCalendarFilter = async (
  {id, leagueIds, ...calendarFilterProps}: CalendarFilterProps,
  formikHelpers: FormikHelpers<CalendarFilterProps>
) => {
  const calendarFilterPayload = await formClient
    .patch(formikHelpers, `calendar-filters/${id}`, {
      json: {
        data: {
          id,
          type: 'calendar-filters',
          attributes: calendarFilterProps,
          relationships: {
            leagues: {
              data: leagueIds.map((leagueId) => ({
                type: 'leagues',
                id: leagueId,
              })),
            },
          },
        },
      },
    })
    .json()

  return CalendarFilterModel.normalizePayload(calendarFilterPayload)
}
