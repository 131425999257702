import React from 'react'

import {Trans} from '@lingui/macro'

import {
  patchEmailNewsletter,
  postEmailNewsletter,
} from '@/api/email-newsletters'

import {Title} from '@/components/Title'
import EmailNewsletterForm from '@/components/forms/EmailNewsletterForm'

import {
  subscribeEmailNewsletter,
  updateEmailNewsletter,
} from '@/actions/email-newsletter'
import {useAuth} from '@/lib/auth'
import {EmailNewsletterModel} from '@/models/EmailNewsletter'

const EmailNewsletter = () => {
  const {user} = useAuth()

  return (
    <div>
      <Title>
        <Trans>Email newsletter</Trans>
      </Title>

      <EmailNewsletterForm
        user={user}
        onSubmit={async (values, formikHelpers) => {
          if (user?.emailNewsletter) {
            const newsletterPayload = await patchEmailNewsletter(
              values,
              formikHelpers
            )

            updateEmailNewsletter(
              user,
              EmailNewsletterModel.fromNormalizedPayload(newsletterPayload).data
            )
          }

          if (!user?.emailNewsletter) {
            const newsletterPayload = await postEmailNewsletter(
              values,
              formikHelpers
            )

            subscribeEmailNewsletter(
              user,
              EmailNewsletterModel.fromNormalizedPayload(newsletterPayload).data
            )
          }

          formikHelpers.setSubmitting(false)
        }}
      />
    </div>
  )
}

export default EmailNewsletter
