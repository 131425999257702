export interface AddressComponents {
  country: string
  areaLevel: string
  postalCode: string
  city: string
  street: string
}

export const normalizeAddressComponents = (
  addressComponents: google.maps.GeocoderAddressComponent[]
): AddressComponents => {
  const structure: any = {
    country: 'country',
    areaLevel: 'administrative_area_level_1',
    postalCode: 'postal_code',
    city: 'locality',
    street: ['route', 'street_number'],
  }
  const normalizedAddressComponents: any = {}

  const getValueByType = (type: string) =>
    addressComponents.find((addressComponent) =>
      addressComponent.types.includes(type)
    )?.long_name || ''

  Object.keys(structure).map((structureKey) => {
    const type = structure[structureKey]

    normalizedAddressComponents[structureKey] = Array.isArray(type)
      ? type
          .map((type) => getValueByType(type))
          .join(' ')
          .trim()
      : getValueByType(type)
  })

  return normalizedAddressComponents
}
