import React from 'react'

import {Trans} from '@lingui/macro'

import {Title} from '@/components/Title'
import PriceListsTable from '@/components/tabels/PriceListsTable'

const MyPriceLists = () => {
  return (
    <div>
      <Title>
        <Trans>My price lists</Trans>
      </Title>

      <PriceListsTable />
    </div>
  )
}

export default MyPriceLists
