import React, {useMemo} from 'react'

import {faPaperPlane} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans, t} from '@lingui/macro'

import {
  prefetchEventGames,
  prefetchFavoriteGames,
  prefetchNextGames,
} from '@/hooks/query/useGame'
import {prefetchLocations} from '@/hooks/query/useLocation'
import {prefetchStat} from '@/hooks/query/useStat'

import {NavigationItemProp} from '@/components/Navigation'
import {createGameCalendarInitValues} from '@/components/games/GameCalendar'

import {useAuth} from '@/lib/auth'
import {queryClient} from '@/lib/query'
import {useLocalizedNavigate} from '@/lib/router'
import {GameModel} from '@/models/Game'

export const useDefaultNavigationItems = (): NavigationItemProp[] => {
  const {user} = useAuth()
  const navigate = useLocalizedNavigate()

  return useMemo(
    () => [
      {
        to: '/tournaments/calendar',
        children: t`Tournaments calendar`,
        onMouseOver: async () =>
          await prefetchEventGames(
            createGameCalendarInitValues(
              new Date(),
              undefined,
              user?.calendarFilter
            )
          ),
      },
      {
        to: '/locations',
        children: t`Tournament locations`,
        onMouseOver: async () => await prefetchLocations(),
      },
      {
        to: '/guides/game-modes',
        children: t`Guide`,
        onActive: (pathname) => pathname.startsWith('/guides'),
      },
      {
        children: (
          <button type="button" className="btn-sm btn-primary capitalize">
            <FontAwesomeIcon icon={faPaperPlane} /> <Trans>To newsletter</Trans>
          </button>
        ),
        onClick: async () => {
          const nextGames = queryClient.getQueryData(GameModel.NEXT_KEY)
          if (!nextGames) {
            await prefetchNextGames()
          }
          navigate('/#newsletter')
        },
        onMouseOver: async () => await prefetchNextGames(),
      },
    ],
    []
  )
}

export const useUserNavigationItems = (): NavigationItemProp[] => {
  const {user, logout} = useAuth()

  return useMemo(() => {
    if (!user) {
      return [
        {
          to: '/auth/login',
          children: t`Login`,
        },
        {
          to: '/auth/registration',
          children: t`Registration`,
        },
      ]
    }

    if (user.isAdmin()) {
      return [
        {
          to: '/admin/dashboard',
          children: t`Admin menu`,
          onActive: (pathname) => pathname.startsWith('/admin'),
          onMouseOver: async () => await prefetchStat(),
        },
        {
          to: '/profile/tournaments/favorites',
          children: t`My profile`,
          onActive: (pathname) => pathname.startsWith('/profile'),
          onMouseOver: async () => await prefetchFavoriteGames(),
        },
        {
          to: '/',
          onClick: () => logout(),
          children: t`Logout`,
          onActive: () => false,
        },
      ]
    }

    return [
      {
        to: '/profile/tournaments/favorites',
        children: t`My profile`,
        onActive: (pathname) => pathname.startsWith('/profile'),
        onMouseOver: async () => await prefetchFavoriteGames(),
      },
      {
        to: '/',
        children: t`Logout`,
        onClick: () => logout(),
        onActive: () => false,
      },
    ]
  }, [user, logout])
}
