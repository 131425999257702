import {t} from '@lingui/macro'
import {toast} from 'react-hot-toast'

import {GameModel} from '@/models/Game'

export const successAddFavoriteToast = (game: GameModel) =>
  toast.success(
    t`The tournament game „${
      game.tournament.name
    }“ on ${game.formatStart()} has been added to your favorites`
  )

export const successRemoveFavoriteToast = (game: GameModel) =>
  toast.success(
    t`The tournament game „${
      game.tournament.name
    }“ on ${game.formatStart()} has been removed from your favorites`
  )

export const errorChangeFavoriteToast = (game: GameModel) =>
  toast.error(
    t`The tournament game „${
      game.tournament.name
    }“ on ${game.formatStart()} could not be seeded as favorite`
  )

export const successCanceledGameToast = (game: GameModel) =>
  toast.success(
    t`The tournament game „${
      game.tournament.name
    }“ on ${game.formatStart()} has been canceled`
  )

export const successActivateGameToast = (game: GameModel) =>
  toast.success(
    t`The tournament game „${
      game.tournament.name
    }“ on ${game.formatStart()} has been activated`
  )
