import {useQuery} from 'react-query'

import {getReports} from '@/api/reports'

import {SearchParamsProps} from '@/components/Table'

import {queryClient} from '@/lib/query'
import {ReportModel, reportSearchParams} from '@/models/Report'

export const useReports = (searchParams: SearchParamsProps) =>
  useQuery([ReportModel.KEY, searchParams], () => getReports(searchParams), {
    select: (reportsPayload) =>
      ReportModel.fromNormalizedPayload(reportsPayload),
    keepPreviousData: true,
  })

export const prefetchReports = (
  searchParams: SearchParamsProps = reportSearchParams
) =>
  !queryClient.getQueryData([ReportModel.KEY, searchParams]) &&
  queryClient.prefetchQuery([ReportModel.KEY, searchParams], () =>
    getReports(searchParams)
  )
