import React from 'react'

import {Trans, t} from '@lingui/macro'

import {FieldGroupRadiusAddressPosition} from '@/components/fields/FieldAddressAutocomplete'
import FieldSubmitButton from '@/components/fields/FieldSubmitButton'

import Form, {CustomFormInterface} from '@/lib/form'
import Yup from '@/lib/yup'

interface SearchTournamentsProps {
  active: boolean
  name: string
  radius?: number
  latitude?: number
  longitude?: number
  currentPosition: boolean
}

const searchTournamentsInitValues: SearchTournamentsProps = {
  active: true,
  name: '',
  radius: undefined,
  latitude: undefined,
  longitude: undefined,
  currentPosition: false,
}

const SearchTournamentsForm = (
  props: CustomFormInterface<SearchTournamentsProps>
) => {
  const searchTournamentsSchema = Yup.object().shape({
    name: Yup.string()
      .when('radius', {
        is: (radius: number) => !!radius,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      })
      .label(t`Address`),
    radius: Yup.radius().required(),
    latitude: Yup.latitude().when('radius', {
      is: (radius: number) => !!radius,
      then: Yup.latitude().required(),
      otherwise: Yup.latitude(),
    }),
    longitude: Yup.longitude().when('radius', {
      is: (radius: number) => !!radius,
      then: Yup.longitude().required(),
      otherwise: Yup.longitude(),
    }),
    currentPosition: Yup.currentPosition(),
  })

  return (
    <Form
      initialValues={searchTournamentsInitValues}
      validationSchema={searchTournamentsSchema}
      {...props}
    >
      {({handleSubmit, setFieldValue}) => (
        <form
          data-test-id="search-tournaments-form"
          onSubmit={handleSubmit}
          noValidate
        >
          <FieldGroupRadiusAddressPosition
            onChange={({position, name}) => {
              setFieldValue('name', name)
              setFieldValue('latitude', position.lat())
              setFieldValue('longitude', position.lng())
            }}
          />

          <FieldSubmitButton>
            <Trans>Find tournaments near you now!</Trans>
          </FieldSubmitButton>
        </form>
      )}
    </Form>
  )
}

export default SearchTournamentsForm
