import {SearchParamsProps} from '@/components/Table'

import {intlFormatDateTime} from '@/lib/date'
import {Model, ModelInterface} from '@/lib/model'
import {CalendarFilterModel} from '@/models/CalendarFilter'
import {EmailNewsletterModel} from '@/models/EmailNewsletter'
import {MobileNewsletterModel} from '@/models/MobileNewsletter'

export interface UserInterface extends ModelInterface {
  username: string
  email: string
  roleId: number
  emailVerifiedAt: string | null
  emailNewsletter: EmailNewsletterModel | null
  mobileNewsletter: MobileNewsletterModel[]
}

export class UserModel extends Model<UserInterface> {
  username!: string
  email!: string
  emailVerifiedAt!: string | null
  roleId!: number

  public static KEY = 'users'

  get emailNewsletter(): EmailNewsletterModel | null {
    return this.singleRelationship(EmailNewsletterModel, 'emailNewsletter')
  }

  set emailNewsletter(value: EmailNewsletterModel | null) {
    this.relationships.emailNewsletter = value
  }

  get mobileNewsletters(): MobileNewsletterModel[] {
    return this.manyRelationship(MobileNewsletterModel, 'mobileNewsletter')
  }

  set mobileNewsletters(value: MobileNewsletterModel[]) {
    this.relationships.mobileNewsletter = value
  }

  public isAdmin(): boolean {
    return this.roleId === 2
  }

  get calendarFilter(): CalendarFilterModel | null {
    return this.singleRelationship(CalendarFilterModel, 'calendarFilter')
  }

  set calendarFilter(value: CalendarFilterModel | null) {
    this.relationships.calendarFilter = value
  }

  public isNormalUser(): boolean {
    return this.roleId === 1
  }

  public removeEmailNewsletter(): UserModel {
    this.attributes.emailNewsletter = null
    this.relationships.emailNewsletter = null

    return this
  }

  public removeMobileNewsletter(): UserModel {
    this.attributes.mobileNewsletter = null
    this.relationships.mobileNewsletter = null

    return this
  }

  public formatEmailVerifiedAt(): string {
    return this.emailVerifiedAt
      ? intlFormatDateTime(new Date(this.emailVerifiedAt))
      : '-'
  }

  public redirectUrl(): string {
    if (this.isAdmin()) {
      return '/admin/dashboard'
    }

    return '/profile/tournaments/favorites'
  }
}

export const userSearchParams: SearchParamsProps = {
  'filter[search]': '',
  'page[size]': 25,
  'page[number]': 0,
  sort: '-createdAt',
}
