import {FormikHelpers} from 'formik/dist/types'

import {SearchParamsProps} from '@/components/Table'
import {LocationProps} from '@/components/forms/LocationModalForm'

import {client, formClient} from '@/lib/api-client'
import {LocationModel} from '@/models/Location'

export const getSelectLocations = async () => {
  const locationsPayload = await client.get('locations').json()

  return LocationModel.normalizePayload(locationsPayload)
}

export const getLocations = async (searchParams: SearchParamsProps) => {
  const locationsPayload = await client
    .get('locations', {
      searchParams: {
        include: 'creator',
        ...searchParams,
      },
    })
    .json()

  return LocationModel.normalizePayload(locationsPayload)
}

export const getLocation = async (slug: string) => {
  const locationPayload = await client
    .get(`locations${slug}`, {
      searchParams: {
        include: 'tournaments,creator,reports',
      },
    })
    .json()

  return LocationModel.normalizePayload(locationPayload)
}

export const postLocation = async (
  locationProps: LocationProps,
  formikHelpers: FormikHelpers<LocationProps>
) => {
  const locationPayload = await formClient
    .post(formikHelpers, 'locations', {
      json: {
        data: {
          type: 'locations',
          attributes: locationProps,
        },
      },
    })
    .json()

  return LocationModel.normalizePayload(locationPayload)
}
