import React, {useMemo, useState} from 'react'
import {UseQueryResult} from 'react-query'

import {t} from '@lingui/macro'

import {
  prefetchUserPriceLists,
  useUserPriceLists,
} from '@/hooks/query/usePriceList'

import {AlertDanger} from '@/components/Alert'
import PodiumPrices from '@/components/PodiumPrices'
import Table, {SearchParamsProps} from '@/components/Table'

import {PriceListModel, priceListSearchParams} from '@/models/PriceList'

interface ReportsTableProps {
  useTablePriceLists?: (searchParams: SearchParamsProps) => UseQueryResult<any>
  prefetchTablePriceLists?: (searchParams: SearchParamsProps) => void
}

const PriceListsTable = ({
  useTablePriceLists = useUserPriceLists,
  prefetchTablePriceLists = prefetchUserPriceLists,
}: ReportsTableProps) => {
  const [searchParams, setSearchParams] = useState<SearchParamsProps>(
    priceListSearchParams
  )
  const {data: priceListsData, ...priceListsQuery} =
    useTablePriceLists(searchParams)

  const priceListColumns = useMemo(
    () => [
      {
        id: 'name',
        Header: t`Price list`,
        accessor: 'name',
      },
      {
        id: 'prices',
        Header: t`Podium prices`,
        accessor: (priceList: PriceListModel) => (
          <PodiumPrices prices={priceList.sortedPrices} />
        ),
        sort: false,
      },
      {
        id: 'createdAt',
        Header: t`Added at`,
        accessor: (priceList: PriceListModel) => priceList.formatCreatedAt(),
        show: false,
      },
    ],
    []
  )

  if (priceListsQuery.isError) {
    return <AlertDanger>{String(priceListsQuery.error)}</AlertDanger>
  }

  const {
    data: priceLists,
    meta: {page: pagination},
  } = priceListsData || {
    data: [],
    meta: {page: {}},
  }

  return (
    <Table
      placeholder={t`Price lists`}
      data={priceLists}
      columns={priceListColumns}
      pagination={pagination}
      searchParams={searchParams}
      query={priceListsQuery}
      onChange={setSearchParams}
      onPrefetch={prefetchTablePriceLists}
    />
  )
}

export default PriceListsTable
