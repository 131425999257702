import React from 'react'

import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {t} from '@lingui/macro'

import {resendVerifyEmail} from '@/actions/user'
import {useAuth} from '@/lib/auth'
import {useLocalizedNavigate} from '@/lib/router'

const Email = () => {
  const {logout} = useAuth()
  const navigate = useLocalizedNavigate()

  return (
    <div className="container py-10">
      <div className="mx-auto my-3 w-full md:w-8/12 lg:w-6/12 xl:w-5/12">
        <h2 className="mb-8 text-center text-7xl text-primary">
          <FontAwesomeIcon icon={faEnvelope} />
        </h2>

        <div className="mb-5 text-center">
          {t`Thanks for signing up! Before getting started, could you verify your email address by clicking on the link we just emailed to you? If you didn\'t receive the email, we will gladly send you another.`}
        </div>

        <div className="flex flex-wrap space-y-5 space-x-0 lg:flex-nowrap lg:space-y-0 lg:space-x-5">
          <button
            type="button"
            className="btn btn-primary w-full"
            onClick={resendVerifyEmail}
          >
            {t`Resend`}
          </button>

          <button
            type="button"
            className="btn btn-gray w-full"
            onClick={() => logout(() => navigate('/'))}
          >
            {t`Logout`}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Email
