import React from 'react'

import {Trans} from '@lingui/macro'

import {Title} from '@/components/Title'
import ReportsTable from '@/components/tabels/ReportsTable'

const Reports = () => (
  <div>
    <Title>
      <Trans>Reports</Trans>
    </Title>

    <ReportsTable />
  </div>
)

export default Reports
