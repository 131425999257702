import React from 'react'

import {Trans, t} from '@lingui/macro'

import FieldInput from '@/components/fields/FieldInput'
import FieldSubmitButton from '@/components/fields/FieldSubmitButton'
import {FieldSwitch} from '@/components/fields/FieldSwitch'

import Form, {CustomFormInterface} from '@/lib/form'
import {LocalizedLink} from '@/lib/router'
import Yup from '@/lib/yup'

export interface RegistrationProps {
  username: string
  email: string
  emailConfirmation: string
  password: string
  passwordConfirmation: string
  privatePolicy: boolean
}

const registerInitValues: RegistrationProps = {
  username: '',
  email: '',
  emailConfirmation: '',
  password: '',
  passwordConfirmation: '',
  privatePolicy: false,
}

const RegistrationForm = (props: CustomFormInterface<RegistrationProps>) => {
  const registerSchema = Yup.object().shape({
    username: Yup.string()
      .required()
      .min(3)
      .max(191)
      .label(t`Username`),
    email: Yup.email().required(),
    emailConfirmation: Yup.string()
      .required()
      .oneOf([Yup.ref('email')], t`Emails must match`)
      .label(t`Email confirmation`),
    password: Yup.password().required(),
    passwordConfirmation: Yup.string()
      .required()
      .oneOf([Yup.ref('password')], t`Passwords must match`)
      .label(t`Password confirmation`),
    privatePolicy: Yup.bool()
      .oneOf([true], t`You must accept the privacy policy to register`)
      .label(t`Private policy`),
  })

  return (
    <Form
      initialValues={registerInitValues}
      validationSchema={registerSchema}
      {...props}
    >
      {({handleSubmit, isSubmitting}) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <FieldInput
            name="username"
            placeholder={t`Username`}
            required
            autoFocus
          />

          <FieldInput name="email" placeholder={t`Email`} required />

          <FieldInput
            name="emailConfirmation"
            placeholder={t`Email confirmation`}
            required
          />

          <FieldInput
            type="password"
            name="password"
            placeholder={t`Password`}
            required
          />

          <FieldInput
            type="password"
            name="passwordConfirmation"
            placeholder={t`Password confirmation`}
            required
          />

          <FieldSwitch className="mx-auto" name="privatePolicy">
            <Trans>
              Confirmation of the{' '}
              <LocalizedLink to="/rights/private-policy">
                {t`private policy`}
              </LocalizedLink>
            </Trans>
          </FieldSwitch>

          <FieldSubmitButton className="mb-5 w-full">
            {t`Register`}
          </FieldSubmitButton>

          <LocalizedLink to="/auth/login" noStyle>
            <button
              type="button"
              className="btn btn-gray w-full"
              disabled={isSubmitting}
            >
              <Trans>
                You have an account?{' '}
                <strong>
                  <Trans>Log in</Trans>
                </strong>
              </Trans>
            </button>
          </LocalizedLink>
        </form>
      )}
    </Form>
  )
}

export default RegistrationForm
