import {SearchParamsProps} from '@/components/Table'

import {client} from '@/lib/api-client'
import {SentEmailModel} from '@/models/SentEmail'

export const getSentEmails = async (searchParams: SearchParamsProps) => {
  const sentEmailsPayload = await client
    .get('emails', {
      searchParams: {
        ...searchParams,
      },
    })
    .json()

  return SentEmailModel.normalizePayload(sentEmailsPayload)
}
