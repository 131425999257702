import {Model, ModelInterface} from '@/lib/model'

export declare type SettingVariant = 'ed' | 'sd'
export declare type SettingValue = '301' | '501' | '701'
export declare type SettingSize = 'e' | 'd' | 't'
export declare type SettingOut = 'so' | 'do' | 'mo'

export interface MultiSettingInterface extends SettingSpecialsInterface {
  variant: SettingVariant[]
  value: SettingValue[]
  size: SettingSize[]
  out: SettingOut[]
}

export interface SettingInterface extends SettingSpecialsInterface {
  variant: SettingVariant
  value: SettingValue
  size: SettingSize
  out: SettingOut
}

export declare type MultiSettingModelInterface = ModelInterface &
  MultiSettingInterface

export interface SettingSpecialsInterface {
  mixed: boolean
  dyp: boolean
  doubleIn: boolean
}

export class SettingModel extends Model<MultiSettingModelInterface> {
  variant!: SettingVariant | SettingVariant[]
  value!: SettingValue | SettingValue[]
  size!: SettingSize | SettingSize[]
  out!: SettingOut | SettingOut[]
  mixed!: boolean
  dyp!: boolean
  doubleIn!: boolean

  public static KEY = 'settings'

  public toInitValues(): SettingInterface {
    return {
      variant: Array.isArray(this.variant) ? this.variant[0] : this.variant,
      value: Array.isArray(this.value) ? this.value[0] : this.value,
      size: Array.isArray(this.size) ? this.size[0] : this.size,
      out: Array.isArray(this.out) ? this.out[0] : this.out,
      mixed: this.mixed,
      dyp: this.dyp,
      doubleIn: this.doubleIn,
    }
  }
}
