import {i18n} from '@lingui/core'
import jsonApiNormalizer from '@spiderdan98/json-api-normalizer'
import {FormikHelpers} from 'formik/dist/types'
import {Options} from 'ky'
import ky from 'ky-universal'

import {accessToken} from '@/lib/auth'
import {errorResponseToast} from '@/toasts/api'

export const client = ky.create({
  prefixUrl: import.meta.env.VITE_API_URL,
  retry: 0,
  timeout: false,
  headers: {
    Accept: 'application/vnd.api+json',
  },
  hooks: {
    beforeRequest: [
      (request) => {
        if (i18n.locale) {
          request.headers.set('Accept-Language', i18n.locale)
        }

        if (accessToken) {
          request.headers.set('Authorization', `Bearer ${accessToken}`)
        }
      },
    ],
  },
})

const plainFormClient = (formHelpers: FormikHelpers<any>) =>
  client.extend({
    hooks: {
      afterResponse: [
        async (request, options, response) => {
          if (response.ok) {
            return Promise.resolve(response)
          }

          if (response.status === 422) {
            const errorsPayload = await response.json()

            formHelpers.setErrors(jsonApiNormalizer(errorsPayload))
            formHelpers.setSubmitting(false)

            return Promise.reject(response)
          }

          formHelpers.setSubmitting(false)
          errorResponseToast(response)
          return Promise.reject(response)
        },
      ],
    },
  })

export const formClient = {
  get: (formHelpers: FormikHelpers<any>, url: string, options?: Options) =>
    plainFormClient(formHelpers).get(url, options),
  post: (formHelpers: FormikHelpers<any>, url: string, options?: Options) =>
    plainFormClient(formHelpers).post(url, options),
  patch: (formHelpers: FormikHelpers<any>, url: string, options?: Options) =>
    plainFormClient(formHelpers).patch(url, options),
}
