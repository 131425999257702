import {useQuery} from 'react-query'

import {getUsers} from '@/api/users'

import {SearchParamsProps} from '@/components/Table'

import {queryClient} from '@/lib/query'
import {UserModel, userSearchParams} from '@/models/User'

export const useUsers = (searchParams: SearchParamsProps) =>
  useQuery([UserModel.KEY, searchParams], () => getUsers(searchParams), {
    select: (usersPayload) => UserModel.fromNormalizedPayload(usersPayload),
    keepPreviousData: true,
  })

export const prefetchUsers = (
  searchParams: SearchParamsProps = userSearchParams
) =>
  !queryClient.getQueryData([UserModel.KEY, searchParams]) &&
  queryClient.prefetchQuery([UserModel.KEY, searchParams], () =>
    getUsers(searchParams)
  )
