import React from 'react'

import {Trans, t} from '@lingui/macro'

import {postLocation} from '@/api/locations'

import FieldAddressAutocomplete from '@/components/fields/FieldAddressAutocomplete'
import FieldInput from '@/components/fields/FieldInput'
import FieldSubmitButton from '@/components/fields/FieldSubmitButton'

import {createLocation} from '@/actions/location'
import Form from '@/lib/form'
import Modal, {ModalProps} from '@/lib/modal'
import Yup from '@/lib/yup'
import {LocationModel} from '@/models/Location'

export interface LocationProps {
  name: string
  placeId: string
  country: string
  latitude?: number
  longitude?: number
  areaLevel: string
  postalCode: string
  city: string
  street: string
  website: string
  number: string
}

const locationInitValues: LocationProps = {
  name: '',
  placeId: '',
  country: '',
  latitude: undefined,
  longitude: undefined,
  areaLevel: '',
  postalCode: '',
  city: '',
  street: '',
  website: '',
  number: '',
}

interface CreateLocationModalProps extends ModalProps {
  onSuccess?: (location: LocationModel) => void
}

const LocationModalForm = ({
  onSuccess = () => null,
  ...props
}: CreateLocationModalProps) => {
  const locationValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required()
      .label(t`Location name`),
    placeId: Yup.string()
      .required()
      .label(t`Place id`),
    latitude: Yup.latitude().required(),
    longitude: Yup.longitude().required(),
    country: Yup.string()
      .required()
      .label(t`Country`),
    areaLevel: Yup.string()
      .required()
      .label(t`Area level`),
    postalCode: Yup.string()
      .required()
      .label(t`Postal code`),
    city: Yup.string()
      .required()
      .label(t`City`),
    street: Yup.string()
      .required()
      .label(t`Street`),
    website: Yup.string().label(t`Website`),
    number: Yup.string().label(t`Number`),
  })

  return (
    <Form
      initialValues={locationInitValues}
      validationSchema={locationValidationSchema}
      onSubmit={async (values, formikHelpers) => {
        const locationPayload = await postLocation(values, formikHelpers)
        const location =
          LocationModel.fromNormalizedPayload(locationPayload).data
        createLocation(location)
        onSuccess(location)
        props.onHide()
      }}
    >
      {({values, setValues}) => (
        <Modal
          {...props}
          title={t`Create location`}
          footer={
            <FieldSubmitButton>
              <Trans>Create location</Trans>
            </FieldSubmitButton>
          }
        >
          <FieldAddressAutocomplete
            name="name"
            onChange={({addressComponents, position, name, placeId}) =>
              setValues({
                ...values,
                placeId,
                name,
                latitude: position.lat(),
                longitude: position.lng(),
                ...addressComponents,
              })
            }
            onlyLocation
          />

          <FieldInput
            name="country"
            placeholder={t`Country`}
            required
            disabled
          />

          <FieldInput
            name="areaLevel"
            placeholder={t`Area level`}
            required
            disabled
          />

          <div className="flex space-x-5">
            <FieldInput
              name="postalCode"
              placeholder={t`Postal code`}
              required
              disabled
            />

            <FieldInput name="city" placeholder={t`City`} required disabled />
          </div>

          <FieldInput name="street" placeholder={t`Street`} required disabled />

          <FieldInput name="website" placeholder={t`Website`} />

          <FieldInput name="number" placeholder={t`Number`} />
        </Modal>
      )}
    </Form>
  )
}

export default LocationModalForm
