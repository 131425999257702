import React from 'react'

import {Trans, t} from '@lingui/macro'

import FieldInput from '@/components/fields/FieldInput'
import {FieldRequiredSubmitButton} from '@/components/fields/FieldSubmitButton'

import Form, {CustomFormInterface} from '@/lib/form'
import Yup from '@/lib/yup'

export interface ResetPasswordProps {
  token: string | string[]
  email: string
  password: string
  passwordConfirmation: string
}

interface ResetPasswordFormProps
  extends CustomFormInterface<ResetPasswordProps> {
  token: string | string[]
}

const createResetPasswordInitValues = (token: string): ResetPasswordProps => ({
  token,
  email: '',
  password: '',
  passwordConfirmation: '',
})

const ResetPasswordForm = ({token, ...props}: ResetPasswordFormProps) => {
  const resetPasswordSchema = Yup.object({
    email: Yup.email().required(),
    password: Yup.password().required(),
    passwordConfirmation: Yup.string()
      .required()
      .oneOf([Yup.ref('password')], t`Passwords must match`)
      .label(t`Password confirmation`),
  })

  return (
    <Form
      initialValues={createResetPasswordInitValues(
        Array.isArray(token) ? token[0] : token
      )}
      validationSchema={resetPasswordSchema}
      {...props}
    >
      {({handleSubmit, errors}) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <FieldInput
            type="email"
            name="email"
            placeholder={t`Email`}
            required
          />

          <FieldInput
            type="password"
            name="password"
            placeholder={t`Password`}
            required
          />

          <FieldInput
            type="password"
            name="passwordConfirmation"
            placeholder={t`Password confirmation`}
            required
          />

          <FieldRequiredSubmitButton>
            <Trans>Reset password</Trans>
          </FieldRequiredSubmitButton>
        </form>
      )}
    </Form>
  )
}

export default ResetPasswordForm
