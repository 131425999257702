import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {Trans, t} from '@lingui/macro'

import {Title} from '@/components/Title'
import RegistrationForm from '@/components/forms/RegistrationForm'

import {useAuth} from '@/lib/auth'
import {useLocalizedNavigate} from '@/lib/router'

const Registration = () => {
  const {register} = useAuth()
  const navigate = useNavigate()
  const localizedNavigate = useLocalizedNavigate()
  const {state}: any = useLocation()

  return (
    <div className="container py-10">
      <div className="mx-auto my-3 w-full md:w-8/12 lg:w-6/12 xl:w-5/12">
        <Title>
          <Trans>Registration</Trans>
        </Title>

        <RegistrationForm
          onSubmit={(values, formikHelpers) => {
            register(values, formikHelpers, (user) => {
              if (state?.from) {
                navigate(state.from.pathname + state.from.search)
              }

              if (!state?.from) {
                localizedNavigate(user.redirectUrl())
              }
            })
          }}
        />
      </div>
    </div>
  )
}

export default Registration
