import {EmailNewsletterModel} from '@/models/EmailNewsletter'
import {UserModel} from '@/models/User'
import {
  successSubscribeNewsletterToast,
  successUnsubscribeNewsletterToast,
  successUpdateNewsletterToast,
} from '@/toasts/newsletter'

export const unsubscribeEmailNewsletter = (user: UserModel) => {
  successUnsubscribeNewsletterToast()
  user.removeEmailNewsletter()
}

export const subscribeEmailNewsletter = (
  user: UserModel | null,
  newsletter: EmailNewsletterModel
) => {
  successSubscribeNewsletterToast(newsletter)
  if (user) {
    user.emailNewsletter = newsletter
  }
}

export const updateEmailNewsletter = (
  user: UserModel,
  newsletter: EmailNewsletterModel
) => {
  successUpdateNewsletterToast(newsletter)
  user.emailNewsletter = newsletter
}
