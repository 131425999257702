import React from 'react'

import {Trans} from '@lingui/macro'

import {postForgotPassword} from '@/api/users'

import {Title} from '@/components/Title'
import ForgotPasswordForm from '@/components/forms/ForgotPasswordForm'

import {forgotPassword} from '@/actions/user'

const Forgot = () => (
  <div className="container py-10">
    <div className="mx-auto my-3 w-full md:w-8/12 lg:w-6/12 xl:w-5/12">
      <Title>
        <Trans>Forgot password</Trans>
      </Title>

      <ForgotPasswordForm
        onSubmit={(values, formikHelpers) => {
          postForgotPassword(values, formikHelpers).then(() => {
            forgotPassword()
            formikHelpers.resetForm()
          })
        }}
      />
    </div>
  </div>
)

export default Forgot
