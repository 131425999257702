import {SearchParamsProps} from '@/components/Table'
import {GameProps} from '@/components/forms/TournamentForm'

import {intlFormatDateTime, intlFormatDistanceToNow} from '@/lib/date'
import {Model, ModelInterface} from '@/lib/model'
import {LeagueModel} from '@/models/League'
import {PriceListModel} from '@/models/PriceList'
import {ReportModel} from '@/models/Report'
import {SettingModel} from '@/models/Setting'
import {TournamentModel} from '@/models/Tournament'

export interface GameInterface extends ModelInterface {
  dbId: string
  deadline: string | null
  start: string
  canceled: boolean
  buyIn: number | null
  favorite: boolean
  image: string
  tournament: TournamentModel
  priceList: PriceListModel | null
}

export class GameModel extends Model<GameInterface> {
  dbId!: string
  deadline!: string | null
  start!: string
  canceled!: boolean
  buyIn!: number | null
  favorite!: boolean
  image!: string

  public static KEY = 'games'
  public static NEXT_KEY = 'next-games'
  public static FAVORITE_KEY = 'favorite-games'
  public static EVENT_KEY = 'event-games'

  get report(): ReportModel | null {
    return this.reports?.length ? this.reports[0] : null
  }

  get reports(): ReportModel[] {
    return this.manyRelationship(ReportModel, 'reports')
  }

  set reports(reports: ReportModel[]) {
    this.relationships.reports = reports
  }

  get tournament(): TournamentModel | null {
    return this.singleRelationship(TournamentModel, 'tournament')
  }

  set tournament(tournament: TournamentModel | null) {
    this.relationships.tournament = tournament
  }

  get priceList(): PriceListModel | null {
    return this.singleRelationship(PriceListModel, 'priceList')
  }

  get setting(): SettingModel {
    return this.singleRelationship(SettingModel, 'setting')
  }

  set setting(setting: SettingModel | null) {
    this.relationships.setting = setting
  }

  get leagues(): LeagueModel[] {
    return this.manyRelationship(LeagueModel, 'leagues')
  }

  public deadlineForHumans(): string {
    return intlFormatDistanceToNow(new Date(this.deadline || this.start))
  }

  public otherGames(): GameModel[] {
    return this.tournament.games?.filter((game) => game.id !== this.id) || []
  }

  public formattedBuyIn(): string {
    return new Intl.NumberFormat('de', {
      style: 'currency',
      currency: 'EUR',
    }).format(this.buyIn / 100)
  }

  public link(): string {
    return `/tournaments/${this.id}`
  }

  public formatDeadline(): string {
    return this.deadline ? intlFormatDateTime(new Date(this.deadline)) : '-'
  }

  public formatStart(): string {
    return intlFormatDateTime(new Date(this.start))
  }

  public slug(): string {
    return `/${this.id}`
  }

  public toInitValues(): GameProps {
    return {
      setting: this.setting.toInitValues(),
      buyIn: this.buyIn,
      priceListId: this.priceList?.id,
      leagueIds: this.leagues.map((league) => league.id),
    }
  }
}

export const gameSearchParams: SearchParamsProps = {
  'filter[search]': '',
  'page[size]': 25,
  'page[number]': 0,
  sort: 'start',
}
