import React from 'react'

import LocationLabels from '@/components/locations/LocationLabels'
import LocationMap from '@/components/locations/LocationMap'

import {LocalizedLink} from '@/lib/router'
import {LocationModel} from '@/models/Location'

interface SmallLocationInformationProps {
  location: LocationModel
}

const SmallLocationInformation = ({
  location,
}: SmallLocationInformationProps) => (
  <div className="flex flex-wrap md:flex-nowrap">
    <div className="order-last w-full md:order-first">
      <LocationMap location={location} />
    </div>

    <div className="order-first flex w-full flex-col justify-center bg-white p-5 dark:bg-dark md:order-last">
      <div className="space-y-5">
        <LocalizedLink to={location.link()}>
          <h3 className="text-center text-2xl">{location.name}</h3>
        </LocalizedLink>

        <LocationLabels location={location} />
      </div>
    </div>
  </div>
)

export default SmallLocationInformation
