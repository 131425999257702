import React, {
  FocusEvent,
  MouseEvent,
  ReactChild,
  useEffect,
  useRef,
  useState,
} from 'react'

import classNames from 'classnames'
import {useField, useFormikContext} from 'formik'

import {InvalidIcon, RequiredIcon, ValidIcon} from '@/components/Icons'
import Loader from '@/components/Loader'

import {FieldInterface, FormControl} from '@/lib/form'

interface TextAreaProps {
  name?: string
  value?: string
  className?: string
  placeholder: string
  onClick?: (event: MouseEvent) => void
  onBlur?: (event: FocusEvent) => void
  onIconContainerWidth?: (iconContainerWidth: number) => void
  loading?: boolean
  disabled?: boolean
  invalid?: boolean
  valid?: boolean
  required?: boolean
  icon?: ReactChild
}

export const TextArea = ({
  className,
  value,
  invalid,
  valid,
  loading,
  icon,
  required,
  onIconContainerWidth,
  ...props
}: TextAreaProps) => {
  const iconContainerRef = useRef<HTMLDivElement>(null)
  const [iconContainerWidth, setIconContainerWidth] = useState(0)

  useEffect(() => {
    if (!iconContainerRef.current) {
      return
    }
    setIconContainerWidth(iconContainerRef.current.offsetWidth)
    onIconContainerWidth &&
      onIconContainerWidth(iconContainerRef.current.offsetWidth)
  }, [iconContainerRef.current?.offsetWidth])

  return (
    <div className="relative w-full">
      <textarea
        className={classNames(
          className,
          'input min-h-[10rem]',
          invalid && 'invalid',
          valid && 'valid'
        )}
        style={{
          paddingRight: `calc(${iconContainerWidth}px + 1.25rem)`,
        }}
        value={value === null ? '' : value}
        {...props}
      />

      <div
        ref={iconContainerRef}
        className="pointer-events-none absolute bottom-0 top-0 right-2 flex items-center space-x-1"
      >
        {icon}
        {loading && <Loader />}
        {invalid && <InvalidIcon />}
        {valid && <ValidIcon />}
        {!invalid && !valid && required && <RequiredIcon />}
      </div>
    </div>
  )
}

interface FieldTextAreaProps extends FieldInterface {
  className?: string
  placeholder: string
  disabled?: boolean
}

const FieldTextArea = ({name, disabled, ...props}: FieldTextAreaProps) => {
  const {isSubmitting} = useFormikContext()
  const [field, meta] = useField(name)

  return (
    <FormControl meta={meta}>
      <TextArea
        {...field}
        {...props}
        invalid={
          (meta.initialTouched || meta.touched) &&
          (!!meta.error || !!meta.initialError)
        }
        valid={
          (meta.touched && !meta.error) ||
          (meta.initialTouched && !meta.initialError)
        }
        disabled={isSubmitting || disabled}
      />
    </FormControl>
  )
}

export default FieldTextArea
