import React from 'react'

import {Trans} from '@lingui/macro'

import {Title} from '@/components/Title'
import LocationsTable from '@/components/tabels/LocationsTable'

const Locations = () => (
  <div>
    <Title>
      <Trans>Tournament locations</Trans>
    </Title>

    <LocationsTable />
  </div>
)

export default Locations
