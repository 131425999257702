import React, {ReactChild, ReactChildren} from 'react'
import {Hydrate, QueryClient, QueryClientProvider} from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: import.meta.env.SSR ? 0 : 1,
      staleTime: 1000,
      refetchOnMount: false,
    },
  },
})

interface HydratedQueryClientProviderProps {
  children: ReactChild | ReactChildren
  state?: object
}

const reactQueryStateCleanUp = () => {
  document.getElementById('react-query-state')?.remove()
  delete window?.__REACT_QUERY_STATE__
}

export const HydratedQueryClientProvider = ({
  children,
  state,
}: HydratedQueryClientProviderProps) => {
  if (!state) {
    state = window?.__REACT_QUERY_STATE__
    reactQueryStateCleanUp()
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={state} children={children} />
    </QueryClientProvider>
  )
}
