import {formatISO} from 'date-fns'

import {postResendVerifyEmail} from '@/api/users'

import {ProfileProps} from '@/components/forms/ProfileForm'

import {UserModel} from '@/models/User'
import {errorResponseToast} from '@/toasts/api'
import {
  successForgotPasswordToast,
  successResendVeryEmailToast,
  successResetPasswordToast,
  successVerifyToast,
} from '@/toasts/auth'
import {successUpdateUser} from '@/toasts/user'

export const resendVerifyEmail = async () => {
  try {
    await postResendVerifyEmail()
    successResendVeryEmailToast()
  } catch ({response}) {
    errorResponseToast(response)
  }
}

export const verifyUser = (user: UserModel) => {
  user.emailVerifiedAt = formatISO(new Date())
  successVerifyToast()
}

export const forgotPassword = () => successForgotPasswordToast()

export const resetPassword = () => successResetPasswordToast()

export const updateUser = (user: UserModel, profileProps: ProfileProps) => {
  user.username = profileProps.username
  successUpdateUser(user)
}
