import React, {useState} from 'react'

import {faCheck, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans} from '@lingui/macro'

import ReportGameModalForm from '@/components/forms/ReportGameModalForm'

import {useAuth} from '@/lib/auth'
import {GameModel} from '@/models/Game'

interface GameReportProps {
  game: GameModel
}

const TournamentReport = ({game}: GameReportProps) => {
  const {user} = useAuth()
  const [isOpen, setIsOpen] = useState(false)

  if (!user) {
    return null
  }

  return (
    <>
      {game.report ? (
        <button type="button" className="btn-sm btn-primary active" disabled>
          <FontAwesomeIcon icon={faCheck} fixedWidth />{' '}
          <Trans>Tournament game reported</Trans>
        </button>
      ) : (
        <button
          type="button"
          className="btn-sm btn-danger"
          onClick={() => setIsOpen(true)}
        >
          <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />{' '}
          <Trans>Report tournament game</Trans>
        </button>
      )}

      <ReportGameModalForm
        game={game}
        open={isOpen}
        onHide={() => setIsOpen(false)}
      />
    </>
  )
}

export default TournamentReport
