import React, {Fragment, ReactChild, useMemo, useState} from 'react'
import {MouseEvent, useCallback} from 'react'

import {
  faChevronLeft,
  faChevronRight,
  faEye,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans, t} from '@lingui/macro'
import classNames from 'classnames'
import {
  Cell,
  ColumnInstance,
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  TableOptions,
  TablePropGetter,
  TableProps,
  useExpanded,
  useTable,
} from 'react-table'

import {AlertDanger, AlertInfo} from '@/components/Alert'
import Collapse from '@/components/Collapse'
import Dropdown from '@/components/Dropdown'
import Loader from '@/components/Loader'
import {Input} from '@/components/fields/FieldInput'

interface QueryProps {
  isLoading: boolean
  isFetching: boolean
}

export interface SearchParamsProps {
  'filter[search]': string
  'page[size]': number
  'page[number]': number
  sort: string
}

interface PaginationProps {
  currentPage: number
  from: number
  lastPage: number
  perPage: number
  to: number
  total: number
}

interface PaginationTableProps {
  searchParams: SearchParamsProps
  pagination: PaginationProps
  placeholder: string
  query: QueryProps
  onChange: (searchParams: SearchParamsProps) => void
  onPrefetch: (searchParams: SearchParamsProps) => void
  onExpand?: (row: Row) => JSX.Element
}

const Table = ({
  searchParams,
  pagination,
  query,
  onChange,
  onPrefetch,
  onExpand,
  placeholder,
  ...props
}: TableOptions<any> & PaginationTableProps) => {
  const onChangeCallback = useCallback(
    (locationTableProps) => onChange(locationTableProps),
    []
  )
  const {allColumns, ...reactTableProps} = useTable(
    {
      initialState: {
        hiddenColumns: props.columns
          .filter((col: any) => col.show === false)
          .map((col) => col.id || col.accessor) as any,
      },
      ...props,
    },
    useExpanded
  )
  const isEmptySearch =
    !props.data.length &&
    !query.isLoading &&
    !props.data.length &&
    !!searchParams['filter[search]']

  const search = searchParams['filter[search]']

  return (
    <div className="space-y-5">
      <TableControls
        searchPlaceholder={t`Search ${placeholder}`}
        allColumns={allColumns}
        searchParams={searchParams}
        onChange={onChangeCallback}
      />

      {!props.data.length &&
        !query.isLoading &&
        !searchParams['filter[search]'] && (
          <AlertInfo className="mx-auto md:w-2/3">
            <Trans>No {placeholder} found</Trans>
          </AlertInfo>
        )}

      {!props.data.length && !!query.isLoading && (
        <div className="py-10 text-center text-6xl">
          <Loader />
        </div>
      )}

      {isEmptySearch && (
        <AlertDanger className="mx-auto md:w-2/3">
          {t`No ${placeholder} found for „${search}“`}
        </AlertDanger>
      )}

      {!isEmptySearch && !!props.data.length && (
        <div className="border-gray-200 overflow-hidden rounded-lg border-b shadow dark:border-dark">
          <TableContent
            isLoading={query.isFetching}
            searchParams={searchParams}
            onChange={onChangeCallback}
            onPrefetch={onPrefetch}
            onExpand={onExpand}
            {...reactTableProps}
          />

          <TablePagination
            onChange={onChangeCallback}
            searchParams={searchParams}
            pagination={pagination}
            query={query}
            onPrefetch={onPrefetch}
          />
        </div>
      )}
    </div>
  )
}

interface TableContentProps<D extends object = {}> {
  isLoading: boolean
  getTableProps: (propGetter?: TablePropGetter<D>) => TableProps
  getTableBodyProps: (propGetter?: TableBodyPropGetter<D>) => TableBodyProps
  headerGroups: Array<HeaderGroup<D>>
  searchParams: SearchParamsProps
  visibleColumns: []
  rows: Array<Row<D>>
  prepareRow: (row: Row<D>) => void
  onChange: (searchParams: SearchParamsProps) => void
  onPrefetch: (searchParams: SearchParamsProps) => void
  onExpand?: (row: Row) => JSX.Element
}

const TableContent = ({
  isLoading,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  searchParams,
  visibleColumns,
  rows,
  prepareRow,
  onChange,
  onPrefetch,
  onExpand = () => null,
}: TableContentProps) => (
  <div className="overflow-x-auto">
    <table
      {...getTableProps()}
      className="divide-gray-200 min-w-full table-auto divide-y"
    >
      <TableHead
        isLoading={isLoading}
        headerGroups={headerGroups}
        searchParams={searchParams}
        onChange={onChange}
        onPrefetch={onPrefetch}
      />

      <tbody
        {...getTableBodyProps()}
        className="divide-gray-200 divide-y bg-white dark:bg-dark"
      >
        {rows.map((row: Row) => {
          prepareRow(row)

          return (
            <Fragment key={row.getRowProps().key}>
              <tr>
                {row.cells.map((cell: Cell) => {
                  return (
                    <td
                      className="whitespace-nowrap px-6 py-4"
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>

              {!!row.isExpanded && (
                <ExpandCollapse
                  visibleColumns={visibleColumns}
                  onExpand={onExpand}
                  row={row}
                />
              )}
            </Fragment>
          )
        })}
      </tbody>
    </table>
  </div>
)

interface ExpandCollapseProps<D extends object = {}> {
  row: any
  visibleColumns: Array<ColumnInstance<D>>
  onExpand?: (row: Row) => JSX.Element
}

const ExpandCollapse = ({
  row,
  visibleColumns,
  onExpand,
}: ExpandCollapseProps) => {
  const [isHidden, setHidden] = useState(!row.isExpanded)

  if (!onExpand) {
    return null
  }

  return (
    <tr className={isHidden && !row.isExpanded ? 'hidden' : ''}>
      <td colSpan={visibleColumns.length}>
        <Collapse
          open={row.isExpanded}
          onAnimationComplete={(variant: string) =>
            variant === 'hidden' && setHidden(true)
          }
          onAnimationStart={() => setHidden(false)}
        >
          {onExpand(row)}
        </Collapse>
      </td>
    </tr>
  )
}

interface TableControlsProps {
  searchPlaceholder: string
  searchParams: SearchParamsProps
  allColumns: ColumnInstance[]
  onChange: (searchParams: SearchParamsProps) => void
}

const TableControls = ({
  searchPlaceholder,
  searchParams,
  allColumns,
  onChange,
}: TableControlsProps) => {
  const [search, setSearch] = useState(searchParams['filter[search]'])
  const perPageItems = useMemo(
    () => [
      {label: '25', value: 25},
      {label: '50', value: 50},
      {label: '100', value: 100},
      {label: '250', value: 250},
    ],
    []
  )
  const onSearch = () => onChange({...searchParams, 'filter[search]': search})

  return (
    <div className="flex flex-wrap space-y-5 md:flex-nowrap md:space-y-0 md:space-x-5">
      <div className="group">
        <Input
          placeholder={searchPlaceholder}
          value={search}
          onChange={({target}) => setSearch(target.value)}
          onKeyDown={(event) => event.keyCode === 13 && onSearch()}
          autoFocus
        />

        <button type="button" className="btn btn-primary" onClick={onSearch}>
          <FontAwesomeIcon icon={faSearch} className="fa-fw" />
        </button>
      </div>

      <div className="flex w-full space-x-5 md:w-auto">
        <Dropdown
          containerClassName="whitespace-nowrap w-full md:w-auto"
          className="w-full md:w-auto"
          buttonClassName="btn btn-gray w-full md:w-auto"
          items={perPageItems}
          onChange={({value}) =>
            onChange({
              ...searchParams,
              'page[number]': 1,
              'page[size]': value,
            })
          }
          value={searchParams['page[size]']}
        >
          <Trans>Elements per page: {searchParams['page[size]']}</Trans>
        </Dropdown>

        <Dropdown
          containerClassName="w-auto"
          buttonClassName="btn btn-gray"
          items={allColumns
            .filter((column) => column.Header)
            .map((column) => ({
              label: String(column.Header),
              value: String(column.Header),
              column,
            }))}
          value={allColumns
            .filter((column) => column.isVisible)
            .map((column) => String(column.Header))}
          onChange={({column}: any) => column.toggleHidden()}
          placement="bottom-end"
          noSameWidth
          noArrow
          multi
        >
          <FontAwesomeIcon icon={faEye} />
        </Dropdown>
      </div>
    </div>
  )
}

interface TableHeadProps {
  isLoading: boolean
  headerGroups: HeaderGroup[]
  searchParams: SearchParamsProps
  onChange: (requestParams: SearchParamsProps) => void
  onPrefetch: (requestParams: SearchParamsProps) => void
}

const TableHead = ({headerGroups, ...props}: TableHeadProps) => (
  <thead className="whitespace-nowrap bg-gray-light dark:bg-gray-dark">
    {headerGroups.map((headerGroup: HeaderGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column: any) => (
          <TableHeader
            id={column.id}
            className={column.className}
            sort={column.sort}
            {...column.getHeaderProps()}
            {...props}
          >
            {column.render('Header')}
          </TableHeader>
        ))}
      </tr>
    ))}
  </thead>
)

interface TableHeaderProps
  extends React.DetailedHTMLProps<
    React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
    HTMLTableHeaderCellElement
  > {
  isLoading: boolean
  searchParams: SearchParamsProps
  onChange: (requestParams: SearchParamsProps | any) => void
  onPrefetch: (requestParams: SearchParamsProps) => void
  sort?: boolean
  className?: string
}

const TableHeader = ({
  isLoading,
  onChange,
  searchParams,
  children,
  onPrefetch,
  className,
  sort = true,
  ...props
}: TableHeaderProps) => {
  const columnId = props.id || ''
  const isOrderBy =
    searchParams.sort === columnId || searchParams.sort === `-${columnId}`
  const isOrderAsc = isOrderBy && searchParams.sort.startsWith('-')
  const sortRequestParams: SearchParamsProps = {
    ...searchParams,
    'page[number]': 0,
    sort: (isOrderAsc ? '' : '-') + columnId,
  }
  const onSort = () => sort && onChange(sortRequestParams)
  const prefetchSort = () => sort && onPrefetch(sortRequestParams)

  return (
    <th
      scope="col"
      className={classNames(
        'text-gray-500 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider',
        sort && 'cursor-pointer',
        className
      )}
      onClick={onSort}
      onMouseOver={prefetchSort}
      {...props}
    >
      {sort && !isOrderBy && (
        <FontAwesomeIcon icon={faSort} className="text-primary" fixedWidth />
      )}
      {sort && !isLoading && isOrderBy && (
        <FontAwesomeIcon
          icon={isOrderAsc ? faSortUp : faSortDown}
          className="text-primary"
          fixedWidth
        />
      )}
      {sort && isOrderBy && isLoading && <Loader className="fa-fw" />}
      <span className="ml-1">{children}</span>
    </th>
  )
}

interface TablePaginationProps {
  pagination: PaginationProps
  query: QueryProps
  searchParams: SearchParamsProps
  onChange: (searchParams: SearchParamsProps) => void
  onPrefetch: (searchParams: SearchParamsProps) => void
}

const TablePagination = ({
  searchParams,
  pagination,
  onChange,
  onPrefetch,
}: TablePaginationProps) => {
  const pages = Array.from({length: pagination.lastPage}, (v, i) => i + 1)
  const cantPreviousPage = pagination.currentPage === 1
  const cantNextPage = pagination.currentPage === pagination.lastPage
  const previousRequestParams: SearchParamsProps = {
    ...searchParams,
    'page[number]': pagination.currentPage - 1,
  }
  const nextRequestParams: SearchParamsProps = {
    ...searchParams,
    'page[number]': pagination.currentPage + 1,
  }
  const goPrevious = () => onChange(previousRequestParams)
  const goNext = () => onChange(nextRequestParams)
  const prefetchPrevious = () =>
    !cantPreviousPage && onPrefetch(previousRequestParams)
  const prefetchNext = () => !cantNextPage && onPrefetch(nextRequestParams)

  return (
    <div className="border-gray-200 flex items-center justify-between border-t px-4 py-3 font-medium sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <PaginationButton
          className="relative inline-flex items-center px-4 py-2"
          onClick={goPrevious}
          onMouseOver={prefetchPrevious}
          disabled={cantPreviousPage}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </PaginationButton>

        <PaginationButton
          className="relative ml-3 inline-flex items-center px-4 py-2"
          onClick={goNext}
          onMouseOver={prefetchNext}
          disabled={cantNextPage}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </PaginationButton>
      </div>

      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-gray-700 text-sm">
            {t`Showing` + ' '}
            <span className="font-bold">{pagination.from}</span>
            {' ' + t`to` + ' '}
            <span className="font-bold">{pagination.to}</span>
            {' ' + t`of` + ' '}
            <span className="font-bold">{pagination.total}</span>
            {' ' + t`results`}
          </p>
        </div>

        <div>
          <nav
            className="relative z-0 inline-flex -space-x-px overflow-hidden rounded-md pr-px shadow-sm"
            aria-label="Pagination"
          >
            <PaginationButton
              className="relative inline-flex items-center rounded-l-md px-2 py-2"
              onClick={goPrevious}
              onMouseOver={prefetchPrevious}
              disabled={cantPreviousPage}
            >
              <span className="sr-only">Previous</span>

              <FontAwesomeIcon icon={faChevronLeft} />
            </PaginationButton>

            {pages.map((page) => {
              const isActive = page === pagination.currentPage
              const pageRequestParams: SearchParamsProps = {
                ...searchParams,
                'page[number]': page,
              }
              const onPage = () => !isActive && onChange(pageRequestParams)
              const prefetchPage = () =>
                !isActive && onPrefetch(pageRequestParams)

              // TODO: add ...

              return (
                <button
                  key={page}
                  onClick={onPage}
                  onMouseOver={prefetchPage}
                  className={classNames(
                    'relative inline-flex items-center border-t border-b border-gray-light px-4 py-2 text-sm',
                    !isActive &&
                      'text-gray-light hover:bg-gray-light hover:text-gray',
                    isActive &&
                      '-m-px cursor-default bg-primary font-bold text-white'
                  )}
                >
                  {page}
                </button>
              )
            })}

            <PaginationButton
              className="relative inline-flex items-center rounded-r-md px-2 py-2"
              onClick={goNext}
              onMouseOver={prefetchNext}
              disabled={cantNextPage}
            >
              <span className="sr-only">Next</span>

              <FontAwesomeIcon icon={faChevronRight} />
            </PaginationButton>
          </nav>
        </div>
      </div>
    </div>
  )
}

interface PaginationButtonProps {
  className?: string
  disabled?: boolean
  onClick?: (event: MouseEvent<any>) => void
  onMouseOver?: (event: MouseEvent<any>) => void
  children: ReactChild | ReactChild[]
}

const PaginationButton = (props: PaginationButtonProps) => (
  <button
    {...props}
    className={classNames(
      'border border-gray-light text-white',
      !props.disabled && 'hover:bg-gray-light hover:text-gray',
      props.disabled && 'cursor-not-allowed text-gray-light',
      props.className
    )}
    type="button"
  />
)

export default Table
