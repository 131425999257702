import React from 'react'

import {Trans, t} from '@lingui/macro'

import {useSelectLeagues} from '@/hooks/query/useLeague'

import FieldSelect from '@/components/fields/FieldSelect'

import {FieldGroupInterface} from '@/lib/form'

interface FieldLeaguesSelectProps extends FieldGroupInterface {
  required?: boolean
  disabled?: boolean
}

const FieldLeaguesSelect = ({
  namePrefix = '',
  ...props
}: FieldLeaguesSelectProps) => {
  const {data: leagues, isLoading, error} = useSelectLeagues()

  return (
    <FieldSelect
      {...props}
      items={leagues || []}
      name={`${namePrefix}leagueIds`}
      placeholder={t`leagues`}
      error={error && String(error)}
      loading={isLoading}
      actions={(updateValue, filteredItems) => (
        <button
          type="button"
          className="btn-sm btn-primary w-full"
          onClick={() => updateValue(filteredItems)}
        >
          <Trans>Open</Trans>
        </button>
      )}
      multi
    />
  )
}

export default FieldLeaguesSelect
