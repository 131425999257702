import {Model, ModelInterface} from '@/lib/model'

export interface LeagueInterface extends ModelInterface {
  name: string
  shortcut: string
}

export class LeagueModel extends Model<LeagueInterface> {
  name!: string
  shortcut!: string

  public static KEY = 'leagues'
  public static SELECT_KEY = 'select-leagues'

  public static toSelectItems(normalizedLeaguesPayload: any) {
    const leagues = normalizedLeaguesPayload.data

    return leagues.map((league) => ({
      label: league.name,
      value: league.id,
      subLabel: league.shortcut,
    }))
  }
}
