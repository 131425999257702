import React, {useEffect} from 'react'

import {t} from '@lingui/macro'
import {useField} from 'formik'

import FieldCheckboxButton from '@/components/fields/FieldCheckboxButton'

import {FieldGroupInterface, FormControl} from '@/lib/form'

interface FieldSettings extends FieldGroupInterface {
  multi?: boolean
  disabled?: boolean
}

const FieldGroupSetting = ({
  namePrefix = '',
  multi,
  disabled,
}: FieldSettings) => {
  const [{value}, meta, helpers]: any = useField(
    namePrefix ? namePrefix.slice(0, -1) : namePrefix
  )
  const type = multi ? 'checkbox' : 'radio'
  const cantSelectMulti =
    !value || Array.isArray(value)
      ? true
      : multi
      ? !value.size?.length ||
        (value.size?.length === 1 && value.size?.includes('e'))
      : value.size === 'e' || !value.size

  useEffect(() => {
    if (value && cantSelectMulti && (value.mixed || value.dyp)) {
      helpers.setValue({
        ...value,
        mixed: false,
        dyp: false,
      })
    }
  }, [cantSelectMulti])

  return (
    <FormControl
      meta={{
        ...meta,
        error:
          meta.error && Object.keys(meta.error).length
            ? t`${'Settings'} is a required field`
            : undefined,
      }}
    >
      <div className="space-y-3">
        <div className="flex flex-wrap space-x-0 space-y-3 text-center xl:flex-nowrap xl:space-x-3 xl:space-y-0">
          <div className="group">
            <FieldCheckboxButton
              type={type}
              children={t`Steel dart`}
              name={`${namePrefix}variant`}
              value="sd"
              disabled={disabled}
            />

            <FieldCheckboxButton
              type={type}
              children={t`E-Dart`}
              name={`${namePrefix}variant`}
              value="ed"
              disabled={disabled}
            />
          </div>

          <div className="group">
            <FieldCheckboxButton
              type={type}
              children={t`301`}
              name={`${namePrefix}value`}
              value="301"
              disabled={disabled}
            />

            <FieldCheckboxButton
              type={type}
              children={t`501`}
              name={`${namePrefix}value`}
              value="501"
              disabled={disabled}
            />

            <FieldCheckboxButton
              type={type}
              children={t`701`}
              name={`${namePrefix}value`}
              value="701"
              disabled={disabled}
            />
          </div>

          <div className="group">
            <FieldCheckboxButton
              type={type}
              children={t`Single`}
              name={`${namePrefix}size`}
              value="e"
              disabled={disabled}
            />

            <FieldCheckboxButton
              type={type}
              children={t`Double`}
              name={`${namePrefix}size`}
              value="d"
              disabled={disabled}
            />

            <FieldCheckboxButton
              type={type}
              children={t`Triple`}
              name={`${namePrefix}size`}
              value="t"
              disabled={disabled}
            />
          </div>
        </div>

        <div className="flex flex-wrap space-x-0 space-y-3 text-center xl:flex-nowrap xl:space-x-3 xl:space-y-0">
          <div className="group">
            <FieldCheckboxButton
              type={type}
              children={t`Single Out`}
              name={`${namePrefix}out`}
              value="so"
              disabled={disabled}
            />

            <FieldCheckboxButton
              type={type}
              children={t`Double Out`}
              name={`${namePrefix}out`}
              value="do"
              disabled={disabled}
            />

            <FieldCheckboxButton
              type={type}
              children={t`Master Out`}
              name={`${namePrefix}out`}
              value="mo"
              disabled={disabled}
            />
          </div>

          <div className="group">
            <FieldCheckboxButton
              type={type}
              children={t`Mixed`}
              name={`${namePrefix}mixed`}
              disabled={cantSelectMulti || disabled}
              asBoolean
            />

            <FieldCheckboxButton
              type={type}
              children={t`DYP`}
              name={`${namePrefix}dyp`}
              disabled={cantSelectMulti || disabled}
              asBoolean
            />

            <FieldCheckboxButton
              type={type}
              children={t`Double In`}
              name={`${namePrefix}doubleIn`}
              disabled={disabled}
              asBoolean
            />
          </div>
        </div>
      </div>
    </FormControl>
  )
}

export default FieldGroupSetting
