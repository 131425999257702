import React, {useState} from 'react'

import {t} from '@lingui/macro'
import {useFormikContext} from 'formik'

import {useSelectLocations} from '@/hooks/query/useLocation'

import {MapMarkerPlus} from '@/components/Icons'
import FieldSelect from '@/components/fields/FieldSelect'
import LocationModalForm from '@/components/forms/LocationModalForm'

import {FieldGroupInterface} from '@/lib/form'

interface FieldLocationProps extends FieldGroupInterface {
  required?: boolean
  disabled?: boolean
}

const FieldLocationSelect = ({
  namePrefix = '',
  ...props
}: FieldLocationProps) => {
  const {setFieldValue} = useFormikContext()
  const [open, setOpen] = useState(false)
  const {data: selectLocationItems, isLoading, error} = useSelectLocations()

  return (
    <div className="flex space-x-5">
      <div className="w-full">
        <FieldSelect
          name={`${namePrefix}locationId`}
          items={selectLocationItems || []}
          error={error && String(error)}
          placeholder={t`location`}
          loading={isLoading}
          {...props}
        />
      </div>

      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setOpen(true)}
          disabled={props.disabled}
        >
          <MapMarkerPlus />
        </button>

        <LocationModalForm
          open={open}
          onHide={() => setOpen(false)}
          onSuccess={(location) =>
            setFieldValue(`${namePrefix}locationId`, location.id)
          }
        />
      </div>
    </div>
  )
}

export default FieldLocationSelect
