import React, {useState} from 'react'

import {faCheck, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans} from '@lingui/macro'

import ReportLocationModalForm from '@/components/forms/ReportLocationModalForm'

import {useAuth} from '@/lib/auth'
import {LocationModel} from '@/models/Location'

interface LocationReportProps {
  location: LocationModel
}

const LocationReport = ({location}: LocationReportProps) => {
  const {user} = useAuth()
  const [isOpen, setIsOpen] = useState(false)

  if (!user) {
    return null
  }

  return (
    <>
      {location.report ? (
        <button type="button" className="btn-sm btn-primary active" disabled>
          <FontAwesomeIcon icon={faCheck} fixedWidth />{' '}
          <Trans>Tournament location reported</Trans>
        </button>
      ) : (
        <button
          type="button"
          className="btn-sm btn-danger"
          onClick={() => setIsOpen(true)}
        >
          <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth />{' '}
          <Trans>Report tournament location</Trans>
        </button>
      )}

      <ReportLocationModalForm
        location={location}
        open={isOpen}
        onHide={() => setIsOpen(false)}
      />
    </>
  )
}

export default LocationReport
