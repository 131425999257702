import React from 'react'

import {Trans, t} from '@lingui/macro'

import {deleteMobileNewsletter} from '@/api/mobile-newsletters'

import {useMobileNewsletter} from '@/hooks/query/useMobileNewsletter'

import Loader, {LayoutLoader} from '@/components/Loader'
import {FieldGroupRadiusAddressPosition} from '@/components/fields/FieldAddressAutocomplete'
import FieldGroupMapRadius from '@/components/fields/FieldGroupMapRadius'
import FieldGroupSetting from '@/components/fields/FieldGroupSetting'
import FieldLeaguesSelect from '@/components/fields/FieldLeaguesSelect'
import FieldSubmitButton from '@/components/fields/FieldSubmitButton'

import {unsubscribeMobileNewsletter} from '@/actions/mobile-newsletter'
import {useAuth} from '@/lib/auth'
import Form, {CustomFormInterface} from '@/lib/form'
import {queryClient} from '@/lib/query'
import Yup from '@/lib/yup'
import {MobileNewsletterModel} from '@/models/MobileNewsletter'
import {MultiSettingInterface} from '@/models/Setting'
import {UserModel} from '@/models/User'
import {errorUnsubscribeNewsletterToast} from '@/toasts/newsletter'

export interface MobileNewsletterProps {
  id?: string
  radius?: number
  name: string
  currentPosition: boolean
  latitude?: number
  longitude?: number
  leagueIds: string[]
  token: string
  setting: MultiSettingInterface
}

const createMobileNewsletterInitValues = (
  user?: UserModel | null
): MobileNewsletterProps =>
  (user?.mobileNewsletters?.length &&
    user.mobileNewsletters[0].toInitValues()) || {
    radius: undefined,
    name: '',
    token: '',
    currentPosition: false,
    latitude: undefined,
    longitude: undefined,
    leagueIds: [],
    setting: {
      variant: [],
      value: [],
      size: [],
      out: [],
      dyp: false,
      doubleIn: false,
      mixed: false,
    },
  }

const MobileNewsletterForm = (
  props: CustomFormInterface<MobileNewsletterProps>
) => {
  const {user} = useAuth()
  const token = queryClient.getQueryData<string>(
    MobileNewsletterModel.TOKEN_KEY
  )
  const {data, isLoading} = useMobileNewsletter(token, {
    enabled: !!token && !user?.mobileNewsletters.length,
  })

  if (isLoading) {
    return <LayoutLoader />
  }

  const newsletterValidationSchema = Yup.object().shape({
    setting: Yup.multiSetting(),
    name: Yup.string()
      .when('radius', {
        is: (radius: number) => !!radius,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      })
      .label(t`Address`),
    radius: Yup.radius().required(),
    latitude: Yup.latitude().when('radius', {
      is: (radius: number) => !!radius,
      then: Yup.latitude().required(),
      otherwise: Yup.latitude().nullable(),
    }),
    longitude: Yup.longitude().when('radius', {
      is: (radius: number) => !!radius,
      then: Yup.longitude().required(),
      otherwise: Yup.longitude().nullable(),
    }),
    leagueIds: Yup.leagues().required(),
    currentPosition: Yup.currentPosition(),
  })

  return (
    <Form
      initialValues={
        data?.toInitValues() || createMobileNewsletterInitValues(user)
      }
      validationSchema={newsletterValidationSchema}
      {...props}
    >
      {({
        handleSubmit,
        setSubmitting,
        setFieldValue,
        setValues,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <FieldGroupSetting namePrefix="setting." multi />

          <FieldGroupRadiusAddressPosition
            onChange={({name, position}) => {
              setFieldValue('name', name)
              setFieldValue('latitude', position.lat())
              setFieldValue('longitude', position.lng())
            }}
            onlyAddress
          />

          <FieldLeaguesSelect />

          <FieldGroupMapRadius />

          <div className="w-full md:w-auto">
            <div className="flex space-x-5 whitespace-nowrap">
              {!!user?.mobileNewsletters?.length && (
                <button
                  type="button"
                  className="btn btn-gray w-full"
                  onClick={async () => {
                    setSubmitting(true)
                    try {
                      await deleteMobileNewsletter(
                        user.mobileNewsletters[0].id,
                        user.mobileNewsletters[0].token
                      )
                      unsubscribeMobileNewsletter(user)
                      setValues(createMobileNewsletterInitValues(user))
                      setSubmitting(false)
                    } catch (e) {
                      errorUnsubscribeNewsletterToast()
                      setSubmitting(false)
                    }
                  }}
                  disabled={isSubmitting}
                >
                  <Trans>Unsubscribe</Trans>
                </button>
              )}

              {!!user?.mobileNewsletters?.length && (
                <FieldSubmitButton className="w-full">
                  {user?.mobileNewsletters?.length ? (
                    <Trans>Update</Trans>
                  ) : (
                    <Trans>Subscribe</Trans>
                  )}
                </FieldSubmitButton>
              )}
            </div>
          </div>
        </form>
      )}
    </Form>
  )
}

export default MobileNewsletterForm
