import {errorCurrentPositionToast} from '@/toasts/position'

const useCurrentPosition =
  () =>
  (errorMessage = true) =>
    new Promise<GeolocationPosition>((resolve, reject) => {
      const onError = () => {
        errorMessage && errorCurrentPositionToast()
        reject()
      }

      if (!navigator.geolocation) {
        onError()
      }

      navigator.geolocation.getCurrentPosition((position) => {
        resolve(position)
      }, onError)
    })

export default useCurrentPosition
