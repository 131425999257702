import {useQuery} from 'react-query'

import {getSentEmails} from '@/api/sent-emails'

import {SearchParamsProps} from '@/components/Table'

import {queryClient} from '@/lib/query'
import {SentEmailModel, sentEmailSearchParams} from '@/models/SentEmail'

export const useSentEmails = (searchParams: SearchParamsProps) =>
  useQuery(
    [SentEmailModel.KEY, searchParams],
    () => getSentEmails(searchParams),
    {
      select: (sentEmailsPayload) =>
        SentEmailModel.fromNormalizedPayload(sentEmailsPayload),
      keepPreviousData: true,
    }
  )

export const prefetchSentEmails = (
  searchParams: SearchParamsProps = sentEmailSearchParams
) =>
  !queryClient.getQueryData([SentEmailModel.KEY, searchParams]) &&
  queryClient.prefetchQuery([SentEmailModel.KEY, searchParams], () =>
    getSentEmails(searchParams)
  )
