import {ReactChild, ReactNode, useEffect, useRef, useState} from 'react'

import {CodeHighlightNode, CodeNode} from '@lexical/code'
import {AutoLinkNode, LinkNode} from '@lexical/link'
import {ListItemNode, ListNode} from '@lexical/list'
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS,
} from '@lexical/markdown'
import {LexicalComposer} from '@lexical/react/LexicalComposer'
import {ContentEditable} from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin'
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin'
import {ListPlugin} from '@lexical/react/LexicalListPlugin'
import {MarkdownShortcutPlugin} from '@lexical/react/LexicalMarkdownShortcutPlugin'
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin'
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin'
import {HeadingNode, QuoteNode} from '@lexical/rich-text'
import {TableCellNode, TableNode, TableRowNode} from '@lexical/table'
import classNames from 'classnames'

import {InvalidIcon, RequiredIcon, ValidIcon} from '@/components/Icons'
import Loader from '@/components/Loader'
import PlaygroundAutoLinkPlugin from '@/components/markdownEditor/plugins/AutoLinkPlugin'
import CodeHighlightPlugin from '@/components/markdownEditor/plugins/CodeHighlightPlugin'
import ListMaxIndentLevelPlugin from '@/components/markdownEditor/plugins/ListMaxIndentLevelPlugin'
import OnBlurPlugin from '@/components/markdownEditor/plugins/OnBlurPlugin'
import ToolbarPlugin from '@/components/markdownEditor/plugins/ToolbarPlugin'

interface PlaceholderProps {
  children?: ReactNode
}

const Placeholder = ({children}: PlaceholderProps) => (
  <div className="pointer-events-none absolute top-16 left-5 mt-px ml-px inline-block select-none overflow-hidden text-ellipsis tracking-normal text-gray-placeholder">
    {children}
  </div>
)

const editorConfig = {
  onError(error) {
    throw error
  },
  theme: {
    paragraph: 'relative m-0 mb-2 last:mb-0',
    quote: 'm-0 ml-5 border-l-4 border-primary pl-4 my-2',
    heading: {
      h1: 'text-2xl text-primary font-normal mb-3',
      h2: 'text-lg text-primary font-bold mb-2 uppercase',
    },
    list: {
      nested: {
        listitem: 'list-none',
      },
      ol: 'list-decimal my-2',
      ul: 'list-disc my-2',
      listitem: 'mx-4',
    },
    link: 'text-primary hover:underline',
    text: {
      bold: 'font-bold',
      italic: 'italic',
      underline: 'underline',
      strikethrough: 'line-through',
      underlineStrikethrough: 'underline-line-through',
    },
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
}

interface MarkdownEditorProps {
  className?: string
  placeholder?: ReactNode
  invalid?: boolean
  valid?: boolean
  required?: boolean
  loading?: boolean
  disabled?: boolean
  icon?: ReactChild
  onIconContainerWidth?: (iconContainerWidth: number) => void
  onChange?: (value: string) => void
  onBlur?: () => void
}

const MarkdownEditor = ({
  placeholder,
  className,
  invalid,
  valid,
  required,
  loading,
  disabled,
  icon,
  onIconContainerWidth,
  onChange,
  onBlur,
}: MarkdownEditorProps) => {
  const iconContainerRef = useRef<HTMLDivElement>(null)
  const [iconContainerWidth, setIconContainerWidth] = useState(0)

  useEffect(() => {
    if (!iconContainerRef.current) {
      return
    }
    setIconContainerWidth(iconContainerRef.current.offsetWidth)
    onIconContainerWidth?.(iconContainerRef.current.offsetWidth)
  }, [iconContainerRef.current?.offsetWidth])

  return (
    <LexicalComposer initialConfig={{...editorConfig, editable: !disabled}}>
      <div className="relative w-full">
        <div
          className={classNames(
            className,
            'input relative p-0',
            invalid && 'invalid',
            valid && 'valid'
          )}
        >
          <ToolbarPlugin />

          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className="relative min-h-[10rem] resize-y overflow-y-auto p-5 outline-none"
                style={{
                  paddingRight: `calc(${iconContainerWidth}px + 1.25rem)`,
                }}
              />
            }
            placeholder={<Placeholder>{placeholder}</Placeholder>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <PlaygroundAutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <OnBlurPlugin onBlur={onBlur} />
          <OnChangePlugin
            onChange={(_, editor) => {
              editor.update(() =>
                onChange?.($convertToMarkdownString(TRANSFORMERS))
              )
            }}
          />
        </div>

        <div
          ref={iconContainerRef}
          className="pointer-events-none absolute bottom-0 top-0 right-2 flex items-center space-x-1"
        >
          {icon}
          {loading && <Loader />}
          {invalid && <InvalidIcon />}
          {valid && <ValidIcon />}
          {!invalid && !valid && required && <RequiredIcon />}
        </div>
      </div>
    </LexicalComposer>
  )
}

export const MarkdownViewer = ({children}) => (
  <LexicalComposer
    initialConfig={{
      ...editorConfig,
      editable: false,
      editorState: () => $convertFromMarkdownString(children, TRANSFORMERS),
    }}
  >
    <RichTextPlugin
      contentEditable={<ContentEditable className="relative outline-none" />}
      placeholder={<div />}
      ErrorBoundary={LexicalErrorBoundary}
    />
    <CodeHighlightPlugin />
    <ListPlugin />
    <LinkPlugin />
    <PlaygroundAutoLinkPlugin />
    <ListMaxIndentLevelPlugin maxDepth={7} />
    <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
  </LexicalComposer>
)

export default MarkdownEditor
