import {FormikHelpers} from 'formik/dist/types'

import {SearchParamsProps} from '@/components/Table'
import {PriceListProps} from '@/components/forms/PriceListModalForm'

import {client, formClient} from '@/lib/api-client'
import {user} from '@/lib/auth'
import {PriceListModel} from '@/models/PriceList'

export const getSelectPriceLists = async () => {
  const searchParams = {
    include: 'prices',
  }

  if (!user.isAdmin()) {
    searchParams['filter[creatorId]'] = user.id
  }

  const priceListPayload = await client
    .get('price-lists', {
      searchParams,
    })
    .json()

  return PriceListModel.normalizePayload(priceListPayload)
}

export const getUserPriceLists = async (
  searchParams: SearchParamsProps,
  userId: string
) => {
  const priceListPayload = await client
    .get('price-lists', {
      searchParams: {
        include: 'prices',
        'filter[creatorId]': userId,
        ...searchParams,
      },
    })
    .json()

  return PriceListModel.normalizePayload(priceListPayload)
}

export const getPriceLists = async (searchParams: SearchParamsProps) => {
  const priceListPayload = await client
    .get('price-lists', {
      searchParams: {
        include: 'prices',
        ...searchParams,
      },
    })
    .json()

  return PriceListModel.normalizePayload(priceListPayload)
}

export const postPriceList = async (
  priceListProps: PriceListProps,
  formikHelpers: FormikHelpers<PriceListProps>
) => {
  const priceListPayload = await formClient
    .post(formikHelpers, 'price-lists', {
      json: {
        data: {
          type: 'price-lists',
          attributes: priceListProps,
        },
      },
    })
    .json()

  return PriceListModel.normalizePayload(priceListPayload)
}
