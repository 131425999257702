import React, {MouseEvent, ReactChild, ReactChildren} from 'react'
import {NavigateOptions, NavigateProps} from 'react-router'
import {
  Link,
  Navigate,
  To,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import {i18n} from '@lingui/core'
import classNames from 'classnames'

const createLocalizedLink = (to: To) => {
  if (typeof to !== 'object') {
    return `/${i18n.locale}${to}`
  }

  return {
    ...to,
    pathname: `/${i18n.locale}${to.pathname}`,
  }
}

interface LocalizedLinkProps {
  to?: string
  className?: string
  onClick?: (event: MouseEvent) => void
  onMouseOver?: (event: MouseEvent) => void
  children: ReactChild | ReactChild[]
  noStyle?: boolean
}

export const LocalizedLink = ({
  to,
  className,
  noStyle,
  ...props
}: LocalizedLinkProps) => (
  <Link
    className={classNames(
      !noStyle && 'text-primary hover:text-primary-dark',
      className
    )}
    to={createLocalizedLink(to)}
    {...props}
  />
)

export const useLocalizedNavigate = () => {
  const navigate = useNavigate()

  return (to: To, options?: NavigateOptions) =>
    navigate(createLocalizedLink(to), options)
}

export const useLocalizedLocation = () => {
  const location = useLocation()

  return {
    ...location,
    pathname: location.pathname.replace(`/${i18n.locale}`, '') || '/',
  }
}

export const LocalizedNavigate = ({to, ...props}: NavigateProps) => {
  return <Navigate to={createLocalizedLink(to)} {...props} />
}

interface ExternalLinkProps {
  className?: string
  to: string
  children: ReactChild | ReactChildren
}

export const ExternalLink = ({className, to, children}: ExternalLinkProps) => (
  <a
    href={to}
    className={classNames(
      'cursor-pointer text-primary hover:text-primary-dark',
      className
    )}
    target="_blank"
    children={children}
  />
)

export const useSlug = () => {
  const params = useParams()

  return `/${Object.values(params).join('/')}`
}
