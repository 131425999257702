import {faBug} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Trans} from '@lingui/macro'

const Error = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center p-5">
      <div className="w-full space-y-5 rounded bg-white p-10 text-center dark:bg-dark md:w-1/2">
        <div className="text-6xl">
          <FontAwesomeIcon icon={faBug} />
        </div>

        <div>
          <Trans>Seems like we ran into a problem.</Trans>
        </div>

        <button
          type="button"
          className="btn btn-primary"
          onClick={() => window.location.reload()}
        >
          <Trans>Reload the page</Trans>
        </button>
      </div>
    </div>
  )
}

export default Error
