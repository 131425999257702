import {CSSProperties, ReactNode, useMemo, useState} from 'react'
import React, {MouseEvent, forwardRef} from 'react'

import {ModifierArguments, Options} from '@popperjs/core'
import * as PopperJS from '@popperjs/core'
import {Placement} from '@popperjs/core/lib/enums'
import classNames from 'classnames'
import useOnclickOutside from 'react-cool-onclickoutside'
import {usePopper} from 'react-popper'
import {useUpdateEffect} from 'react-use'

interface PopperOptions {
  styles: {[key: string]: React.CSSProperties}
  attributes: {[key: string]: {[key: string]: string} | undefined}
  state: PopperJS.State | null
  update: PopperJS.Instance['update'] | null
  forceUpdate: PopperJS.Instance['forceUpdate'] | null
}

interface ExtendedPopperOptions extends PopperOptions {
  setRefElement: (element: Element) => void
  setPopperElement: (element: Element) => void
  refElement: Element | null
  popperElement: Element | null
}

interface PopperProps {
  hidden?: boolean
  className?: string
  containerClassName?: string
  children:
    | ((popperOptions: ExtendedPopperOptions) => JSX.Element)
    | string
    | JSX.Element
    | JSX.Element[]
  refChildren: (popperOptions: ExtendedPopperOptions) => JSX.Element
  onClick?: (event: MouseEvent) => void
  onOutsideClick?: () => void
  placement?: Placement
  noSameWidth?: boolean
  arrow?: boolean
}

export const PopperContainer = ({
  hidden,
  className = '',
  containerClassName = '',
  children,
  refChildren,
  placement = 'bottom-start',
  onClick = () => null,
  onOutsideClick = () => null,
  noSameWidth,
  arrow,
}: PopperProps) => {
  const [refElement, setRefElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [arrowElement, setArrowElement] = useState(null)
  const modifiers = useMemo<any>(() => {
    const modifiers = []

    if (!noSameWidth) {
      modifiers.push({
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['computeStyles'],
        fn({state}: ModifierArguments<Options>) {
          state.styles.popper.width = `${state.rects.reference.width}px`
        },
      })
    }

    if (arrow) {
      modifiers.push({
        name: 'arrow',
        options: {
          element: arrowElement,
          padding: 5,
        },
      })
    }

    return modifiers
  }, [noSameWidth, arrow])
  const popperOptions = usePopper(refElement, popperElement, {
    placement,
    modifiers,
  })

  const ref = useOnclickOutside(hidden ? () => null : onOutsideClick)

  const extendedPopperOptions = {
    ...popperOptions,
    refElement,
    setRefElement,
    popperElement,
    setPopperElement,
  }

  useUpdateEffect(() => {
    popperOptions.forceUpdate && popperOptions.forceUpdate()
  }, [hidden])

  return (
    <div ref={ref} className={containerClassName}>
      {refChildren(extendedPopperOptions)}

      {!hidden && (
        <div
          ref={setPopperElement}
          className={classNames('z-50', className)}
          onClick={onClick}
          style={popperOptions.styles.popper}
          {...popperOptions.attributes.popper}
        >
          {typeof children === 'function'
            ? children(extendedPopperOptions)
            : children}

          {!!arrow && (
            <PopperContainerArrow
              ref={setArrowElement}
              styles={popperOptions.styles.arrow}
            />
          )}
        </div>
      )}
    </div>
  )
}

interface PopperContainerArrowProps {
  ref: (element: ReactNode) => void
  styles?: CSSProperties
}

const PopperContainerArrow = forwardRef(
  (props: PopperContainerArrowProps, ref) => (
    <div className="relative -mt-px h-arrow rotate-180 overflow-hidden">
      <div
        ref={ref}
        className="absolute inset-x-0 top-[2px] mx-auto h-2.5 w-2.5 -rotate-45 border border-gray-light bg-white pb-1 dark:bg-dark"
        {...props}
      />
    </div>
  )
)
