import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'

import {i18n} from '@lingui/core'
import {I18nProvider} from '@lingui/react'
import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga'

import GaWrapper from '@/components/GaWrapper'

import Error from '@/pages/errors/Error'

import App from '@/App'
import {AuthProvider} from '@/lib/auth'
import {loadDetectedLocale} from '@/lib/i18n'
import {HydratedQueryClientProvider} from '@/lib/query'
import '@/lib/sentry'
import {UserModel} from '@/models/User'

if (import.meta.env.PROD) {
  ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS || '')
}

interface ProviderWrapperProps {
  children: JSX.Element
}

const getInitUser = () => {
  const user = window?.__USER__
  document.getElementById('user')?.remove()
  delete window?.__USER__
  return user ? UserModel.fromNormalizedPayload({data: user}).data : null
}

const ProviderWrapper = ({children}: ProviderWrapperProps) => {
  useEffect(() => {
    loadDetectedLocale()
  }, [])

  return (
    <I18nProvider i18n={i18n}>
      <Sentry.ErrorBoundary fallback={<Error />}>
        <AuthProvider initUser={getInitUser()}>
          <BrowserRouter>
            <GaWrapper>
              <HydratedQueryClientProvider children={children} />
            </GaWrapper>
          </BrowserRouter>
        </AuthProvider>
      </Sentry.ErrorBoundary>
    </I18nProvider>
  )
}

ReactDOM.hydrate(
  <ProviderWrapper>
    <App />
  </ProviderWrapper>,
  document.getElementById('root')
)
