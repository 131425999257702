import React, {ReactChild} from 'react'

import {faBook, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {t} from '@lingui/macro'

import {IconHandHoldingEuro} from '@/components/Icons'

import {ExternalLink, LocalizedLink} from '@/lib/router'

const Cards = () => (
  <section
    className="w-full bg-white px-4 pt-16 shadow-lg- dark:bg-dark"
    data-test-id="cards"
  >
    <div className="mx-auto flex flex-wrap md:flex-nowrap md:space-x-3 xl:w-8/12">
      <Card
        title={t`Support`}
        url="mailto:support@darts-turniere.de"
        link="support@darts-turniere.de"
        blank
      >
        <FontAwesomeIcon
          icon={faEnvelope}
          className="fa-fw fa-2x mb-2 text-primary"
        />
      </Card>

      <Card title={t`Guide`} url="/guides/game-modes" link={t`Guide`}>
        <FontAwesomeIcon
          icon={faBook}
          className="fa-fw fa-2x mb-2 text-primary"
        />
      </Card>

      <Card
        title={t`Donation`}
        url="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=8TC4866SW9SVS&source=url"
        link="www.paypal.com"
        blank
      >
        <IconHandHoldingEuro className="fa-fw fa-2x mb-2 text-primary" />
      </Card>
    </div>
  </section>
)

interface CardProps extends CardContentProps {
  url: string
  blank?: boolean
}

const Card = ({url, blank = false, ...props}: CardProps) => {
  if (blank) {
    return (
      <div className="h-100 mb-3 w-full cursor-pointer rounded border-b-4 border-primary bg-white text-center text-dark dark:bg-dark dark:text-white">
        <ExternalLink to={url}>
          <CardContent {...props} />
        </ExternalLink>
      </div>
    )
  }

  return (
    <div className="h-100 mb-3 w-full cursor-pointer rounded border-b-4 border-primary bg-white text-center text-dark dark:bg-dark dark:text-white">
      <LocalizedLink to={url}>
        <CardContent {...props} />
      </LocalizedLink>
    </div>
  )
}

interface CardContentProps {
  title: string
  link: string
  children: ReactChild
}

const CardContent = ({title, link, children}: CardContentProps) => (
  <div className="-mb-1 rounded border-l border-r border-t border-gray-light p-5 hover:border-primary dark:border-gray-dark dark:hover:border-primary">
    {children}

    <h3 className="uppercase">{title}</h3>

    <hr className="my-4 mx-auto w-1/4 border-2 border-primary" />

    <div className="text-sm text-primary">{link}</div>
  </div>
)

export default Cards
