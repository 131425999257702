import React from 'react'
import {Outlet} from 'react-router-dom'

import {
  faMobile,
  faPaperPlane,
  faStar,
  faTrophy,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {Trans} from '@lingui/macro'

import {prefetchFavoriteGames} from '@/hooks/query/useGame'
import {prefetchSelectLocations} from '@/hooks/query/useLocation'
import {prefetchUserPriceLists} from '@/hooks/query/usePriceList'
import {prefetchMyTournaments} from '@/hooks/query/useTournament'

import {CalendarFilter, TrophyPlus} from '@/components/Icons'
import Nav from '@/components/Nav'

import {RequireAuth, useAuth} from '@/lib/auth'

const tournamentActiveIgnoreUrls = [
  '/profile/tournaments/create',
  '/profile/tournaments/calendar/filter',
  '/profile/tournaments/favorites',
]

const ProfileLayout = () => {
  const {user} = useAuth()

  const profileNavItems = [
    {
      title: <Trans>Favorites</Trans>,
      url: '/profile/tournaments/favorites',
      icon: faStar,
      onMouseOver: async () => await prefetchFavoriteGames(),
    },
    {
      title: <Trans>My tournaments</Trans>,
      url: '/profile/tournaments',
      icon: faTrophy,
      active: (pathname) =>
        pathname.startsWith('/profile/tournaments') &&
        !tournamentActiveIgnoreUrls.includes(pathname),
      onMouseOver: async () => await prefetchMyTournaments(),
    },
    {
      title: <Trans>Create tournament</Trans>,
      url: '/profile/tournaments/create',
      iconChild: <TrophyPlus className="mr-1" layerClassName="text-gray" />,
      onMouseOver: async () => {
        await prefetchUserPriceLists()
        await prefetchSelectLocations()
      },
    },
    {
      title: <Trans>My price lists</Trans>,
      url: '/profile/price-lists',
      iconChild: <TrophyPlus className="mr-1" layerClassName="text-gray" />,
      onMouseOver: async () => prefetchUserPriceLists(),
    },
    {
      title: <Trans>My profile</Trans>,
      url: '/profile',
      icon: faUser,
    },
    {
      title: <Trans>Email newsletter</Trans>,
      url: '/profile/newsletters/email',
      icon: faPaperPlane,
    },
    {
      title: <Trans>Calendar filter</Trans>,
      url: '/profile/tournaments/calendar/filter',
      iconChild: <CalendarFilter className="mr-1" layerClassName="text-gray" />,
    },
  ]

  if (user?.mobileNewsletters.length) {
    profileNavItems.splice(profileNavItems.length - 1, 0, {
      title: <Trans>Mobile newsletter</Trans>,
      url: '/profile/newsletters/mobile',
      icon: faMobile,
    })
  }

  return (
    <RequireAuth>
      <Nav navItems={profileNavItems} children={<Outlet />} />
    </RequireAuth>
  )
}

export default ProfileLayout
