import React from 'react'

import classNames from 'classnames'

import DartLeagues from '@/components/DartLeagues'
import DartsSetting from '@/components/DartsSetting'

import {LocalizedLink} from '@/lib/router'
import {GameModel} from '@/models/Game'

interface GameListProps {
  games: GameModel[]
}

const GameList = ({games}: GameListProps) => (
  <div className="overflow-hidden rounded bg-white dark:bg-dark">
    {games.map((game) => (
      <GameListItem key={game.id} game={game} />
    ))}
  </div>
)

interface GameListItemProps {
  game: GameModel
}

const GameListItem = ({game}: GameListItemProps) => (
  <LocalizedLink
    className="flex cursor-pointer justify-between space-x-3 overflow-x-auto p-5 hover:bg-primary hover:bg-opacity-30"
    to={game.link()}
    noStyle
  >
    <div className="flex space-x-3">
      <div className="whitespace-nowrap">{game.formatStart()}</div>

      <div className="flex items-center">
        <hr
          className={classNames(
            'mx-auto w-0 rounded-full border-4',
            game.canceled ? 'border-danger' : 'border-primary'
          )}
        />
      </div>

      <div>
        <DartsSetting setting={game.setting} />
      </div>
    </div>

    <div>
      <DartLeagues leagues={game.leagues} />
    </div>
  </LocalizedLink>
)

export default GameList
