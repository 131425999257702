import {useQuery} from 'react-query'

import {getLeagues} from '@/api/leagues'

import {queryClient} from '@/lib/query'
import {LeagueModel} from '@/models/League'

export const useSelectLeagues = () =>
  useQuery(LeagueModel.SELECT_KEY, getLeagues, {
    select: (leaguesPayload) => LeagueModel.toSelectItems(leaguesPayload),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  })

export const prefetchSelectLeagues = () =>
  !queryClient.getQueryData(LeagueModel.SELECT_KEY) &&
  queryClient.prefetchQuery(LeagueModel.SELECT_KEY, getLeagues)
