import React from 'react'
import {useLocation} from 'react-router-dom'

import {Trans} from '@lingui/macro'

import {postResetPassword} from '@/api/users'

import {Title} from '@/components/Title'
import ResetPasswordForm from '@/components/forms/ResetPasswordForm'

import {resetPassword} from '@/actions/user'
import {useLocalizedNavigate} from '@/lib/router'

const Reset = () => {
  const navigate = useLocalizedNavigate()
  const {search} = useLocation()
  const query = new URLSearchParams(search)
  const token = query.get('token')

  return (
    <div className="container py-10">
      <div className="mx-auto my-3 w-full md:w-8/12 lg:w-6/12 xl:w-5/12">
        <Title>
          <Trans>Reset password</Trans>
        </Title>

        <ResetPasswordForm
          token={token}
          onSubmit={(values, formikHelpers) => {
            postResetPassword(values, formikHelpers).then(() => {
              resetPassword()
              navigate('/auth/login')
            })
          }}
        />
      </div>
    </div>
  )
}

export default Reset
