import React from 'react'

import {Trans} from '@lingui/macro'

import {patchCalendarFilter, postCalendarFilter} from '@/api/calendar-filters'

import {Title} from '@/components/Title'
import CalendarFilterForm from '@/components/forms/CalendarFilterForm'

import {
  createCalendarFilter,
  updateCalendarFilter,
} from '@/actions/calendar-filter'
import {useAuth} from '@/lib/auth'
import {CalendarFilterModel} from '@/models/CalendarFilter'

const CalendarFilter = () => {
  const {user} = useAuth()

  return (
    <div>
      <Title>
        <Trans>Calendar filter</Trans>
      </Title>

      <CalendarFilterForm
        user={user}
        onSubmit={async (values, formikHelpers) => {
          if (user.calendarFilter) {
            const calendarFilterPayload = await patchCalendarFilter(
              values,
              formikHelpers
            )

            updateCalendarFilter(
              user,
              CalendarFilterModel.fromNormalizedPayload(calendarFilterPayload)
                .data
            )
          }

          if (!user.calendarFilter) {
            const calendarFilterPayload = await postCalendarFilter(
              values,
              formikHelpers
            )

            createCalendarFilter(
              user,
              CalendarFilterModel.fromNormalizedPayload(calendarFilterPayload)
                .data
            )
          }

          formikHelpers.setSubmitting(false)
        }}
      />
    </div>
  )
}

export default CalendarFilter
