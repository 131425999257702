import React from 'react'

import {Trans, t} from '@lingui/macro'

import FieldInput from '@/components/fields/FieldInput'
import {FieldRequiredSubmitButton} from '@/components/fields/FieldSubmitButton'

import Form, {CustomFormInterface} from '@/lib/form'
import Yup from '@/lib/yup'

export interface ForgotPasswordProps {
  email: string
}

const forgotPasswordInitValues = {
  email: '',
}

const ForgotPasswordForm = (
  props: CustomFormInterface<ForgotPasswordProps>
) => {
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.email().required(),
  })

  return (
    <Form
      initialValues={forgotPasswordInitValues}
      validationSchema={forgotPasswordSchema}
      {...props}
    >
      {({handleSubmit}) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <FieldInput
            type="email"
            name="email"
            placeholder={t`Email`}
            required
          />

          <FieldRequiredSubmitButton>
            <Trans>Request password reset link</Trans>
          </FieldRequiredSubmitButton>
        </form>
      )}
    </Form>
  )
}

export default ForgotPasswordForm
