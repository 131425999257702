import {Model, ModelInterface} from '@/lib/model'

export interface Stat {
  total: number
  day: number
  week: number
  month: number
}

export interface StatInterface extends ModelInterface {
  emailNewsletter: Stat
  emailNewsletterSent: Stat
  location: Stat
  register: Stat
  tournament: Stat
  game: Stat
}

export class StatModel extends Model<StatInterface> {
  newsletter!: Stat
  newsletterSent!: Stat
  location!: Stat
  register!: Stat
  tournament!: Stat
  game!: Stat

  public static KEY = 'stats'
}
