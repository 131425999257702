import React from 'react'

import {Trans} from '@lingui/macro'

import {prefetchPriceLists, usePriceLists} from '@/hooks/query/usePriceList'

import {Title} from '@/components/Title'
import PriceListsTable from '@/components/tabels/PriceListsTable'

const PriceLists = () => (
  <div>
    <Title>
      <Trans>Price lists</Trans>
    </Title>

    <PriceListsTable
      useTablePriceLists={usePriceLists}
      prefetchTablePriceLists={prefetchPriceLists}
    />
  </div>
)

export default PriceLists
