import React from 'react'

import {Trans} from '@lingui/macro'

import {prefetchFavoriteGames, useFavoritesGames} from '@/hooks/query/useGame'

import {Title} from '@/components/Title'
import GamesTable from '@/components/tabels/GamesTable'

const Favorites = () => (
  <div>
    <Title>
      <Trans>Tournament favorites</Trans>
    </Title>

    <GamesTable
      useTableGames={useFavoritesGames}
      prefetchTableGames={prefetchFavoriteGames}
    />
  </div>
)

export default Favorites
