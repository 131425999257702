import React from 'react'

import {Trans} from '@lingui/macro'

import SearchTournamentsForm from '@/components/forms/SearchTournamentsForm'

import {useLocalizedNavigate} from '@/lib/router'

const Masthead = () => {
  const navigate = useLocalizedNavigate()

  return (
    <header className="min-h-128 relative h-nav bg-masthead  bg-cover bg-scroll bg-center bg-no-repeat">
      <div className="container flex h-full items-center px-5 ">
        <div className="mx-auto text-center">
          <h1 className="font-serif h-20 text-4xl uppercase text-white md:text-7xl">
            <Trans>Darts Turniere</Trans>
          </h1>

          <SearchTournamentsForm
            onSubmit={(values: any) =>
              navigate(
                `/tournaments/calendar?${new URLSearchParams(
                  values
                ).toString()}`
              )
            }
          />
        </div>
      </div>
    </header>
  )
}

export default Masthead
