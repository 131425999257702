import React from 'react'

import {Trans} from '@lingui/macro'
import classNames from 'classnames'

import {IconTrophyPlacement} from '@/components/Icons'
import InputLabel from '@/components/InputLabel'

import {GameModel} from '@/models/Game'

interface TournamentPricesProps {
  className?: string
  game: GameModel
}

const TournamentPrices = ({className, game}: TournamentPricesProps) => {
  if (!game.priceList) {
    return null
  }

  return (
    <div
      className={classNames('space-y-5 bg-white p-5 dark:bg-dark', className)}
    >
      <div className="text-center text-2xl text-primary">
        <Trans>Prices</Trans>
      </div>

      {game.priceList.firstPrice() && (
        <div className="mx-auto sm:w-8/12">
          <InputLabel
            iconChild={
              <IconTrophyPlacement
                className="text-gold"
                layerClassName="text-primary"
                placement={1}
              />
            }
          >
            {game.priceList.firstPrice()}
          </InputLabel>
        </div>
      )}

      <div className="space-y-5 sm:flex sm:space-x-5 sm:space-y-0">
        {game.priceList.secondPrice() && (
          <div className="sm:w-1/2">
            <InputLabel
              iconChild={
                <IconTrophyPlacement
                  className="text-silver"
                  layerClassName="text-primary"
                  placement={2}
                />
              }
            >
              {game.priceList.secondPrice()}
            </InputLabel>
          </div>
        )}

        {game.priceList.thirdPrice() && (
          <div className="sm:w-1/2">
            <InputLabel
              iconChild={
                <IconTrophyPlacement
                  className="text-bronze"
                  layerClassName="text-primary"
                  placement={3}
                />
              }
            >
              {game.priceList.thirdPrice()}
            </InputLabel>
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
        {game.priceList.otherPrices().map((price, index) => (
          <div key={index}>
            <InputLabel
              iconChild={
                <IconTrophyPlacement
                  className="text-white"
                  layerClassName="text-primary"
                  placement={index + 4}
                />
              }
            >
              {price}
            </InputLabel>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TournamentPrices
