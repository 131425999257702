import {useQuery} from 'react-query'

import {
  getExistsPredictionTournaments,
  getMyTournaments,
  getReviewTournaments,
  getTournament,
  getTournaments,
} from '@/api/tournaments'

import {SearchParamsProps} from '@/components/Table'

import {queryClient} from '@/lib/query'
import {TournamentModel, tournamentSearchParams} from '@/models/Tournament'

export const useTournament = (slug: string) =>
  useQuery([TournamentModel.KEY, slug], () => getTournament(slug), {
    select: (tournamentPayload) =>
      TournamentModel.fromNormalizedPayload(tournamentPayload).data,
  })

export const prefetchTournament = (slug: string) =>
  !queryClient.getQueryData([TournamentModel.KEY, slug]) &&
  queryClient.prefetchQuery([TournamentModel.KEY, slug], () =>
    getTournament(slug)
  )

export const useMyTournaments = (searchParams: SearchParamsProps) =>
  useQuery(
    [TournamentModel.MY_KEY, searchParams],
    () => getMyTournaments(searchParams),
    {
      select: (tournamentsPayload) =>
        TournamentModel.fromNormalizedPayload(tournamentsPayload),
      keepPreviousData: true,
    }
  )

export const prefetchMyTournaments = (
  searchParams: SearchParamsProps = tournamentSearchParams
) =>
  !queryClient.getQueryData([TournamentModel.MY_KEY, searchParams]) &&
  queryClient.prefetchQuery([TournamentModel.MY_KEY, searchParams], () =>
    getMyTournaments(searchParams)
  )

export const useTournaments = (searchParams: SearchParamsProps) =>
  useQuery(
    [TournamentModel.KEY, searchParams],
    () => getTournaments(searchParams),
    {
      select: (tournamentsPayload) =>
        TournamentModel.fromNormalizedPayload(tournamentsPayload),
      keepPreviousData: true,
    }
  )

export const prefetchTournaments = (
  searchParams: SearchParamsProps = tournamentSearchParams
) =>
  !queryClient.getQueryData([TournamentModel.KEY, searchParams]) &&
  queryClient.prefetchQuery([TournamentModel.KEY, searchParams], () =>
    getTournaments(searchParams)
  )

export const useReviewTournaments = (searchParams: SearchParamsProps) =>
  useQuery(
    [TournamentModel.REVIEW_KEY, searchParams],
    () => getReviewTournaments(searchParams),
    {
      select: (tournamentsPayload) =>
        TournamentModel.fromNormalizedPayload(tournamentsPayload),
      keepPreviousData: true,
    }
  )

export const prefetchReviewTournaments = (
  searchParams: SearchParamsProps = tournamentSearchParams
) =>
  !queryClient.getQueryData([TournamentModel.REVIEW_KEY, searchParams]) &&
  queryClient.prefetchQuery([TournamentModel.REVIEW_KEY, searchParams], () =>
    getReviewTournaments(searchParams)
  )

export const useExistsPredictionTournaments = (
  name: string,
  locationSlug: string,
  enabled: boolean
) =>
  useQuery(
    [TournamentModel.EXISTS_PREDICTION_KEY, {name, locationSlug}],
    () => getExistsPredictionTournaments(name, locationSlug),
    {
      enabled,
      select: (tournamentsPayload) =>
        TournamentModel.fromNormalizedPayload(tournamentsPayload).data,
    }
  )
