import React from 'react'

import {Trans} from '@lingui/macro'

import {Title} from '@/components/Title'
import EmailNewslettersTable from '@/components/tabels/EmailNewslettersTable'

const EmailNewsletters = () => (
  <div>
    <Title>
      <Trans>Email newsletters</Trans>
    </Title>

    <EmailNewslettersTable />
  </div>
)

export default EmailNewsletters
